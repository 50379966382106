// Chakra imports
import { Flex, Image, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

// import logos
import logoLight from "assets/img/logo/logo-light.png";
import logoDark from "assets/img/logo/logo-dark.png";


export function SidebarBrand() {
	const logo = useColorModeValue(logoLight, logoDark);

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image h='65px' my='32px' src={logo} />
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
