import {BullFolio} from "bullfolio-types";


export const calculateEMA = (data: number[], period: number) => {
  if (data.length < period) {
    throw new Error("Not enough data points for the given period");
  }

  const multiplier = 2 / (period + 1);
  const emaArray = [];

  // Calculate the SMA for the first data point
  let sma = 0;
  for (let i = 0; i < period; i++) {
    sma += data[i];
  }
  sma /= period;

  emaArray.push(sma);

  // Calculate the EMA for the remaining data points
  for (let i = period; i < data.length; i++) {
    // eslint-disable-next-line max-len
    const ema: number = data[i] * multiplier + emaArray[emaArray.length - 1] * (1-multiplier);
    emaArray.push(ema);
  }

  return emaArray;
};

export const calculateSMA = (closingPrices: number[], length: number) => {
  const smaValues: number[] = [];

  if (closingPrices.length < length) {
    throw new Error("Not enough data points for the given period");
  }

  if (length === 0) {
    return closingPrices;
  }

  for (let i = 0; i <= closingPrices.length - length; i++) {
    const sum = closingPrices.slice(i, i + length)
      .reduce((acc, price) => acc + price, 0);
    const sma = sum / length;
    smaValues.push(sma);
  }

  return smaValues;
};

export const calculateWMA = (closingPrices: number[], length: number) => {
  const wmaValues = [];

  if (closingPrices.length < length) {
    throw new Error("Not enough data points for the given period");
  }

  for (let i = length - 1; i < closingPrices.length; i++) {
    let sumWeighted = 0;
    let sumWeights = 0;

    for (let j = 0; j < length; j++) {
      sumWeighted += closingPrices[i - j] * (j + 1);
      sumWeights += j + 1;
    }

    const wma = sumWeighted / sumWeights;
    wmaValues.push(wma);
  }

  return wmaValues;
};

export const calculateDEMA = (closingPrices: number[], length: number) => {
  const ema = calculateEMA(closingPrices, length);
  const doubleEma = calculateEMA(ema, length);

  const dema = ema.map((emaValue, index) => {
    return 2 * emaValue - doubleEma[index];
  });

  return dema.slice(length - 1);
};

export const calculateTEMA = (closingPrices: number[], length: number) => {
  const ema = calculateEMA(closingPrices, length);
  const emaOfEma = calculateEMA(ema, length);
  const emaOfEmaOfEma = calculateEMA(emaOfEma, length);

  const tema = ema.map((emaValue, index) => {
    return 3 * emaValue - 3 * emaOfEma[index] + emaOfEmaOfEma[index];
  });

  return tema.slice(length * 2 - 2);
};

export const calculateRSI = (prices: number[], n: number) => {
  if (prices.length <= n) {
    throw new Error("Input array length should be greater than 'n'.");
  }

  // Calculate daily price changes
  const changes = [];
  for (let i = 1; i < prices.length; i++) {
    changes.push(prices[i] - prices[i - 1]);
  }

  // Separate gains and losses
  const gains = [];
  const losses = [];
  for (let i = 0; i < n; i++) {
    if (changes[i] >= 0) {
      gains.push(changes[i]);
      losses.push(0);
    } else {
      gains.push(0);
      losses.push(-changes[i]);
    }
  }

  // Calculate average gains and losses
  let sumGains = 0;
  let sumLosses = 0;
  for (let i = 0; i < n; i++) {
    sumGains += gains[i];
    sumLosses += losses[i];
  }
  let avgGain = sumGains / n;
  let avgLoss = sumLosses / n;

  const rsiValues = [];

  for (let i = n; i < prices.length; i++) {
    const currentChange = changes[i];
    const currentGain = currentChange >= 0 ? currentChange : 0;
    const currentLoss = currentChange < 0 ? -currentChange : 0;

    // Calculate the average gain and loss using the previous values
    avgGain = (avgGain * (n - 1) + currentGain) / n;
    avgLoss = (avgLoss * (n - 1) + currentLoss) / n;

    // Calculate the RSI for the current day
    const rs = avgGain / avgLoss;
    const rsi = 100 - (100 / (1 + rs));

    rsiValues.push(rsi);
  }

  return rsiValues;
};

export const calculateRsiMa = (prices: number[], n: number, man: number) => {
  const rsi = calculateRSI(prices, n);
  const rsiMa = calculateSMA(rsi, man);
  return rsiMa;
};

export const calculateStochRSI = (
  rsiValues: number[],
  stochLength: number,
  k: number,
  d: number
) => {
  // Calculate Stochastic RSI
  const stochRSI: number[] = [];
  for (let i = 0; i < rsiValues.length - stochLength + 1; i++) {
    const rsiSlice = rsiValues.slice(i, i + stochLength);
    const highestRSI = Math.max(...rsiSlice);
    const lowestRSI = Math.min(...rsiSlice);
    const currentRSI = rsiValues[i + stochLength - 1];
    const k = (currentRSI - lowestRSI) / (highestRSI - lowestRSI) * 100;
    stochRSI.push(k);
  }

  // Calculate smoothed K and D
  const smoothedK = calculateSMA(stochRSI, k);
  const smoothedD = calculateSMA(smoothedK, d);

  return {
    k: smoothedK,
    d: smoothedD,
  };
};

// BOLLINGER BANDS
export const calculateBollingerBands = (prices: number[], period: number) => {
  const bands = [];

  for (let i = period - 1; i < prices.length; i++) {
    const sma = calculateSMA(prices, period)[i];
    const stdDev = calculateStandardDeviation(prices, i, period, sma);

    const upper = sma + 2 * stdDev;
    const lower = sma - 2 * stdDev;
    const width = ((upper - lower) / sma) * 100;

    bands.push({
      middle: sma,
      upper,
      lower,
      width,
    });
  }

  return bands;
};

const calculateStandardDeviation = (
  prices: number[],
  index: number,
  period: number,
  sma: number
) => {
  let sumSquaredDiff = 0;
  for (let i = index - period + 1; i <= index; i++) {
    const diff = prices[i] - sma;
    sumSquaredDiff += diff * diff;
  }
  const variance = sumSquaredDiff / period;
  return Math.sqrt(variance);
};

const stdev = (data: number[], period: number) => {
  // Calculate Standard Deviation
  const mean = calculateSMA(data, period);
  return data.slice(period).map((val, index) => {
    const slice = data.slice(index, index + period);
    // eslint-disable-next-line max-len
    const sum = slice.reduce((acc, curr) => acc + Math.pow(curr - mean[index], 2), 0);
    return Math.sqrt(sum / period);
  });
};

const calculateBBWP = (prices: number[], bbwLen: number, lookback: number) => {
  const basis = calculateSMA(prices, bbwLen);
  const dev = stdev(prices, bbwLen);
  // eslint-disable-next-line max-len
  const bbw = basis.map((val, index) => (val + dev[index] - (val - dev[index])) / val);
  const bbwpArray = [];
  for (let i = 0; i < prices.length; i++) {
    if (i >= bbwLen) {
      let bbwSum = 0.0;
      const len = i < lookback ? i + 1 : lookback;
      for (let j = 1; j <= len; j++) {
        bbwSum += (bbw[i - j] > bbw[i] ? 0 : 1);
      }
      const bbwp = (bbwSum / len) * 100;
      bbwpArray.push(bbwp);
    } else {
      bbwpArray.push(0);
    }
  }
  // remove all invalid data (last bbwLen that are always 100)
  bbwpArray.splice(-bbwLen);
  return bbwpArray;
};

const calculateBBWPMA = (
  prices: number[],
  bbwLen: number,
  lookback: number,
  maLen: number
) => {
  return calculateSMA(calculateBBWP(prices, bbwLen, lookback), maLen);
};


// ICHIMOKU
// Helper function to calculate the highest and lowest values in an array
const getHighLow = (array: number[]) => ({
  high: Math.max(...array),
  low: Math.min(...array),
});

// Tenkan-sen (Conversion Line) calculation
const calculateTenkanSen = (closingPrices: number[], tenkanPeriod: number): number[] => {
  const tenkanSen = new Array(closingPrices.length).fill(null);
  for (let i = tenkanPeriod - 1; i < closingPrices.length; i++) {
    const periodPrices = closingPrices.slice(i - tenkanPeriod + 1, i + 1);
    const { high, low } = getHighLow(periodPrices);
    tenkanSen[i] = (high + low) / 2;
  }
  return tenkanSen;
};

// Kijun-sen (Base Line) calculation
const calculateKijunSen = (closingPrices: number[], kijunPeriod: number) => {
  const kijunSen = new Array(closingPrices.length).fill(null);
  for (let i = kijunPeriod - 1; i < closingPrices.length; i++) {
    const periodPrices = closingPrices.slice(i - kijunPeriod + 1, i + 1);
    const { high, low } = getHighLow(periodPrices);
    kijunSen[i] = (high + low) / 2;
  }
  return kijunSen;
};

// Senkou Span A (Leading Span A) calculation
const calculateSenkouSpanA = (closingPrices: number[], tenkanSen: number[], kijunSen: number[], displacement: number) => {
  const senkouSpanA = new Array(closingPrices.length + displacement).fill(null);
  for (let i = 0; i < closingPrices.length; i++) {
    if (tenkanSen[i] !== null && kijunSen[i] !== null) {
      senkouSpanA[i + displacement] = (tenkanSen[i] + kijunSen[i]) / 2;
    }
  }
  return senkouSpanA;
};

const handleCalculateSenkouSpanA = (closingPrices: number[], tenkanPeriod: number, kijunPerion: number, displacement: number) => {
  const tenkanSen = calculateTenkanSen(closingPrices, tenkanPeriod);
  const kijunSen = calculateKijunSen(closingPrices, kijunPerion);
  const spanA = calculateSenkouSpanA(closingPrices, tenkanSen, kijunSen, displacement);
  return spanA;
}

// Senkou Span B (Leading Span B) calculation
const calculateSenkouSpanB = (closingPrices: number[], senkouSpanBPeriod: number, displacement: number) => {
  const senkouSpanB = new Array(closingPrices.length + displacement).fill(null);
  for (let i = senkouSpanBPeriod - 1; i < closingPrices.length; i++) {
    const periodPrices = closingPrices.slice(i - senkouSpanBPeriod + 1, i + 1);
    const { high, low } = getHighLow(periodPrices);
    senkouSpanB[i + displacement] = (high + low) / 2;
  }
  return senkouSpanB;
};

// Chikou Span (Lagging Span) calculation
const calculateChikouSpan = (closingPrices: number[], displacement: number) => {
  const chikouSpan = new Array(closingPrices.length).fill(null);
  for (let i = 0; i < closingPrices.length; i++) {
    if (i >= displacement) {
      chikouSpan[i] = closingPrices[i - displacement];
    }
  }
  return chikouSpan;
};

const calculateIchimoku = (closingPrices: number[]) => {
  if (!Array.isArray(closingPrices) || closingPrices.length === 0) {
    throw new Error("Input must be a non-empty array of closing prices.");
  }

  const tenkanPeriod = 9;
  const kijunPeriod = 26;
  const senkouSpanBPeriod = 52;
  const displacement = 26;

  const tenkanSen = calculateTenkanSen(closingPrices, tenkanPeriod);
  const kijunSen = calculateKijunSen(closingPrices, kijunPeriod);
  const senkouSpanA = calculateSenkouSpanA(closingPrices, tenkanSen, kijunSen, displacement);
  const senkouSpanB = calculateSenkouSpanB(closingPrices, senkouSpanBPeriod, displacement);
  const chikouSpan = calculateChikouSpan(closingPrices, displacement);

  return {
    tenkanSen,
    kijunSen,
    senkouSpanA,
    senkouSpanB,
    chikouSpan,
  };
}



// HANDLER FUNCTION
export const handleCalculateIndicator = (
  code: BullFolio.Strategy.Event.Condition.Type,
  prices: number[],
  settings: number[]
) => {
  try {
    switch (code) {
    case "sma":
      return calculateSMA(prices, settings[0]);
    case "dema":
      return calculateDEMA(prices, settings[0]);
    case "ema":
      return calculateEMA(prices, settings[0]);
    case "rsi":
      return calculateRSI(prices, settings[0]);
    case "rsi_ma":
      return calculateRsiMa(prices, settings[0], settings[1]);
    case "tema":
      return calculateTEMA(prices, settings[0]);
    case "wma":
      return calculateWMA(prices, settings[0]);
    case "stoch_rsi_d":
      return calculateStochRSI(
        calculateRSI(prices, settings[0]), settings[1], settings[2], settings[3]
      ).d;
    case "stoch_rsi_k":
      return calculateStochRSI(
        calculateRSI(prices, settings[0]), settings[1], settings[2], settings[3]
      ).k;
    case "bbwp":
      return calculateBBWP(prices, settings[0], settings[1]);
    case "bbwp_ma":
      return calculateBBWPMA(prices, settings[0], settings[1], settings[2]);
    case "tenkan_sen": 
      return calculateTenkanSen(prices, settings[0]);
    case "kijun_sen":
      return calculateKijunSen(prices, settings[0]);
    case "chikou_span":
      return calculateChikouSpan(prices, settings[0]);
    case "senkou_span_a":
      return handleCalculateSenkouSpanA(prices, settings[0], settings[1], settings[2]);
    case "senkou_span_b":
      return calculateSenkouSpanB(prices, settings[0], settings[1]);
    }
  } catch (err) {
    console.log(
      "error while calculating indicator - not enough data in prices[]"
    );
    return [];
  }
};
