import { Box, Link, Button, Flex, FormLabel, Input, Select, Switch, Text, Tooltip, useColorModeValue, Divider, useToast } from "@chakra-ui/react";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import { links } from "helpers/links";
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import TextFiled from "components/fields/TextField";
import SwitchField from "components/fields/SwitchField";
import { useState, useEffect } from "react";
import { BullFolio } from "bullfolio-types";
import { useCoins } from "contexts/CoinsContext";
import Loading from "components/Loading/Loading";
import { useUser } from "contexts/UserContext";
import { useAlerts } from "contexts/AlertsContext";
import { useStrategies } from "contexts/StrategiesContext";
import { useWatchlists } from "contexts/WatchlistsContext";
import { getToast } from "helpers/formatters";
import { AVAILABLE_TIMEFRAMES } from "helpers/defaultIndicators";


const CreateAlertPage = () => {

  const textColor = useColorModeValue("navy.700", "white");

  const { createAlert } = useAlerts();
  const { getMyStrategies } = useStrategies();
  const { userData } = useUser();
  const { getAllWatchlists } = useWatchlists();
  const toast = useToast();

  const [watchlists, setWatchlists] = useState<BullFolio.Watchlist[]>(null);
  const [strategies, setStrategies] = useState<BullFolio.Strategy[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [alert, setAlert] = useState<BullFolio.Alert>({
    id: "",
    name: "",
    description: "",
    active: true,
    condition: {
      strategy_event: null,
      type: "value_reached",
      value_reached: {
        value: 0,
        against: "usd",
        timeframe: "1h",
      },
    },
    recurring: true,
    userUid: "",
    event: {
      data: "",
      description: "",
      name: "",
    },
    tokens: {
      type: "value",
      top: 0,
      array: [],
      value: null,
      watchlistId: null,
    },
  });

  const CONDITION_TYPES: BullFolio.Strategy.Event.Condition.ConditionType[] = ["<", "<=", "==", ">", ">="];
  const KEYS: BullFolio.Strategy.Event.Condition.ConditionType[] = ["<", "<=", "==", ">", ">="];
  const TIMEFRAMES: BullFolio.Alert.Timeframe[] = ["1d", "1h", "4h"];
  const AGAINST: BullFolio.Alert.AgainstType[] = ["btc", "usd"];
  const COIN_KEYS: (keyof BullFolio.CoinData.Data)[] = ["ath", "ath_change_percentage", "ath_date", "atl", "atl_change_percentage", "atl_date", "circulating_supply", "current_price", "fully_diluted_valuation", "high_24h", "id", "image", "last_updated", "low_24h", "market_cap", "market_cap_change_24h", "market_cap_change_percentage_24h", "market_cap_rank", "max_supply", "name", "price_change_24h", "price_change_percentage_1h_in_currency", "price_change_percentage_24h", "price_change_percentage_24h_in_currency", "price_change_percentage_30d_in_currency", "price_change_percentage_7d_in_currency", "roi", "symbol", "total_supply", "total_volume"]

  const handleCreate = async () => {
    setIsLoading(true);
    console.log(alert);
    if(alert.tokens.type === "array") {
      if(alert.tokens.array.length === 0) {
        setIsLoading(false);
        toast(getToast("warning", "Select token!", "You must select token!"));
        return;
      }
    }
    await createAlert(alert);
    setIsLoading(false);
  }

  const handleEditCoin = (id: string) => {
    const prev = {...alert};
    prev.tokens.array = [id];
    setAlert(prev);
  }

  const handleEditWatchlist = (id: string) => {
    const prev = {...alert};
    prev.tokens.watchlistId = id;
    setAlert(prev);
  }

  const handleEditValueKey = (id: string) => {
    const prev = {...alert};
    prev.tokens.value.key = id as BullFolio.Alert.AllowedKeys;
    setAlert(prev);
  }

  const handleEditValueCondition = (id: string) => {
    const prev = {...alert};
    prev.tokens.value.condition = id as BullFolio.Strategy.Event.Condition.ConditionType;
    setAlert(prev);
  }

  const handleEditValueValue = (id: string) => {
    const prev = {...alert};
    prev.tokens.value.value = Number(id);
    setAlert(prev);
  }

  const handleConditionValueValue = (id: string) => {
    const prev = {...alert};
    // Parse the input value as a float (allowing decimals)
    const newValue = id === "0" ? 0 : parseFloat(id);

    // Check if newValue is a valid number (not NaN)
    if (!isNaN(newValue)) {
      prev.condition.value_reached.value = newValue;
    }
    setAlert(prev);
  };

  const handleConditionValueAgainst = (against: BullFolio.Alert.AgainstType) => {
    const prev = {...alert};
    prev.condition.value_reached.against = against;
    setAlert(prev);
  };

  const handleTimeframeChange = (timeframe: BullFolio.Timeframe) => {
    const prev = {...alert};
    prev.condition.value_reached.timeframe = timeframe;
    setAlert(prev);
  };

  const handleEventChange = (name: BullFolio.Strategy.Event.Type) => {
    const prev = {...alert};
    if(alert.condition.strategy_event.events.includes(name)) {
      // remove
      alert.condition.strategy_event.events.filter(item => item !== name);
    }else{
      // add
      prev.condition.strategy_event.events.push(name);
    }
    setAlert(prev);
  };

  useEffect(() => {
    (async () => {
      const _strategies = await getMyStrategies();
      console.log(_strategies);
      setStrategies(_strategies);
      const _watchlists = await getAllWatchlists();
      console.log(_watchlists);
      setWatchlists(_watchlists);
    })();
  }, []);

  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `/#${links.myAlerts}`,
          name: "My Alerts"
        }, {
          href: `/`,
          name: "Create"
        }]}
        additional={{ mb: "4" }}
      />
      <Card>
        <Text color={textColor} fontSize="xl" fontWeight={"900"} px="1" mb="3">
          Create Alert
        </Text>
        {!isLoading ? (
          <>
            <InputField
              label="Name"
              placeholder="My alert"
              mb="10px"
              value={alert.name}
              onChange={(e: any) => setAlert({...alert, name: e.target.value})}
            />
            <TextFiled
              label="Description"
              placeholder="Your short description"
              mb="6"
              value={alert.description}
              onChange={(e: any) => setAlert({...alert, description: e.target.value})}
            />
            <Flex>
              <Switch
                isChecked={!alert.recurring}
                onChange={() => setAlert({...alert, recurring: !alert.recurring})}
              />
              <FormLabel ml="3">One Time Alert (you only get one notification - then alert is deleted)</FormLabel>
            </Flex>
            <Flex>
              <Switch
                isChecked={alert.condition.strategy_event !== null}
                onChange={() => alert.condition.strategy_event === null ? setAlert({...alert, condition: {...alert.condition, type: "strategy_event", strategy_event: { strategyId: "", events: [], strategyName: "" }, value_reached: null}}) : setAlert({...alert, condition: {...alert.condition, type: "value_reached", strategy_event: null}})}
              />
              <FormLabel ml="3">Use Strategy Events for Alerts notifications</FormLabel>
            </Flex>
            <Box mt="6">
              <Text fontWeight={"extrabold"}>
                Tokens you want to be notified about
              </Text>
              {userData?.allIds?.coins ? (
                <>
                  <Flex mt="3">
                    <Switch
                      id="array"
                      mt="0.5"
                      isChecked={alert.tokens.type === "array"}
                      onChange={() => {
                        const prev = {...alert};
                        if(alert.tokens.type === "value" || alert.tokens.type === "top" || alert.tokens.type === "watchlist") {
                          console.log("in if")
                          prev.tokens.value = null;
                          prev.tokens.type = "array";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "top"
                          prev.tokens.value = null;
                        }
                        setAlert(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Individual Coin:</FormLabel>
                  </Flex>
                  <Flex>
                    <Switch
                      id="watchlists"
                      mt="0.5"
                      isChecked={alert.tokens.type === "watchlist"}
                      onChange={() => {
                        const prev = {...alert};
                        if(alert.tokens.type === "array" || alert.tokens.type === "top" || alert.tokens.type === "value") {
                          console.log("in if")
                          prev.tokens.value = {
                            key: "market_cap",
                            value: 0,
                            condition: "=="
                          }
                          prev.tokens.type = "watchlist";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "watchlist"
                          prev.tokens.value = null;
                        }
                        setAlert(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Tokens on my watchlist</FormLabel>
                  </Flex>
                  <Flex>
                    <Switch
                      id="metrics"
                      mt="0.5"
                      isChecked={alert.tokens.type === "value"}
                      onChange={() => {
                        const prev = {...alert};
                        if(alert.tokens.type === "array" || alert.tokens.type === "top" || alert.tokens.type === "watchlist") {
                          console.log("in if")
                          prev.tokens.value = {
                            key: "market_cap",
                            value: 0,
                            condition: "=="
                          }
                          prev.tokens.type = "value";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "array"
                          prev.tokens.value = null;
                        }
                        setAlert(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Select By Metric (eg. all tokens with market cap above $100m, ...):</FormLabel>
                  </Flex>
                  <Flex>
                    <Switch
                      id="top"
                      mt="0.5"
                      isChecked={alert.tokens.type === "top"}
                      onChange={() => {
                        const prev = {...alert};
                        if(alert.tokens.type === "array" || alert.tokens.type === "value") {
                          console.log("in if")
                          prev.tokens.value = null;
                          prev.tokens.type = "top";
                          prev.tokens.array = [];
                        }else{
                          console.log("in else")
                          prev.tokens.type = "array"
                          prev.tokens.value = null;
                        }
                        setAlert(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Use Top Coins (eg. top 10 coins):</FormLabel>
                  </Flex>
                  {alert.tokens.type === "array" ? (
                    <>
                      <Input
                        placeholder="Search tokens by name..."
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        mb="1"
                      />
                      <Select onChange={(e) => handleEditCoin(e.target.value)} placeholder="Select token">
                        {userData?.allIds?.coins?.filter((x) => x.name.toLowerCase().includes(searchValue.toLowerCase())).map(coin => {
                          return(
                            <option key={coin.id} value={coin.id}>{coin.name} ({coin.symbol.toUpperCase()})</option>
                          )
                        })}
                      </Select>
                    </>
                  ):null}
                  {alert.tokens.type === "watchlist" ? (
                    <Select onChange={(e) => handleEditCoin(e.target.value)}>
                      {watchlists?.map(watchlist => {
                        return(
                          <option key={watchlist.id} value={watchlist.id}>{watchlist.name}</option>
                        )
                      })}
                    </Select>
                  ):null}
                  {alert.tokens.type === "value" ? (
                    <Flex>
                      <Select onChange={(e) => handleEditValueKey(e.target.value)}>
                        {COIN_KEYS.map(key => {
                          return(
                            <option key={key} value={key}>{key}</option>
                            )
                          })}
                      </Select>
                      <Select onChange={(e) => handleEditValueCondition(e.target.value)}>
                        {CONDITION_TYPES.map(val => {
                          return(
                            <option key={val} value={val}>{val}</option>
                            )
                          })}
                      </Select>
                      <Input
                        placeholder="Value"
                        value={alert.tokens.value?.value || ""}
                        onChange={(e) => handleEditValueValue(e.target.value)}
                      />
                    </Flex>
                  ):null}
                  {alert.tokens.type === "top" ? (
                    <Box>
                      <InputField
                        placeholder="20"
                        label="How Many Top Coins"
                        value={alert.tokens.top}
                        type="number"
                        onChange={(e: any) => setAlert({...alert, tokens: {...alert.tokens, top: Number(e.target.value)}})}
                      />
                    </Box>
                  ):null}
                </>
              ):(
                <Loading text="Loading Tokens..." />
              )}
            </Box>
            {alert.condition.strategy_event === null ? (
              <Box mt="6">
                <Text fontWeight={"extrabold"}>
                  Alert me when price hits:
                </Text>
                {userData?.allIds?.coins ? (
                  <Flex>
                    <Input
                      placeholder="Value"
                      type={"number"}
                      value={alert.condition?.value_reached.value || ""}
                      onChange={(e) => handleConditionValueValue(e.target.value)}
                    />
                    <Select defaultValue={alert.condition.value_reached.against} onChange={(e) => handleConditionValueAgainst(e.target.value as BullFolio.Alert.AgainstType)}>
                      {AGAINST.map((against) => {
                        return(
                          <option key={against} value={against}>{against.toUpperCase()}</option>
                        )
                      })}
                    </Select>
                  </Flex>
                ):(
                  <Loading text="Loading coin.data..." />
                )}
                <Box mt="6">
                  <Text fontWeight={"extrabold"}>
                    On Timeframe (close):
                  </Text>
                  <Select defaultValue={alert.condition.value_reached.timeframe} onChange={(e) => handleTimeframeChange(e.target.value as BullFolio.Timeframe)}>
                    {AVAILABLE_TIMEFRAMES.map((timeframe) => {
                      return(
                        <option key={timeframe} value={timeframe}>{timeframe.toUpperCase()}</option>
                      )
                    })}
                  </Select>
                </Box>
              </Box>
            ):(
              <Box mt="6">
                <Text fontWeight={"extrabold"}>
                  Select Strategy
                </Text>
                {strategies ? (
                  <Flex>
                    <Select onChange={(e) => setAlert({...alert, condition: {...alert.condition, strategy_event: {...alert.condition.strategy_event, strategyId: e.target.value, strategyName: strategies.find(x => x.id === e.target.value)?.name || ""}}})} placeholder="Select Strategy">
                      {strategies?.map((strategy) => {
                        return(
                          <option key={strategy.id} value={strategy.id}>{strategy.name}</option>
                        )
                      })}
                    </Select>
                  </Flex>
                ):(
                  <Loading text="Loading Strategies..." />
                )}
                {alert.condition.strategy_event.strategyId.length>0 ? (
                  <Box mt="6">
                    <Text fontWeight={"extrabold"}>
                      Notify me for events
                    </Text>
                    {strategies.find(x => x.id === alert.condition.strategy_event.strategyId)?.events.map((strategyEvent) => {
                      return(
                        <Flex key={strategyEvent.name}>
                          <Switch
                            isChecked={alert.condition.strategy_event.events.includes(strategyEvent.type)}
                            onChange={() => handleEventChange(strategyEvent.type)}
                          />
                          <FormLabel ml="3">{strategyEvent.name} ({strategyEvent.type.toUpperCase()})</FormLabel>
                        </Flex>
                      )
                    })}
                  </Box>
                ):null}
              </Box>
            )}
          </>
        ):(
          <Loading text="Saving your alert..." />
        )}

        <Button width={"100%"} mt="8" variant={"darkBrand"} onClick={handleCreate}>
          Create
        </Button>

        <Box mt="10" px="2" mb="2">
          <Text fontWeight={"extrabold"} fontSize="xl">
            Advanced Alerts?
          </Text>
          <Text fontSize={"lg"}>If you want to create more advanced alerts - alerts with indicators data - first create a strategy.</Text>
          <Link href={`/#${links.createStrategy}`}>
            <Button variant={"brand"} mt="3">
              Create Strategy
            </Button>
          </Link>
        </Box>
      </Card>
    </Box>
  );
};

export default CreateAlertPage;