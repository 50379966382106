import { useEffect, useState } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Flex, Icon, Image, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { useCoins } from "contexts/CoinsContext";
import { useUser } from "contexts/UserContext";
import WatchlistCoinsTable, { RowObjCoins } from "./components/WatchlistCoinsTable";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import numeral from "numeral";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from "components/icons/IconBox";
import { MdBarChart } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";
import { getCurrencySymbol, useQuery } from "helpers/formatters";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import WatchlistTxTable, { RowObjTx } from "./components/WatchlistsTxTable";
import { useHistory } from "react-router-dom";
import { useWatchlists } from "contexts/WatchlistsContext";
import { links } from "helpers/links";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import AddNewTokenDrawer from "components/drawers/AddNewTokenDrawer";
import PieChartWatchlist from "./components/PieChart";
import Graph from "./components/Graph";


export default function WatchlistPage() {
	// Chakra Color Mode
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const [loadingText, setLoadingText] = useState<string>("Loading coins...");
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [watchlist, setWatchlist] = useState<BullFolio.Watchlist | null>(null);
	const [isForTx, setIsForTx] = useState(false);

	const { user, userData } = useUser();
	const { getWatchlistById, addTokenToWatchlist, deleteWatchlist } = useWatchlists();

	const query = useQuery();
  const history = useHistory();

	const handleAdd = async (tokenId: string, note: string | undefined, amount: number | undefined, buyPrice: number | undefined, isForTx: boolean, txType: string | undefined) => {
		let tokenIndex = -1;
		watchlist.tokens.forEach((token, index) => {
			if (token.id === tokenId) tokenIndex = index;
		});
		if(tokenIndex >= 0) {
			const res = await addTokenToWatchlist(tokenId, watchlist.id, note, amount, buyPrice, isForTx, txType as BullFolio.Watchlist.Transaction.TxType, tokenIndex);
			if (res) {
				setWatchlist(res);
			}
		}else{
			// token not yet in watchlist
			const res = await addTokenToWatchlist(tokenId, watchlist.id, note, amount, buyPrice, false, txType as BullFolio.Watchlist.Transaction.TxType, tokenIndex);
			if (res) {
				setWatchlist(res);
			}
		}
	}

	const createCoinsTableData = (): RowObjCoins[] => {
		if(watchlist && userData) {
			return watchlist.tokens.map((token, index) => {
				const _tokenData = userData.allIds.coins.find((x) => x.id === token.id);
				if(_tokenData) {
					console.log(_tokenData)
					const usdValue = _tokenData.current_price*token.currentBalance;
					const totalValue = getCurrentValue().usdNum;
					const percentage = usdValue / totalValue * 100;
					return ({
						id: index,
						price: `${getCurrencySymbol(userData.baseCurrency)}${numeral(_tokenData?.current_price).format(`0,0.00`)}`,
						balance: token.currentBalance.toString(),
						profit: ["+0", "+$0"],
						rank: _tokenData.market_cap_rank.toString(),
						name: [_tokenData.name, _tokenData.symbol, _tokenData.image, _tokenData.id],
						changeDay: (_tokenData.price_change_24h / _tokenData.current_price * 100).toFixed(1),
						percentage: `${percentage.toFixed(1)}%`,
						marketCap: `${getCurrencySymbol(userData.baseCurrency)}${numeral(_tokenData.market_cap).format(`0,0.00`)}`,
						balanceUsd: `${getCurrencySymbol(userData.baseCurrency)}${numeral(usdValue).format(`0,0.00`)}`,
					})
				}else{
					return ({
						id: index,
						price: `⚠ Token inactive!`,
						balance: token.currentBalance.toString(),
						profit: ["+0", "+$0"],
						rank: `⚠ Token inactive!`,
						name: [token.id],
						changeDay: `⚠ Token inactive!`,
						percentage: `⚠ Token inactive!`,
						marketCap: `⚠ Token inactive!`,
						balanceUsd: `⚠ Token inactive!`,
					})
				}
			});
		}else{
			return [];
		}
	}

	const getCurrentValue = () => {
		if(watchlist && userData) {
			let value = 0;
			let btcValue = 0;
			let changeUsd = 0;
			let changeBtc = 0;
			const btc = userData.allIds.coins.find((x) => x.id === "bitcoin");
			watchlist.tokens.forEach((token) => {
				const _tokenData = userData.allIds.coins.find((x) => x.id === token.id);
				if(_tokenData) {
					value += _tokenData.current_price*token.currentBalance;
					btcValue += (_tokenData.current_price*token.currentBalance)/btc.current_price;
					changeUsd += _tokenData.price_change_percentage_24h;
					changeUsd += _tokenData.price_change_percentage_24h / btc.price_change_24h;
				}
			});
			console.log(changeUsd, changeBtc)
			return ({
				usd: `${getCurrencySymbol(userData.baseCurrency)}${numeral(value).format(`0,0.00`)}`,
				btc: `฿ ${btcValue}`,
				changeUsd: `${changeUsd<0 ? "-": "+"}${(changeUsd/watchlist.tokens.length).toFixed(2)}%`,
				changeBtc: `${changeBtc<0 ? "-": "+"}${(changeBtc/watchlist.tokens.length).toFixed(2)}%`,
				usdNum: value,
			});
		}else{
			return {
				usd: "$-",
				btc: "฿-",
				changeUsd: "+0%",
				changeBtc: "+0%",
				usdNum: 1
			};
		}
	}

	const createTxTableData = () => {
		if(watchlist && userData) {
			const txData: RowObjTx[] = [];
			watchlist.tokens.forEach((token, index) => {
				const _tokenData = userData.allIds.coins.find((x) => x.id === token.id);
				token.transactions.forEach((tx, txIndex) => {
					const cost = tx.buyPrice*tx.balanceChange;
					const currentValue =(_tokenData?.current_price || 0) * tx.balanceChange;
					const profit = currentValue - cost;
					txData.push({
						id: [index, txIndex],
						price: _tokenData ? `${getCurrencySymbol(userData.baseCurrency)}${numeral(_tokenData.current_price).format(`0,0.00`)}` : `⚠ Token inactive!`,
						profit: [`${profit>=0 ? "+" : ""}${(profit / cost * 100).toFixed(1)}`, `${profit>=0 ? "+" : ""}${getCurrencySymbol(userData.baseCurrency)}${numeral(profit).format(`0,0.00`)}`],
						name: _tokenData ? [_tokenData.name, _tokenData.symbol, _tokenData.image, _tokenData.id]: [token.id],
						type: tx.type,
						note: tx.note,
						time: `${padZero(new Date(tx.buyDate).getDate())}.${padZero(new Date(tx.buyDate).getMonth() + 1)}.${new Date(tx.buyDate).getFullYear()}`,
						quantity: `${tx.type === "buy" ? "+" : "-"}` + tx.balanceChange.toString(),
						cost: `${getCurrencySymbol(userData.baseCurrency)}${numeral(cost).format(`0,0.00`)}`,
					});
				});
			});
			return txData.reverse();
		}else{
			return [];
		}
	}

	// Function to pad zeros to single digits
	const padZero = (num: number) => {
		return (num < 10 ? '0' : '') + num;
	};

  useEffect(() => {
    const id = query.get("id");
    if(id) {
			setIsLoading(true);
			setLoadingText("Loading watchlist.");
			if(userData && user) {
				(async () => {
					const res = await getWatchlistById(id);
					setWatchlist(res);
					setIsLoading(false);
					if(!res) {
						console.log("redirect");
					}
				})();
			}
    }else{
      history.push(`/#${links.allWatchlists}`);
    }
  }, [query, user, userData]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<BreadCrumpStrip
        links={[{
          href: `/#${links.allWatchlists}`,
          name: "All My Watchlists"
        }, {
          href: `/`,
          name: watchlist?.name || "Watchlist Details"
        }]}
        additional={{ mb: "4" }}
      />
			{!isLoading && watchlist ? (
				<>
					<Box px="4" mb="6" mt="5">
						<Flex justifyContent={"space-between"}>
							<Box>
								<Text fontWeight={"extrabold"} fontSize={"5xl"}>{watchlist.name}</Text>
								<Flex>
									<Text fontWeight={"thin"} fontSize={"md"} mx="2.5">WATCHLIST</Text>
									<Badge>{watchlist?.isPrivate === true ? "Private" : "Public"}</Badge>
								</Flex>
							</Box>
							<Box>
								<Text fontWeight={"extrabold"} fontSize={"3xl"} mb="-1" pb="-1">{getCurrentValue().usd}</Text>
								<Text fontWeight={"thin"} fontSize={"md"} mx="2.5" textColor={"green"} textAlign={"right"}>{getCurrentValue().changeUsd}</Text>
								<Text fontWeight={"normal"} fontSize={"md"} mx="2.5" mt="1.5" textAlign={"right"}>{getCurrentValue().btc}</Text>
								<Text fontWeight={"thin"} fontSize={"xs"} mx="2.5" textColor={"green"} textAlign={"right"}>{getCurrentValue().changeBtc}</Text>
							</Box>
						</Flex>
						<Text px="3">
							<i>{watchlist.description}</i>
						</Text>
					</Box>
					<Box px="4">
						<Accordion allowToggle>
							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex='1' textAlign='left'>
											<Text fontWeight={"bold"} fontSize={"lg"}>Graph</Text>
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4} pt={2}>
									{watchlist ? (
										<Graph
											balanceBtc={getCurrentValue().btc}
											balanceUsd={getCurrentValue().usd}
											watchlist={watchlist}
										/>
									):null}
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex='1' textAlign='left'>
											<Text fontWeight={"bold"} fontSize={"lg"}>Pie Chart</Text>
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4} pt={2}>
									{watchlist ? (
										<PieChartWatchlist
											watchlist={watchlist}
										/>
									):null}
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex='1' textAlign='left'>
											<Text fontWeight={"bold"} fontSize={"lg"}>Transactions</Text>
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4} pt={2}>
									<Flex justifyContent={"space-between"} px="4" mb="2.5">
										<Text pt="2" fontWeight={"bold"} fontSize={"xl"}>Transactions</Text>
										<Button variant="darkBrand" onClick={() => {setIsOpen(true); setIsForTx(true)}}>Add New</Button>
									</Flex>
									{watchlist.tokens.length>0 ? (
										<WatchlistTxTable watchlist={watchlist} tableData={createTxTableData()} />
									):(
										<Text textAlign={"center"}>No transactions in this watchlist.</Text>
									)}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Box>
					<Box mt="6">
						<Flex justifyContent={"space-between"} px="4" mb="2.5">
							<Text pt="2" fontWeight={"bold"} fontSize={"xl"}>Coins</Text>
							<Button variant="darkBrand" onClick={() => {setIsOpen(true); setIsForTx(false)}}>Add New</Button>
						</Flex>
						<Card>
							{watchlist.tokens.length>0 ? (
								<WatchlistCoinsTable watchlist={watchlist} tableData={createCoinsTableData()} />
							):(
								<Text textAlign={"center"}>No tokens in this watchlist.</Text>
							)}
						</Card>
					</Box>
					<Box px="4" mt="6">
						<Button variant="darkBrand" onClick={() => deleteWatchlist(watchlist?.id)}>Delete</Button>
					</Box>
				</>
			):null}
			{isLoading ? (
				<Loading text={loadingText} />
			):null}
			{!isLoading && !watchlist ? (
				<Text textAlign={"center"}>No watchlist found!</Text>
			):null}

			<AddNewTokenDrawer
				add={handleAdd}
				watchlistId={watchlist?.id || ""}
				isOpen={isOpen}
				close={() => setIsOpen(false)}
				isForTx={isForTx}
			/>
		</Box>
	);
}
