import {BullFolio} from "bullfolio-types";

// available timeframes
export const AVAILABLE_TIMEFRAMES: BullFolio.Timeframe[] = [
  "1h", "4h", "8h", "1d", "2d", "5d", "1w",
];

// default indicators add new here
export const INDICATOR_KEYS: string[] = [
  // STOCH RSIs
  "stoch_rsi_d:14,14,3,3:1",
  "stoch_rsi_d:14,14,3,3:4",
  "stoch_rsi_d:14,14,3,3:8",
  "stoch_rsi_d:14,14,3,3:24",
  "stoch_rsi_d:14,14,3,3:48",
  "stoch_rsi_d:14,14,3,3:120",
  "stoch_rsi_d:14,14,3,3:168",

  "stoch_rsi_k:14,14,3,3:1",
  "stoch_rsi_k:14,14,3,3:4",
  "stoch_rsi_k:14,14,3,3:8",
  "stoch_rsi_k:14,14,3,3:24",
  "stoch_rsi_k:14,14,3,3:48",
  "stoch_rsi_k:14,14,3,3:120",
  "stoch_rsi_k:14,14,3,3:168",

  // BBWPs
  "bbwp:13,252:1",
  "bbwp:13,252:4",
  "bbwp:13,252:8",
  "bbwp:13,252:24",
  "bbwp:13,252:48",
  "bbwp:13,252:120",
  "bbwp:13,252:168",

  "bbwp_ma:13,252,5:1",
  "bbwp_ma:13,252,5:4",
  "bbwp_ma:13,252,5:8",
  "bbwp_ma:13,252,5:24",
  "bbwp_ma:13,252,5:48",
  "bbwp_ma:13,252,5:120",
  "bbwp_ma:13,252,5:168",

  // RSIs
  "rsi:14:1",
  "rsi:14:4",
  "rsi:14:8",
  "rsi:14:24",
  "rsi:14:48",
  "rsi:14:120",
  "rsi:14:168",

  "rsi_ma:14,14:1",
  "rsi_ma:14,14:4",
  "rsi_ma:14,14:8",
  "rsi_ma:14,14:24",
  "rsi_ma:14,14:48",
  "rsi_ma:14,14:120",
  "rsi_ma:14,14:168",

  // EMAs
  "ema:32:1",
  "ema:32:4",
  "ema:32:8",
  "ema:32:24",
  "ema:32:48",
  "ema:32:120",
  "ema:32:168",

  "ema:58:1",
  "ema:58:4",
  "ema:58:8",
  "ema:58:24",
  "ema:58:48",
  "ema:58:120",
  "ema:58:168",

  "ema:5:1",
  "ema:5:4",
  "ema:5:8",
  "ema:5:24",
  "ema:5:48",
  "ema:5:120",
  "ema:5:168",

  "ema:10:1",
  "ema:10:4",
  "ema:10:8",
  "ema:10:24",
  "ema:10:48",
  "ema:10:120",
  "ema:10:168",

  "ema:11:1",
  "ema:11:4",
  "ema:11:8",
  "ema:11:24",
  "ema:11:48",
  "ema:11:120",
  "ema:11:168",

  "ema:20:1",
  "ema:20:4",
  "ema:20:8",
  "ema:20:24",
  "ema:20:48",
  "ema:20:120",
  "ema:20:168",

  "ema:21:1",
  "ema:21:4",
  "ema:21:8",
  "ema:21:24",
  "ema:21:48",
  "ema:21:120",
  "ema:21:168",

  "ema:50:1",
  "ema:50:4",
  "ema:50:8",
  "ema:50:24",
  "ema:50:48",
  "ema:50:120",
  "ema:50:168",

  "ema:55:1",
  "ema:55:4",
  "ema:55:8",
  "ema:55:24",
  "ema:55:48",
  "ema:55:120",
  "ema:55:168",

  "ema:100:1",
  "ema:100:4",
  "ema:100:8",
  "ema:100:24",
  "ema:100:48",
  "ema:100:120",
  "ema:100:168",

  "ema:200:1",
  "ema:200:4",
  "ema:200:8",
  "ema:200:24",
  "ema:200:48",
  "ema:200:120",
  "ema:200:168",

  // SMAs
  "sma:0:1",
  "sma:0:4",
  "sma:0:8",
  "sma:0:24",
  "sma:0:48",
  "sma:0:120",
  "sma:0:168",

  "sma:10:1",
  "sma:10:4",
  "sma:10:8",
  "sma:10:24",
  "sma:10:48",
  "sma:10:120",
  "sma:10:168",

  "sma:20:1",
  "sma:20:4",
  "sma:20:8",
  "sma:20:24",
  "sma:20:48",
  "sma:20:120",
  "sma:20:168",

  "sma:50:1",
  "sma:50:4",
  "sma:50:8",
  "sma:50:24",
  "sma:50:48",
  "sma:50:120",
  "sma:50:168",

  "sma:100:1",
  "sma:100:4",
  "sma:100:8",
  "sma:100:24",
  "sma:100:48",
  "sma:100:120",
  "sma:100:168",

  "sma:200:1",
  "sma:200:4",
  "sma:200:8",
  "sma:200:24",
  "sma:200:48",
  "sma:200:120",
  "sma:200:168",

  // MACDs

  // BBs

  // ADXs

  // SAR

  // ICHIMOKU
  "tenkan_sen:9:1",
  "tenkan_sen:9:4",
  "tenkan_sen:9:8",
  "tenkan_sen:9:24",
  "tenkan_sen:9:48",
  "tenkan_sen:9:120",
  "tenkan_sen:9:168",

  "kijun_sen:26:1",
  "kijun_sen:26:4",
  "kijun_sen:26:8",
  "kijun_sen:26:24",
  "kijun_sen:26:48",
  "kijun_sen:26:120",
  "kijun_sen:26:168",

  /*"senkou_span_a:9,26,26:1",
  "senkou_span_a:9,26,26:4",
  "senkou_span_a:9,26,26:8",
  "senkou_span_a:9,26,26:24",
  "senkou_span_a:9,26,26:48",
  "senkou_span_a:9,26,26:120",
  "senkou_span_a:9,26,26:168",

  "senkou_span_b:52,26:1",
  "senkou_span_b:52,26:4",
  "senkou_span_b:52,26:8",
  "senkou_span_b:52,26:24",
  "senkou_span_b:52,26:48",
  "senkou_span_b:52,26:120",
  "senkou_span_b:52,26:168",

  "chikou_span:26:1",
  "chikou_span:26:4",
  "chikou_span:26:8",
  "chikou_span:26:24",
  "chikou_span:26:48",
  "chikou_span:26:120",
  "chikou_span:26:168",*/

  // MARKET CYPHER
];


/* "wma:10:1",
"wma:20:1",
"wma:50:1",
"wma:100:1",
"wma:200:1",
"wma:10:24",
"wma:20:24",
"wma:50:24",
"wma:100:24",
"wma:200:24",*/
