import { Box, Button, Select, Text, useColorModeValue, Flex, Switch, FormLabel, Image, Icon, Input } from "@chakra-ui/react";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import { links } from "helpers/links";
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import TextFiled from "components/fields/TextField";
import Dropzone from "./Dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useState, useEffect } from "react";
import { BullFolio } from "bullfolio-types";
import { BsTrash } from "react-icons/bs";
import { useStrategies } from "contexts/StrategiesContext";
import Loading from "components/Loading/Loading";
import { useCoins } from "contexts/CoinsContext";
import BackTestDrawer from "components/drawers/BackTestDrawer";
import { AVAILABLE_TIMEFRAMES, INDICATOR_KEYS } from "helpers/defaultIndicators";
import { dissolveKey, getHoursFromTimeframe } from "helpers/formatters";


const CreateStrategyPage = () => {

  const textColor = useColorModeValue("navy.700", "white");
	const brand = useColorModeValue('brand.500', 'brand.400');

  const CONDITIONS: BullFolio.Strategy.Event.Condition.ConditionType[] = ["<", "<=", "==", ">", ">=", "in range"];
  const CONDITION_TYPES: BullFolio.Strategy.Event.Condition.ConditionType[] = ["<", "<=", "==", ">", ">="];

  const { createStrategy } = useStrategies();
  const { getAllCoins } = useCoins();

  const [isOpen, setIsOpen] = useState(false);
  const [coins, setCoins] = useState<BullFolio.CoinData.Data[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [strategy, setStrategy] = useState<BullFolio.Strategy>({
    id: "",
    name: "",
    description: "",
    image: "",
    private: true,
    timeframe: "1d",
    type: "recurring",
    userUid: "",
    events: [],
    against: "usd",
    created: "",
    author: "",
    isVerified: false,
    tokens: {
      type: "array",
      top: 0,
      array: ["bitcoin"],
      value: null,
      watchlistId: null
    },
  });

  const addEvent = () => {
    const _strategy = {...strategy};
    _strategy.events.push({
      name: "",
      description: "",
      type: "buy",
      conditions: [{
        condition: "<",
        script: {
          type: "ema",
          settings: [32]
        },
        value: {
          value: 0,
          range: null,
          indicator: null
        }
      }]
    });
    setStrategy(_strategy);
  };

  const deleteEvent = (eventIndex: number) => {
    const _strategy = {...strategy};
    _strategy.events.splice(eventIndex, 1);
    setStrategy(_strategy);
  };
  
  const addCondition = (eventIndex: number) => {
    const _strategy = {...strategy};
    _strategy.events[eventIndex].conditions.push({
      condition: "<",
      script: {
        type: "ema",
        settings: [32]
      },
      value: {
        value: 0,
        range: null,
        indicator: null
      }
    });
    setStrategy(_strategy);
  };

  const deleteCondition = (eventIndex: number, conditionIndex: number) => {
    const _strategy = {...strategy};
    _strategy.events[eventIndex].conditions.splice(conditionIndex, 1);
    setStrategy(_strategy);
  };

  const handleConditionChange = (val: string, eventIndex: number, conditionIndex: number) => {
    const _strategy = {...strategy};
    _strategy.events[eventIndex].conditions[conditionIndex].condition = val as BullFolio.Strategy.Event.Condition.ConditionType;
    if(val === "in range") {
      _strategy.events[eventIndex].conditions[conditionIndex].value.range = {low: 0, high: 0};
    }
    setStrategy(_strategy);
  };

  const handleConditionIndicatorSwitch = (eventIndex: number, conditionIndex: number) => {
    const _strategy = {...strategy};
    const beforeIndicator = _strategy.events[eventIndex].conditions[conditionIndex].value.indicator;
    const beforeValue = _strategy.events[eventIndex].conditions[conditionIndex].value.value;
    if(beforeIndicator === null) {
      _strategy.events[eventIndex].conditions[conditionIndex].value.indicator = {
        type: "ema",
        settings: []
      }
      _strategy.events[eventIndex].conditions[conditionIndex].value.value = null;
    }
    if(beforeValue === null) {
      _strategy.events[eventIndex].conditions[conditionIndex].value.indicator = null;
      _strategy.events[eventIndex].conditions[conditionIndex].value.value = 0;
    }
    setStrategy(_strategy);
  };

  const handleEventEdit = (type: "name" | "description", val: string, index: number) => {
    const _strategy = {...strategy};
    _strategy.events[index][type] = val;
    setStrategy(_strategy);
  };

  const handleEventType = (val: string, index: number) => {
    const _strategy = {...strategy};
    _strategy.events[index].type = val as BullFolio.Strategy.Event.Type;
    setStrategy(_strategy);
  };

  const handleIndicatorChange = (indicatorKey: string, eventIndex: number, conditionIndex: number , isValue: boolean) => {
    const _strategy = {...strategy};
    const dissolvedKey = dissolveKey(indicatorKey);
    if(isValue) {
      _strategy.events[eventIndex].conditions[conditionIndex].value.indicator.type = dissolvedKey.type as BullFolio.Strategy.Event.Condition.Type;
      strategy.events[eventIndex].conditions[conditionIndex].value.indicator.settings = dissolvedKey.settings;
    }else{
      _strategy.events[eventIndex].conditions[conditionIndex].script.type = dissolvedKey.type as BullFolio.Strategy.Event.Condition.Type;
      _strategy.events[eventIndex].conditions[conditionIndex].script.settings = dissolvedKey.settings;
    }
    setStrategy(_strategy);
  };

  const handleConditionValue = (val: string, eventIndex: number, conditionIndex: number , key: "range.low"|"range.high"|"value") => {
    const _strategy = {...strategy};
    if(key==="value") {
      _strategy.events[eventIndex].conditions[conditionIndex].value[key] = Number(val);
    }else{
      const first = key.split(".")[0] as "range";
      const last = key.split(".")[1] as "low" | "high";
      _strategy.events[eventIndex].conditions[conditionIndex].value[first][last] = Number(val);
    }
    setStrategy(_strategy);
  };

  const handleEditCoin = (id: string) => {
    const prev = {...strategy};
    prev.tokens.array = [id];
    setStrategy(prev);
  }

  const handleEditValueKey = (id: string) => {
    const prev = {...strategy};
    prev.tokens.value.key = id as BullFolio.Alert.AllowedKeys;
    setStrategy(prev);
  }

  const handleEditValueCondition = (id: string) => {
    const prev = {...strategy};
    prev.tokens.value.condition = id as BullFolio.Strategy.Event.Condition.ConditionType;
    setStrategy(prev);
  }

  const handleEditValueValue = (id: string) => {
    const prev = {...strategy};
    prev.tokens.value.value = Number(id);
    setStrategy(prev);
  }

  const create = async() => {
    console.log("creating")
    console.log(strategy);
    setIsLoading(true);
    await createStrategy(strategy);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const _coins = await getAllCoins(1);
      setCoins(_coins.coins);
    })();
  }, []);

  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `/#${links.myStrategies}`,
          name: "My Strategies"
        }, {
          href: `/`,
          name: "Create"
        }]}
        additional={{ mb: "4" }}
      />
      {!isLoading ? (
        <Card>
          <Text color={textColor} fontSize="xl" fontWeight={"900"} px="1" mb="3">
            Create Strategy
          </Text>
          <InputField
            label="Name"
            placeholder="My Strategy"
            mb="10px"
            value={strategy.name}
            onChange={(e: any) => setStrategy({...strategy, name: e.target.value})}
          />
          <TextFiled
            label="Description"
            placeholder="Your short description"
            mb="6"
            value={strategy.description}
            onChange={(e: any) => setStrategy({...strategy, description: e.target.value})}
          />
          {/*<InputField
            label="Your Nickname"
            placeholder="How should you be called?"
            mb="10px"
            value={strategy.name}
            onChange={(e: any) => setStrategy({...strategy, author: e.target.value})}
      />*/}
          <Box mb="6">
            <Text fontWeight={"extrabold"}>
              Select Timeframe
            </Text>
            <Select defaultValue={"1d"} onChange={(e) => setStrategy({...strategy, timeframe: e.target.value as BullFolio.Timeframe})}>
              {AVAILABLE_TIMEFRAMES.map(x => {
                return(
                  <option key={x} value={x}>{x.toUpperCase()}</option>
                )
              })}
            </Select>
          </Box>
          <Box mb={"6"}>
            <Flex>
              <Switch
                id="top"
                mt="0.5"
                isChecked={!strategy.private}
                onChange={() => setStrategy({...strategy, private: !strategy.private})}
              />
              <FormLabel htmlFor='isDisabled' ml="3">Make this strategy visible in markeplace.</FormLabel>
            </Flex>
            <Flex>
              <Switch
                id="top"
                mt="0.5"
                isChecked={strategy.type === "one-time"}
                onChange={() => setStrategy({...strategy, type: strategy.type === "one-time" ? "recurring" : "one-time"})}
              />
              <FormLabel htmlFor='isDisabled' ml="3">One-time stratgey (gets deleted after action is made).</FormLabel>
            </Flex>
          </Box>
          {/*<Box mb="6">
            <Text fontWeight={"extrabold"}>
              Image
            </Text>
            <Dropzone
              file={strategy.image}
              onChange={(e: string) => setStrategy({...strategy, image: e})}
              content={
                <Box>
                  {strategy.image ? (
                    <Image
                      src={strategy.image}
                      h='80px'
                      alt="Uploaded logo"
                      mb="2"
                      mx="auto"
                    />
                  ):(
                    <Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
                  )} 
                  <Text
                    mx='auto'
                    mb='12px'
                    fontSize='lg'
                    fontWeight='700'
                    whiteSpace='pre-wrap'
                    color={textColor}>
                    Drop your files here, or{' '}
                    <Text as='span' fontSize='lg' fontWeight='700' color={brand}>
                      browse
                    </Text>
                  </Text>
                  <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                    PNG, JPG and GIF files are allowed
                  </Text>
                </Box>
              }
            />
            </Box>*/}
          <Flex>
            <Select defaultValue={strategy.against} onChange={(e) => setStrategy({...strategy, against: e.target.value as BullFolio.Strategy.Against})}>
              {["usd", "btc"].map((against) => {
                return(
                  <option key={against} value={against}>{against.toUpperCase()}</option>
                )
              })}
            </Select>
          </Flex>
          {/*
            <Box mb="6">
              <Text fontWeight={"extrabold"}>
                Save logs for tokens:
              </Text>
              {coins ? (
                <>
                  <Flex mt="3">
                    <Switch
                      id="array"
                      mt="0.5"
                      isChecked={strategy.tokens.type === "array"}
                      onChange={() => {
                        const prev = {...strategy};
                        if(strategy.tokens.type === "value" || strategy.tokens.type === "top") {
                          console.log("in if")
                          prev.tokens.value = null;
                          prev.tokens.type = "array";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "top"
                          prev.tokens.value = null;
                        }
                        setStrategy(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Individual Token (eg. BTC)</FormLabel>
                  </Flex>
                  <Flex>
                    <Switch
                      id="metrics"
                      mt="0.5"
                      isChecked={strategy.tokens.type === "value"}
                      onChange={() => {
                        const prev = {...strategy};
                        if(strategy.tokens.type === "array" || strategy.tokens.type === "top") {
                          console.log("in if")
                          prev.tokens.value = {
                            key: "ath",
                            value: 0,
                            condition: "=="
                          }
                          prev.tokens.type = "value";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "array"
                          prev.tokens.value = null;
                        }
                        setStrategy(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Select By Metric (eg. all tokens with market cap above $100m, ...):</FormLabel>
                  </Flex>
                  <Flex>
                    <Switch
                      id="top"
                      mt="0.5"
                      isChecked={strategy.tokens.type === "top"}
                      onChange={() => {
                        const prev = {...strategy};
                        if(strategy.tokens.type === "array" || strategy.tokens.type === "value") {
                          console.log("in if")
                          prev.tokens.value = null;
                          prev.tokens.array = [];
                          prev.tokens.type = "top";
                        }else{
                          console.log("in else")
                          prev.tokens.type = "array"
                          prev.tokens.value = null;
                        }
                        setStrategy(prev);
                      }}
                    />
                    <FormLabel htmlFor='isDisabled' ml="3">Use Top Tokens (eg. top 10 tokens):</FormLabel>
                  </Flex>
                  {strategy.tokens.type === "array" ? (
                    <Select onChange={(e) => handleEditCoin(e.target.value)}>
                      {coins.map(coin => {
                        return(
                          <option key={coin.data.id} value={coin.data.id}>{coin.data.name} ({coin.data.symbol.toUpperCase()})</option>
                        )
                      })}
                    </Select>
                  ):null}
                  {strategy.tokens.type === "value" ? (
                    <Flex>
                      <Select onChange={(e) => handleEditValueKey(e.target.value)}>
                        {Object.keys(coins[0]).map(key => {
                          return(
                            <option key={key} value={key}>{key}</option>
                            )
                          })}
                      </Select>
                      <Select onChange={(e) => handleEditValueCondition(e.target.value)}>
                        {CONDITION_TYPES.map(val => {
                          return(
                            <option key={val} value={val}>{val}</option>
                            )
                          })}
                      </Select>
                      <Input
                        placeholder="Value"
                        value={strategy.tokens.value?.value || ""}
                        onChange={(e) => handleEditValueValue(e.target.value)}
                      />
                    </Flex>
                  ):null}
                  {strategy.tokens.type === "top" ? (
                    <Box>
                      <InputField
                        placeholder="20"
                        label="How Many Top Coins"
                        value={strategy.tokens.top}
                        type="number"
                        onChange={(e: any) => setStrategy({...strategy, tokens: {...strategy.tokens, top: Number(e.target.value)}})}
                      />
                    </Box>
                  ):null}
                </>
              ):(
                <Loading text="Loading Tokens..." />
              )}
            </Box>
              ):null*/}
          <Box>
            <Text fontWeight={"extrabold"}>
              Strategy Events:
            </Text>
            {strategy.events.map((strategyEvent, index) => {
              return(
                <Card mb="3" key={index}>
                  <Button onClick={() => deleteEvent(index)}>
                    <BsTrash />
                  </Button>
                  <InputField
                    label="Name"
                    placeholder="My Event"
                    mb="10px"
                    value={strategy.events[index].name}
                    onChange={(e: any) => handleEventEdit("name", e.target.value, index)}
                  />
                  <TextFiled
                    label="Description"
                    placeholder="Your short description of this event"
                    mb="4"
                    value={strategy.events[index].description}
                    onChange={(e: any) => handleEventEdit("description", e.target.value, index)}
                  />
                  <Box mb="4">
                    <Text fontWeight={"extrabold"}>
                      Event Type
                    </Text>
                    <Select placeholder='Select Event' mt="1" onChange={(e) => handleEventType(e.target.value, index)}>
                      <option value='buy'>Buy</option>
                      <option value='sell'>Sell</option>
                      <option value='terminate'>Terminate</option>
                    </Select>
                  </Box>
                  <Box mb="4">
                    <Text fontWeight={"extrabold"}>
                      Conditions
                    </Text>
                    {strategy.events[index].conditions.map((eventCondition, conditionIndex) => {
                      return(
                        <Box px="4">
                          <Box mb="4">
                            <Text fontWeight={"extrabold"}>
                              Select Indicator:
                            </Text>
                            <Box>
                              <Flex>
                                <Select placeholder='Select Indicator' mt="1" onChange={(e) => handleIndicatorChange(e.target.value, index, conditionIndex, false)}>
                                  {INDICATOR_KEYS.map((indicator) => {
                                    const dissolvedKey = dissolveKey(indicator);
                                    if(dissolvedKey.timeframe === getHoursFromTimeframe(strategy.timeframe)) {
                                      return(
                                        <option value={indicator} key={indicator}>{indicator.toUpperCase()}</option>
                                      )
                                    }
                                  })}
                                </Select>
                              </Flex>
                              <Box mb="4">
                                <Text fontWeight={"extrabold"}>
                                  Select Condition
                                </Text>
                                <Flex>
                                  <Select placeholder="Select condition" onChange={(e) => handleConditionChange(e.target.value, index, conditionIndex)}>
                                    {CONDITIONS.map((indicator) => {
                                      return(
                                        <option value={indicator} key={indicator}>{indicator}</option>
                                      )
                                    })}
                                  </Select>
                                  {strategy.events[index].conditions[conditionIndex].condition === "in range" ? (
                                    <Flex>
                                      <Input
                                        onChange={(e) => handleConditionValue(e.target.value, index, conditionIndex, "range.low")}
                                        value={strategy.events[index].conditions[conditionIndex]?.value?.range?.low || 0}
                                        type="number"
                                        placeholder="Range low"
                                      />
                                      <Text> - </Text>
                                      <Input
                                        onChange={(e) => handleConditionValue(e.target.value, index, conditionIndex, "range.high")}
                                        value={strategy.events[index].conditions[conditionIndex]?.value?.range?.high || 0}
                                        type="number"
                                        placeholder="Range high"
                                      />
                                    </Flex>
                                  ):(
                                    <Box>
                                      <Flex>
                                        {strategy.events[index].conditions[conditionIndex].value.value !== null ? (
                                          <Input
                                            onChange={(e) => handleConditionValue(e.target.value, index, conditionIndex, "value")}
                                            value={strategy.events[index].conditions[conditionIndex].value.value || 0}
                                            type="number"
                                            placeholder="Value"
                                          />
                                        ):(
                                          <Flex>
                                            <Select placeholder='Select Indicator' mt="1" onChange={(e) => handleIndicatorChange(e.target.value, index, conditionIndex, true)}>
                                              {INDICATOR_KEYS.map((indicator) => {
                                                const dissolvedKey = dissolveKey(indicator);
                                                if(dissolvedKey.timeframe === getHoursFromTimeframe(strategy.timeframe)) {
                                                  return(
                                                    <option value={indicator} key={indicator}>{indicator.toUpperCase()}</option>
                                                  )
                                                }
                                              })}
                                            </Select>
                                          </Flex>
                                        )}
                                      </Flex>
                                      <Flex>
                                        <Switch
                                          id="top"
                                          mt="0.5"
                                          isChecked={!strategy.events[index].conditions[conditionIndex].value.indicator === null}
                                          onChange={() => handleConditionIndicatorSwitch(index, conditionIndex)}
                                        />
                                        <FormLabel htmlFor='isDisabled' ml="3">Use another indicator instead</FormLabel>
                                      </Flex>
                                    </Box>
                                  )}
                                </Flex>
                              </Box>
                            </Box>
                          </Box>
                          <Button onClick={() => deleteCondition(index, conditionIndex)}>
                            <BsTrash />
                          </Button>
                        </Box>
                      )
                    })}
                    <Button
                      mt="3"
                      variant={"brand"}
                      onClick={() => addCondition(index)}
                    >
                      Add New Event Condition
                    </Button>
                  </Box>
                </Card>
              )
            })}
            <Button
              mt="3"
              variant={"brand"}
              onClick={() => addEvent()}
            >
              Add New Strategy Event
            </Button>
          </Box>
          <Flex>
            <Button width={"100%"} mt="12" mr="4" variant={"darkBrand"} onClick={() => setIsOpen(true)}>
              Show Back-Testing Results
            </Button>
            <Button width={"100%"} mt="12" variant={"darkBrand"} onClick={() => create()}>
              Create Strategy
            </Button>
          </Flex>
        </Card>
      ):(
        <Loading text="Saving your strategy..." />
      )}

      <BackTestDrawer
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        strategyData={strategy}
        coins={coins}
      />
    </Box>
  );
};

export default CreateStrategyPage;