import { Badge, Box, Button, Flex, Grid, Icon, Image, Link, LinkBox, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text, useColorModeValue } from "@chakra-ui/react"
import { BullFolio } from "bullfolio-types";
import { useUser } from "contexts/UserContext";
import { capitalizeFirstLetter, getCurrencySymbol } from "helpers/formatters";
import numeral from "numeral";
import Chart from "./Chart";
import Card from "components/card/Card";
import { links } from "helpers/links";
import { BsBarChart, BsBell } from "react-icons/bs";
import { TbChartCandle } from "react-icons/tb";
import DetailsTable from "./DetailsTable";
import { useState, useEffect } from "react";
import IconBox from "components/icons/IconBox";
import { FcBearish, FcBullish, FcNeutralTrading } from "react-icons/fc";
import CGlogo from "assets/img/logo/coingecko.png";
import AddNewTokenDrawer from "components/drawers/AddNewTokenDrawer";
import AddToWatchlistDrawer from "components/drawers/AddToWatchlistDrawer";

const widthSizes = {
  sm: '100%',
  md: "100%",
  lg: "75%",
};

const Content = (props: { coin: BullFolio.CoinData, bitcoin: BullFolio.CoinData, isModal?: boolean }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const secondaryText = useColorModeValue('gray.700', 'white');

  const { coin, bitcoin, isModal=false } = props;

  const { userData } = useUser();

  const [currencySymbol, setCurrencySymbol] = useState<string>("$");
  const [isOpen, setIsOpen] = useState(false);

  /*const calculatePriceChange = () => {
    if(coin) {
      const currentPrice = coin.data.current_price;
      const priceAtChange = coin.data.trend_1d.price_at_change || 0;
      const diff = currentPrice-priceAtChange;
      const per = diff / priceAtChange * 100;
      return per;
    }
    return 0;
  }*/

  const openModal = () => {
    setIsOpen(true);
  }

  const calculateBtcPrice = () => {
    return coin.data.current_price / bitcoin.data.current_price;
  }

  const calculateBtcPriceChange = () => {
    return (coin.data.price_change_percentage_24h / bitcoin.data.price_change_percentage_24h - 1).toFixed(2);
  }

  useEffect(() => {
    if(userData) {
      setCurrencySymbol(getCurrencySymbol(userData.baseCurrency));
    }else{
      setCurrencySymbol("$");
    }
  }, [userData]);

  return (
    <Box px="4" mt="4" pb="12">
      <Badge colorScheme={"brand"}>Rank #{coin.data.market_cap_rank}</Badge>
      <Flex mt="4">
        <Image src={coin.data.image} width="65px" height={"65px"} alt="Coin logo" mr="3" rounded={"full"} />
        <Text color={textColor} fontSize='3xl' fontWeight='800' mr="3.5" mt="2.5">
          {coin.data.name}
        </Text>
        <Text color={textColor} fontSize='2xl' fontWeight='500' mt="4">
          {coin.data.symbol.toUpperCase()}
        </Text>
      </Flex>
      <Flex px="3">
        <Text color={textColor} fontSize='3xl' fontWeight='800' mr="3.5" mt="2.5">
          {getCurrencySymbol(userData.baseCurrency)} {numeral(coin.data.current_price).format("0,0.00")}
        </Text>
        <Text color={coin.data.price_change_percentage_24h>=0 ? "green.500" : "red.500"} fontSize='2xl' fontWeight='800' mt="3">
          {coin.data.price_change_percentage_24h.toFixed(2)} %
        </Text>
      </Flex>
      <Flex px="4">
        <Text color={secondaryText} fontSize='xl' fontWeight='600' mr="3.5" mt="2.5">
          ₿ {calculateBtcPrice()}
        </Text>
        <Text color={Number(calculateBtcPriceChange())>=0 ? "green.500" : "red.500"} fontSize='xl' fontWeight='800' mt="3">
          {calculateBtcPriceChange()} %
        </Text>
      </Flex>
      <Box mt="4" width={widthSizes} px="3">
        <RangeSlider isDisabled={true} aria-label={['min', 'max']} defaultValue={[0, Number((coin.data.current_price/coin.data.high_24h*100).toFixed(0))]}>
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
        </RangeSlider>
        <Flex justifyContent={"space-between"}>
          <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.low_24h).format("0,00.00")}</Text>
          <Text fontSize='lg' fontWeight='500'>24H Range</Text>
          <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.high_24h).format("0,00.00")}</Text>
        </Flex>
      </Box>
     
      <Chart id={coin.data.id} coin={coin} bitcoin={bitcoin} isModal={isModal} />

      <Grid
        templateColumns={{
          sm: '1fr 1fr',
          md: "1fr 1fr",
          lg: "1fr 1fr 1fr 1fr",
				}}
        width={widthSizes}
        my="10"
        gap="6"
      >
        <Card rounded={"3xl"} width={"fit-content"} onClick={() => openModal()} cursor="pointer">
          <Flex w="100%">
            <Icon as={BsBarChart} h="40px" width={"40px"} mr="4" />
            <Text fontSize={"xl"} fontWeight="extrabold" mt="1">Add to Watchlist</Text>
          </Flex>
        </Card>
        <Card rounded={"3xl"} width={"fit-content"} cursor="pointer">
          <Link href={`/#${links.createAlert}`}>
            <Flex w="100%">
              <Icon as={BsBell} h="40px" width={"40px"} mr="4" />
              <Text fontSize={"xl"} fontWeight="extrabold" mt="1">Setup Alert</Text>
            </Flex>
          </Link>
        </Card>
        <Card rounded={"3xl"} width={"fit-content"} cursor="pointer">
          <Link href={`/#${links.createStrategy}`}>
            <Flex w="100%">
              <Icon as={TbChartCandle} h="40px" width={"40px"} mr="4" />
              <Text fontSize={"xl"} fontWeight="extrabold" mt="1">Setup Strategy</Text>
            </Flex>
          </Link>
        </Card>
        <Card rounded={"3xl"} width={"fit-content"} cursor="pointer">
          <Link href={`${links.coingecko}/coins/${coin.data.id}`} isExternal>
            <Flex w="100%">
              <Image h="40px" width={"40px"} mr="4" src={CGlogo} />
              <Text fontSize={"xl"} fontWeight="extrabold" mt="1">Coingecko</Text>
            </Flex>
          </Link>
        </Card>
      </Grid>

      <DetailsTable
        coin={coin}
        currencySymbol={currencySymbol}
      />

      <AddToWatchlistDrawer
        close={() => setIsOpen(false)}
        isOpen={isOpen}
        token={coin.data}
      />
    </Box>
  );
};

export default Content;