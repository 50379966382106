import React, { useState, createContext, useContext, useEffect } from "react";
import { functions } from "../helpers/firebase";
import { useToast } from '@chakra-ui/react'
import { getToast } from "../helpers/formatters";
import { httpsCallable } from "firebase/functions";
import { useUser } from "./UserContext";
import { BullFolio } from "bullfolio-types";

interface WatchlistsProviderProps {
  children: React.ReactNode
}

interface WatchlistsContextProps {
  createWatchlist: (name: string, description: string, isPrivate: boolean) => Promise<void>;
  getAllWatchlists: () => Promise<BullFolio.Watchlist[] | null>;
  deleteWatchlist: (id: string) => Promise<void>;
  getWatchlistById: (id: string) => Promise<BullFolio.Watchlist | null>;
  addTokenToWatchlist: (tokenId: string, watchlist: string, note: string | undefined, amount: number | undefined, buyPrice: number | undefined, isForTx: boolean, txType: BullFolio.Watchlist.Transaction.TxType | undefined, tokenIndex: number | undefined) => Promise<BullFolio.Watchlist | null>;
  removeTokenFromWatchlist: (watchlist: BullFolio.Watchlist, tokenIndex: number) => Promise<void>;
  removeTxFromWatchlist: (watchlist: BullFolio.Watchlist, tokenIndex: number, txIndex: number, newBalance: number) => Promise<void>;
}

const WatchlistsContext = createContext<WatchlistsContextProps>({
  createWatchlist: (watchlist) => new Promise(() => { }),
  getAllWatchlists: () => new Promise(() => null),
  deleteWatchlist: (id) => new Promise(() => {  }),
  getWatchlistById: (id) => new Promise(() => null),
  addTokenToWatchlist: (tokenId, watchlistId, note, amount, buyPrice, isForTx, txType, tokenIndex) => new Promise(() => { }),
  removeTokenFromWatchlist: (watchlist, tokenIndex) => new Promise(() => { }),
  removeTxFromWatchlist: (watchlist, tokenIndex, txIndex, newBalance) => new Promise(() => { }),
});


export const WatchlistsProvider = ({ children }: WatchlistsProviderProps) => {

  const { user, userData } = useUser();
  const toast = useToast();

  const [watchlists, setWatchlists] = useState<BullFolio.Watchlist[]>([]);

  const createWatchlist = async (name: string, description: string, isPrivate: boolean) => {
    if(!user || !userData) {
      return;
    };

    try{
      const createWatchlistF = httpsCallable(functions, "createWatchlist");
      const result = await createWatchlistF({
        name: name,
        description: description,
        isPrivate: isPrivate
      });
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting coins!", message));
    }
  }

  const getAllWatchlists = async (): Promise<BullFolio.Watchlist[] | null> => {
    if(!user || !userData) {
      return null;
    };

    try{
      const getAllUserWatchlists = httpsCallable(functions, "getUserWatchlists");
      const result = await getAllUserWatchlists();
      const _watchlists = result.data as BullFolio.Watchlist[];
      setWatchlists(_watchlists);
      return _watchlists;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting coins!", message));
      return null;
    }
  }

  const deleteWatchlist = async (id: string) => {
    if(user && userData && id) {
      try{
        toast(getToast("info", "Deleting", "Deleting Watchlist, please wait."));
        const deleteWatchlist = httpsCallable(functions, "deleteWatchlist");
        await deleteWatchlist({
          watchlistId: id
        });
        toast(getToast("success", "Deleted", "Watchlist was deleted."));
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while getting coins!", message));
      }
    }
  }

  const removeTokenFromWatchlist = async (watchlist: BullFolio.Watchlist, tokenIndex: number) => {
    if(user && userData && watchlist) {
      try{
        toast(getToast("info", "Removing Token", "removing token from Watchlist, please wait."));
        const deleteWatchlist = httpsCallable(functions, "removeTokenFromWatchlist");
        await deleteWatchlist({
          watchlist: watchlist,
          tokenIndex: tokenIndex
        });
        toast(getToast("success", "Removed", "Token removed successfully."));
        window.location.reload(); 
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while removing token!", message));
      }
    }
  }

  const removeTxFromWatchlist = async (watchlist: BullFolio.Watchlist, tokenIndex: number, txIndex: number, newBalance: number) => {
    if(user && userData && watchlist) {
      try{
        toast(getToast("info", "Deleting Transaction", "Deleting Transaction, please wait."));
        const deleteWatchlist = httpsCallable(functions, "deleteTransactionFromWatchlist");
        await deleteWatchlist({
          tokenIndex: tokenIndex,
          watchlist: watchlist,
          txIndex: txIndex,
          newCurrentBalance: newBalance
        });
        toast(getToast("success", "Deleted", "Transaction was deleted."));
        window.location.reload(); 
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while Deleting Transaction!", message));
      }
    }
  }

  const getWatchlistById = async (id: string) => {
    if(user && userData && id) {
      let toReturn: BullFolio.Watchlist = null;
      
      watchlists?.forEach((x) => {
        if(x.id === id) toReturn = x;
      });

      if (toReturn) {
        return toReturn;
      }else{
        try{
          const getByIdF = httpsCallable(functions, "getUserWatchlistById");
          const res = await getByIdF({
            watchlistId: id
          });
          const result = res.data as BullFolio.Watchlist | null;
          if (result && !watchlists.includes(result)) {
            watchlists.push(result);
          }
          return result;
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Something went wrong while getting strategy!", message));
        }
      }
    }
  }

  const addTokenToWatchlist = async (tokenId: string, watchlistId: string, note: string | undefined, amount: number | undefined, buyPrice: number | undefined, isForTx: boolean, txType: BullFolio.Watchlist.Transaction.TxType | undefined, tokenIndex: number | undefined): Promise<BullFolio.Watchlist | null> => {
    const watchlistToSend = watchlists.find(obj => obj.id === watchlistId);
    console.log(watchlistToSend, watchlistId, watchlists)
    if(tokenId && watchlistToSend && user && userData) {
      try{
        const getByIdF = httpsCallable(functions, "addTokenToWatchlist");
        console.log("is for tx ", watchlistToSend);
        const _watchlist = (await getByIdF({
          watchlist: watchlistToSend,
          tokenId: tokenId,
          note: note,
          amount: amount,
          buyPrice: buyPrice,
          time: (new Date()).toString(),
          isForTx: isForTx,
          txType: txType,
          tokenIndex: tokenIndex
        })).data as BullFolio.Watchlist | null;
        console.log("res");
        console.log(_watchlist)
        if(_watchlist) {
          // update watchlist state
          const _allWatchlists = [...watchlists];
          let index = _allWatchlists.findIndex(obj => obj.id === watchlistId);
          if (index !== -1) {
            _allWatchlists.splice(index, 1, _watchlist);
          }
          setWatchlists(_allWatchlists);
        }
        return _watchlist;
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while getting strategy!", message));
        return null;
      }
    }
    return null;
  }

  useEffect(() => {
    if (watchlists) {
      console.log("watchlists", watchlists);
    }
  }, [watchlists]);

  return (
    <WatchlistsContext.Provider
      value={{
        createWatchlist,
        getAllWatchlists,
        deleteWatchlist,
        getWatchlistById,
        addTokenToWatchlist,
        removeTokenFromWatchlist,
        removeTxFromWatchlist,
      }}
    >
      {children}
    </WatchlistsContext.Provider>
  );
};

export const useWatchlists = () => {
  return useContext(WatchlistsContext);
}