import { BullFolio } from "bullfolio-types";

const chains = [
  {
    id: "valobit",
    chain_identifier: null,
    name: "Valobit",
    shortname: "",
    native_coin_id: "valobit",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/182/thumb/valobit.png?1708317270",
      small: "https://coin-images.coingecko.com/asset_platforms/images/182/small/valobit.png?1708317270",
      large: "https://coin-images.coingecko.com/asset_platforms/images/182/large/valobit.png?1708317270"
    }
  },
  {
    id: "arbitrum-nova",
    chain_identifier: 42170,
    name: "Arbitrum Nova",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/93/thumb/AN_logomark.png?1706606703",
      small: "https://coin-images.coingecko.com/asset_platforms/images/93/small/AN_logomark.png?1706606703",
      large: "https://coin-images.coingecko.com/asset_platforms/images/93/large/AN_logomark.png?1706606703"
    }
  },
  {
    id: "factom",
    chain_identifier: null,
    name: "Factom",
    shortname: "",
    native_coin_id: "factom",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "monad",
    chain_identifier: null,
    name: "Monad",
    shortname: "",
    native_coin_id: "monad",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22182/thumb/monad.png?1729046263",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22182/small/monad.png?1729046263",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22182/large/monad.png?1729046263"
    }
  },
  {
    id: "fraxtal",
    chain_identifier: 252,
    name: "Fraxtal",
    shortname: "",
    native_coin_id: "fraxtal",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/193/thumb/fraxtal.jpeg?1709564289",
      small: "https://coin-images.coingecko.com/asset_platforms/images/193/small/fraxtal.jpeg?1709564289",
      large: "https://coin-images.coingecko.com/asset_platforms/images/193/large/fraxtal.jpeg?1709564289"
    }
  },
  {
    id: "zano",
    chain_identifier: null,
    name: "Zano",
    shortname: "",
    native_coin_id: "zano",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "picasso",
    chain_identifier: null,
    name: "Picasso",
    shortname: "",
    native_coin_id: "picasso",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "findora",
    chain_identifier: null,
    name: "Findora",
    shortname: "",
    native_coin_id: "findora",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/91/thumb/findora.jpeg?1706606402",
      small: "https://coin-images.coingecko.com/asset_platforms/images/91/small/findora.jpeg?1706606402",
      large: "https://coin-images.coingecko.com/asset_platforms/images/91/large/findora.jpeg?1706606402"
    }
  },
  {
    id: "bitkub-chain",
    chain_identifier: 96,
    name: "Bitkub Chain",
    shortname: "",
    native_coin_id: "bitkub-coin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/109/thumb/bitkub.jpeg?1706606416",
      small: "https://coin-images.coingecko.com/asset_platforms/images/109/small/bitkub.jpeg?1706606416",
      large: "https://coin-images.coingecko.com/asset_platforms/images/109/large/bitkub.jpeg?1706606416"
    }
  },
  {
    id: "onus",
    chain_identifier: null,
    name: "ONUS",
    shortname: "",
    native_coin_id: "onus",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/115/thumb/onus.jpeg?1706606579",
      small: "https://coin-images.coingecko.com/asset_platforms/images/115/small/onus.jpeg?1706606579",
      large: "https://coin-images.coingecko.com/asset_platforms/images/115/large/onus.jpeg?1706606579"
    }
  },
  {
    id: "empire",
    chain_identifier: 3693,
    name: "Empire",
    shortname: "",
    native_coin_id: "empire-capital-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/118/thumb/empire.jpeg?1706606582",
      small: "https://coin-images.coingecko.com/asset_platforms/images/118/small/empire.jpeg?1706606582",
      large: "https://coin-images.coingecko.com/asset_platforms/images/118/large/empire.jpeg?1706606582"
    }
  },
  {
    id: "areon-network",
    chain_identifier: null,
    name: "Areon Network",
    shortname: "",
    native_coin_id: "areon-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/172/thumb/areon.jpeg?1706606591",
      small: "https://coin-images.coingecko.com/asset_platforms/images/172/small/areon.jpeg?1706606591",
      large: "https://coin-images.coingecko.com/asset_platforms/images/172/large/areon.jpeg?1706606591"
    }
  },
  {
    id: "shibarium",
    chain_identifier: null,
    name: "Shibarium",
    shortname: "",
    native_coin_id: "bone-shibaswap",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/149/thumb/shibarium-removebg-preview.png?1706606593",
      small: "https://coin-images.coingecko.com/asset_platforms/images/149/small/shibarium-removebg-preview.png?1706606593",
      large: "https://coin-images.coingecko.com/asset_platforms/images/149/large/shibarium-removebg-preview.png?1706606593"
    }
  },
  {
    id: "kujira",
    chain_identifier: null,
    name: "Kujira",
    shortname: "",
    native_coin_id: "kujira",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/129/thumb/kujira.jpeg?1706606596",
      small: "https://coin-images.coingecko.com/asset_platforms/images/129/small/kujira.jpeg?1706606596",
      large: "https://coin-images.coingecko.com/asset_platforms/images/129/large/kujira.jpeg?1706606596"
    }
  },
  {
    id: "vite",
    chain_identifier: null,
    name: "Vite",
    shortname: "",
    native_coin_id: "vite",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "kusama",
    chain_identifier: null,
    name: "Kusama",
    shortname: "",
    native_coin_id: "kusama",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "komodo",
    chain_identifier: null,
    name: "Komodo",
    shortname: "",
    native_coin_id: "komodo",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "counterparty",
    chain_identifier: null,
    name: "Counterparty",
    shortname: "",
    native_coin_id: "counterparty",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "bitshares",
    chain_identifier: null,
    name: "BitShares",
    shortname: "",
    native_coin_id: "bitshares",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "hoo",
    chain_identifier: null,
    name: "Hoo",
    shortname: "Hoo",
    native_coin_id: "hoo-token",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "icon",
    chain_identifier: null,
    name: "ICON",
    shortname: "",
    native_coin_id: "icon",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "nem",
    chain_identifier: null,
    name: "NEM",
    shortname: "",
    native_coin_id: "nem",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "nuls",
    chain_identifier: null,
    name: "Nuls",
    shortname: "",
    native_coin_id: "nuls",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "super-zero",
    chain_identifier: null,
    name: "Sero",
    shortname: "",
    native_coin_id: "super-zero",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "yocoin",
    chain_identifier: null,
    name: "Yocoin",
    shortname: "yocoin",
    native_coin_id: "yocoin",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "internet-computer",
    chain_identifier: null,
    name: "Internet Computer",
    shortname: "",
    native_coin_id: "internet-computer",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/165/thumb/01_ICP_Token_HEX_white__2x.png?1706606785",
      small: "https://coin-images.coingecko.com/asset_platforms/images/165/small/01_ICP_Token_HEX_white__2x.png?1706606785",
      large: "https://coin-images.coingecko.com/asset_platforms/images/165/large/01_ICP_Token_HEX_white__2x.png?1706606785"
    }
  },
  {
    id: "exosama",
    chain_identifier: null,
    name: "Exosama",
    shortname: "",
    native_coin_id: "exosama-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/111/thumb/exosama_%282%29.png?1706606472",
      small: "https://coin-images.coingecko.com/asset_platforms/images/111/small/exosama_%282%29.png?1706606472",
      large: "https://coin-images.coingecko.com/asset_platforms/images/111/large/exosama_%282%29.png?1706606472"
    }
  },
  {
    id: "taiko",
    chain_identifier: 167000,
    name: "Taiko",
    shortname: "taiko",
    native_coin_id: "taiko",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/266/thumb/taiko.jpeg?1716985374",
      small: "https://coin-images.coingecko.com/asset_platforms/images/266/small/taiko.jpeg?1716985374",
      large: "https://coin-images.coingecko.com/asset_platforms/images/266/large/taiko.jpeg?1716985374"
    }
  },
  {
    id: "godwoken",
    chain_identifier: null,
    name: "Godwoken",
    shortname: "",
    native_coin_id: "nervos-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/103/thumb/godwoken.png?1706606634",
      small: "https://coin-images.coingecko.com/asset_platforms/images/103/small/godwoken.png?1706606634",
      large: "https://coin-images.coingecko.com/asset_platforms/images/103/large/godwoken.png?1706606634"
    }
  },
  {
    id: "trustless-computer",
    chain_identifier: null,
    name: "Trustless Computer",
    shortname: "",
    native_coin_id: "bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/132/thumb/trustless.jpeg?1706606636",
      small: "https://coin-images.coingecko.com/asset_platforms/images/132/small/trustless.jpeg?1706606636",
      large: "https://coin-images.coingecko.com/asset_platforms/images/132/large/trustless.jpeg?1706606636"
    }
  },
  {
    id: "neutron",
    chain_identifier: null,
    name: "Neutron",
    shortname: "",
    native_coin_id: "neutron",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/133/thumb/neutron.jpeg?1706606638",
      small: "https://coin-images.coingecko.com/asset_platforms/images/133/small/neutron.jpeg?1706606638",
      large: "https://coin-images.coingecko.com/asset_platforms/images/133/large/neutron.jpeg?1706606638"
    }
  },
  {
    id: "etherlink",
    chain_identifier: 42793,
    name: "Etherlink",
    shortname: "",
    native_coin_id: "tezos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/278/thumb/etherlink.jpg?1719800793",
      small: "https://coin-images.coingecko.com/asset_platforms/images/278/small/etherlink.jpg?1719800793",
      large: "https://coin-images.coingecko.com/asset_platforms/images/278/large/etherlink.jpg?1719800793"
    }
  },
  {
    id: "cybria",
    chain_identifier: 6661,
    name: "Cybria",
    shortname: "",
    native_coin_id: "wrapped-cybria",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/194/thumb/cybria.jpeg?1709690260",
      small: "https://coin-images.coingecko.com/asset_platforms/images/194/small/cybria.jpeg?1709690260",
      large: "https://coin-images.coingecko.com/asset_platforms/images/194/large/cybria.jpeg?1709690260"
    }
  },
  {
    id: "alienx",
    chain_identifier: 10241024,
    name: "AlienX",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/277/thumb/alienx.png?1719305544",
      small: "https://coin-images.coingecko.com/asset_platforms/images/277/small/alienx.png?1719305544",
      large: "https://coin-images.coingecko.com/asset_platforms/images/277/large/alienx.png?1719305544"
    }
  },
  {
    id: "rollux",
    chain_identifier: null,
    name: "Rollux",
    shortname: "",
    native_coin_id: "syscoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/136/thumb/logo_rollux_%281%29.png?1706606481",
      small: "https://coin-images.coingecko.com/asset_platforms/images/136/small/logo_rollux_%281%29.png?1706606481",
      large: "https://coin-images.coingecko.com/asset_platforms/images/136/large/logo_rollux_%281%29.png?1706606481"
    }
  },
  {
    id: "omax",
    chain_identifier: 311,
    name: "OMAX",
    shortname: "",
    native_coin_id: "omax-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/179/thumb/omax.jpeg?1707898533",
      small: "https://coin-images.coingecko.com/asset_platforms/images/179/small/omax.jpeg?1707898533",
      large: "https://coin-images.coingecko.com/asset_platforms/images/179/large/omax.jpeg?1707898533"
    }
  },
  {
    id: "xodex",
    chain_identifier: 2415,
    name: "XODEX",
    shortname: "",
    native_coin_id: "xodex",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/158/thumb/xodex.jpeg?1706606643",
      small: "https://coin-images.coingecko.com/asset_platforms/images/158/small/xodex.jpeg?1706606643",
      large: "https://coin-images.coingecko.com/asset_platforms/images/158/large/xodex.jpeg?1706606643"
    }
  },
  {
    id: "celestia",
    chain_identifier: null,
    name: "Celestia",
    shortname: "",
    native_coin_id: "celestia",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/207/thumb/celestia.png?1711421607",
      small: "https://coin-images.coingecko.com/asset_platforms/images/207/small/celestia.png?1711421607",
      large: "https://coin-images.coingecko.com/asset_platforms/images/207/large/celestia.png?1711421607"
    }
  },
  {
    id: "cardano",
    chain_identifier: null,
    name: "Cardano",
    shortname: "",
    native_coin_id: "cardano",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22/thumb/cardano.jpg?1706606486",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22/small/cardano.jpg?1706606486",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22/large/cardano.jpg?1706606486"
    }
  },
  {
    id: "aura-network",
    chain_identifier: null,
    name: "Aura Network",
    shortname: "",
    native_coin_id: "aura-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/145/thumb/aura_network.jpeg?1706606479",
      small: "https://coin-images.coingecko.com/asset_platforms/images/145/small/aura_network.jpeg?1706606479",
      large: "https://coin-images.coingecko.com/asset_platforms/images/145/large/aura_network.jpeg?1706606479"
    }
  },
  {
    id: "echelon",
    chain_identifier: null,
    name: "Echelon",
    shortname: "",
    native_coin_id: "echelon",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/82/thumb/Echelon.png?1706606497",
      small: "https://coin-images.coingecko.com/asset_platforms/images/82/small/Echelon.png?1706606497",
      large: "https://coin-images.coingecko.com/asset_platforms/images/82/large/Echelon.png?1706606497"
    }
  },
  {
    id: "acala",
    chain_identifier: null,
    name: "Acala",
    shortname: "",
    native_coin_id: "acala",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/73/thumb/Acala.jpeg?1706606499",
      small: "https://coin-images.coingecko.com/asset_platforms/images/73/small/Acala.jpeg?1706606499",
      large: "https://coin-images.coingecko.com/asset_platforms/images/73/large/Acala.jpeg?1706606499"
    }
  },
  {
    id: "everscale",
    chain_identifier: null,
    name: "Everscale",
    shortname: "",
    native_coin_id: "everscale",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/78/thumb/7505.png?1706606508",
      small: "https://coin-images.coingecko.com/asset_platforms/images/78/small/7505.png?1706606508",
      large: "https://coin-images.coingecko.com/asset_platforms/images/78/large/7505.png?1706606508"
    }
  },
  {
    id: "skale",
    chain_identifier: 2046399126,
    name: "Skale",
    shortname: "",
    native_coin_id: "skale",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/96/thumb/skale.png?1706606673",
      small: "https://coin-images.coingecko.com/asset_platforms/images/96/small/skale.png?1706606673",
      large: "https://coin-images.coingecko.com/asset_platforms/images/96/large/skale.png?1706606673"
    }
  },
  {
    id: "the-open-network",
    chain_identifier: null,
    name: "TON",
    shortname: "TON",
    native_coin_id: "the-open-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/142/thumb/tonblockchain.jpeg?1706606805",
      small: "https://coin-images.coingecko.com/asset_platforms/images/142/small/tonblockchain.jpeg?1706606805",
      large: "https://coin-images.coingecko.com/asset_platforms/images/142/large/tonblockchain.jpeg?1706606805"
    }
  },
  {
    id: "telos",
    chain_identifier: 40,
    name: "Telos",
    shortname: "",
    native_coin_id: "telos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/7588/thumb/TLOS_200.png?1706606551",
      small: "https://coin-images.coingecko.com/asset_platforms/images/7588/small/TLOS_200.png?1706606551",
      large: "https://coin-images.coingecko.com/asset_platforms/images/7588/large/TLOS_200.png?1706606551"
    }
  },
  {
    id: "saga",
    chain_identifier: null,
    name: "Saga",
    shortname: "",
    native_coin_id: "saga-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/234/thumb/saga.jpeg?1712891811",
      small: "https://coin-images.coingecko.com/asset_platforms/images/234/small/saga.jpeg?1712891811",
      large: "https://coin-images.coingecko.com/asset_platforms/images/234/large/saga.jpeg?1712891811"
    }
  },
  {
    id: "zenon",
    chain_identifier: null,
    name: "Zenon",
    shortname: "",
    native_coin_id: "zenon-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22181/thumb/zenon.jpg?1728643155",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22181/small/zenon.jpg?1728643155",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22181/large/zenon.jpg?1728643155"
    }
  },
  {
    id: "xai",
    chain_identifier: null,
    name: "XAI",
    shortname: "",
    native_coin_id: "xai-blockchain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/189/thumb/xai.png?1708573604",
      small: "https://coin-images.coingecko.com/asset_platforms/images/189/small/xai.png?1708573604",
      large: "https://coin-images.coingecko.com/asset_platforms/images/189/large/xai.png?1708573604"
    }
  },
  {
    id: "tron",
    chain_identifier: null,
    name: "TRON",
    shortname: "",
    native_coin_id: "tron",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/1094/thumb/TRON_LOGO.png?1706606652",
      small: "https://coin-images.coingecko.com/asset_platforms/images/1094/small/TRON_LOGO.png?1706606652",
      large: "https://coin-images.coingecko.com/asset_platforms/images/1094/large/TRON_LOGO.png?1706606652"
    }
  },
  {
    id: "rootstock",
    chain_identifier: 30,
    name: "Rootstock RSK",
    shortname: "",
    native_coin_id: "rootstock",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/5070/thumb/Rootstock_RSK.jpg?1724842928",
      small: "https://coin-images.coingecko.com/asset_platforms/images/5070/small/Rootstock_RSK.jpg?1724842928",
      large: "https://coin-images.coingecko.com/asset_platforms/images/5070/large/Rootstock_RSK.jpg?1724842928"
    }
  },
  {
    id: "omnia",
    chain_identifier: 2342,
    name: "Omnia",
    shortname: "",
    native_coin_id: "omniaverse",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/263/thumb/OmniaVerse.png?1716209921",
      small: "https://coin-images.coingecko.com/asset_platforms/images/263/small/OmniaVerse.png?1716209921",
      large: "https://coin-images.coingecko.com/asset_platforms/images/263/large/OmniaVerse.png?1716209921"
    }
  },
  {
    id: "algorand",
    chain_identifier: null,
    name: "Algorand",
    shortname: "",
    native_coin_id: "algorand",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/3/thumb/algorand_logo_mark_black.png?1706606710",
      small: "https://coin-images.coingecko.com/asset_platforms/images/3/small/algorand_logo_mark_black.png?1706606710",
      large: "https://coin-images.coingecko.com/asset_platforms/images/3/large/algorand_logo_mark_black.png?1706606710"
    }
  },
  {
    id: "tombchain",
    chain_identifier: null,
    name: "Tombchain",
    shortname: "",
    native_coin_id: "tomb",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/87/thumb/tombchain.jpeg?1706606589",
      small: "https://coin-images.coingecko.com/asset_platforms/images/87/small/tombchain.jpeg?1706606589",
      large: "https://coin-images.coingecko.com/asset_platforms/images/87/large/tombchain.jpeg?1706606589"
    }
  },
  {
    id: "callisto",
    chain_identifier: null,
    name: "Callisto",
    shortname: "",
    native_coin_id: "callisto",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/123/thumb/callisto.jpeg?1706606557",
      small: "https://coin-images.coingecko.com/asset_platforms/images/123/small/callisto.jpeg?1706606557",
      large: "https://coin-images.coingecko.com/asset_platforms/images/123/large/callisto.jpeg?1706606557"
    }
  },
  {
    id: "function-x",
    chain_identifier: 530,
    name: "Function X",
    shortname: "",
    native_coin_id: "fx-coin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/106/thumb/functionx.jpeg?1706606559",
      small: "https://coin-images.coingecko.com/asset_platforms/images/106/small/functionx.jpeg?1706606559",
      large: "https://coin-images.coingecko.com/asset_platforms/images/106/large/functionx.jpeg?1706606559"
    }
  },
  {
    id: "redlight-chain",
    chain_identifier: 2611,
    name: "Redlight Chain",
    shortname: "",
    native_coin_id: "redlight-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/104/thumb/redlight.jpg?1706606562",
      small: "https://coin-images.coingecko.com/asset_platforms/images/104/small/redlight.jpg?1706606562",
      large: "https://coin-images.coingecko.com/asset_platforms/images/104/large/redlight.jpg?1706606562"
    }
  },
  {
    id: "alephium",
    chain_identifier: null,
    name: "Alephium",
    shortname: "",
    native_coin_id: "alephium",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/146/thumb/alephium.jpeg?1706606715",
      small: "https://coin-images.coingecko.com/asset_platforms/images/146/small/alephium.jpeg?1706606715",
      large: "https://coin-images.coingecko.com/asset_platforms/images/146/large/alephium.jpeg?1706606715"
    }
  },
  {
    id: "assetmantle",
    chain_identifier: null,
    name: "AssetMantle",
    shortname: "",
    native_coin_id: "assetmantle",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/210/thumb/assetmantle.jpeg?1711954900",
      small: "https://coin-images.coingecko.com/asset_platforms/images/210/small/assetmantle.jpeg?1711954900",
      large: "https://coin-images.coingecko.com/asset_platforms/images/210/large/assetmantle.jpeg?1711954900"
    }
  },
  {
    id: "kardiachain",
    chain_identifier: 24,
    name: "KardiaChain",
    shortname: "kardiachain",
    native_coin_id: "kardiachain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/31/thumb/5453.png?1706606609",
      small: "https://coin-images.coingecko.com/asset_platforms/images/31/small/5453.png?1706606609",
      large: "https://coin-images.coingecko.com/asset_platforms/images/31/large/5453.png?1706606609"
    }
  },
  {
    id: "venom",
    chain_identifier: null,
    name: "Venom",
    shortname: "",
    native_coin_id: "venom",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/257/thumb/venom.jpeg?1715656420",
      small: "https://coin-images.coingecko.com/asset_platforms/images/257/small/venom.jpeg?1715656420",
      large: "https://coin-images.coingecko.com/asset_platforms/images/257/large/venom.jpeg?1715656420"
    }
  },
  {
    id: "rari",
    chain_identifier: 1380012617,
    name: "Rari",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/286/thumb/rari.png?1722501053",
      small: "https://coin-images.coingecko.com/asset_platforms/images/286/small/rari.png?1722501053",
      large: "https://coin-images.coingecko.com/asset_platforms/images/286/large/rari.png?1722501053"
    }
  },
  {
    id: "neon-evm",
    chain_identifier: 245022934,
    name: "Neon EVM",
    shortname: "",
    native_coin_id: "neon",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/139/thumb/neon.jpeg?1706606787",
      small: "https://coin-images.coingecko.com/asset_platforms/images/139/small/neon.jpeg?1706606787",
      large: "https://coin-images.coingecko.com/asset_platforms/images/139/large/neon.jpeg?1706606787"
    }
  },
  {
    id: "degen",
    chain_identifier: 666666666,
    name: "Degen",
    shortname: "",
    native_coin_id: "degen-base",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/209/thumb/degen.jpeg?1711946732",
      small: "https://coin-images.coingecko.com/asset_platforms/images/209/small/degen.jpeg?1711946732",
      large: "https://coin-images.coingecko.com/asset_platforms/images/209/large/degen.jpeg?1711946732"
    }
  },
  {
    id: "okex-chain",
    chain_identifier: 66,
    name: "OKT Chain",
    shortname: "OKEx",
    native_coin_id: "oec-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/23/thumb/OKT_Chain.jpg?1724842844",
      small: "https://coin-images.coingecko.com/asset_platforms/images/23/small/OKT_Chain.jpg?1724842844",
      large: "https://coin-images.coingecko.com/asset_platforms/images/23/large/OKT_Chain.jpg?1724842844"
    }
  },
  {
    id: "mint",
    chain_identifier: 185,
    name: "Mint",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/293/thumb/mint.jpg?1724895620",
      small: "https://coin-images.coingecko.com/asset_platforms/images/293/small/mint.jpg?1724895620",
      large: "https://coin-images.coingecko.com/asset_platforms/images/293/large/mint.jpg?1724895620"
    }
  },
  {
    id: "apex",
    chain_identifier: null,
    name: "Apex",
    shortname: "",
    native_coin_id: "omniaverse",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/166/thumb/Screenshot_2023-12-20_at_3.31.33%E2%80%AFPM.png?1706606794",
      small: "https://coin-images.coingecko.com/asset_platforms/images/166/small/Screenshot_2023-12-20_at_3.31.33%E2%80%AFPM.png?1706606794",
      large: "https://coin-images.coingecko.com/asset_platforms/images/166/large/Screenshot_2023-12-20_at_3.31.33%E2%80%AFPM.png?1706606794"
    }
  },
  {
    id: "meld",
    chain_identifier: 333000333,
    name: "Meld",
    shortname: "",
    native_coin_id: "meld-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/173/thumb/meld.jpeg?1706606800",
      small: "https://coin-images.coingecko.com/asset_platforms/images/173/small/meld.jpeg?1706606800",
      large: "https://coin-images.coingecko.com/asset_platforms/images/173/large/meld.jpeg?1706606800"
    }
  },
  {
    id: "tezos",
    chain_identifier: null,
    name: "Tezos",
    shortname: "",
    native_coin_id: "tezos",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "harmony-shard-0",
    chain_identifier: 1666600000,
    name: "Harmony Shard 0",
    shortname: "Harmony Shard 0",
    native_coin_id: "harmony",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/20/thumb/harmony.jpg?1724898618",
      small: "https://coin-images.coingecko.com/asset_platforms/images/20/small/harmony.jpg?1724898618",
      large: "https://coin-images.coingecko.com/asset_platforms/images/20/large/harmony.jpg?1724898618"
    }
  },
  {
    id: "waves",
    chain_identifier: null,
    name: "Waves",
    shortname: "",
    native_coin_id: "waves",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/425/thumb/Waves.png?1724898723",
      small: "https://coin-images.coingecko.com/asset_platforms/images/425/small/Waves.png?1724898723",
      large: "https://coin-images.coingecko.com/asset_platforms/images/425/large/Waves.png?1724898723"
    }
  },
  {
    id: "stargaze",
    chain_identifier: null,
    name: "Stargaze",
    shortname: "",
    native_coin_id: "stargaze",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/174/thumb/stargaze.jpeg?1706606807",
      small: "https://coin-images.coingecko.com/asset_platforms/images/174/small/stargaze.jpeg?1706606807",
      large: "https://coin-images.coingecko.com/asset_platforms/images/174/large/stargaze.jpeg?1706606807"
    }
  },
  {
    id: "nxt",
    chain_identifier: null,
    name: "NXT",
    shortname: "",
    native_coin_id: "nxt",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "mdex",
    chain_identifier: null,
    name: "Mdex",
    shortname: "",
    native_coin_id: "mdex",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "bahamut",
    chain_identifier: 5165,
    name: "Bahamut",
    shortname: "",
    native_coin_id: "fasttoken",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/256/thumb/bahamut.jpeg?1715338015",
      small: "https://coin-images.coingecko.com/asset_platforms/images/256/small/bahamut.jpeg?1715338015",
      large: "https://coin-images.coingecko.com/asset_platforms/images/256/large/bahamut.jpeg?1715338015"
    }
  },
  {
    id: "starknet",
    chain_identifier: null,
    name: "StarkNet",
    shortname: "",
    native_coin_id: "starknet",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/151/thumb/starknet.png?1706606812",
      small: "https://coin-images.coingecko.com/asset_platforms/images/151/small/starknet.png?1706606812",
      large: "https://coin-images.coingecko.com/asset_platforms/images/151/large/starknet.png?1706606812"
    }
  },
  {
    id: "humanode",
    chain_identifier: null,
    name: "Humanode",
    shortname: "",
    native_coin_id: "wrapped-ehmnd",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/161/thumb/humanode.png?1706606654",
      small: "https://coin-images.coingecko.com/asset_platforms/images/161/small/humanode.png?1706606654",
      large: "https://coin-images.coingecko.com/asset_platforms/images/161/large/humanode.png?1706606654"
    }
  },
  {
    id: "curio-chain",
    chain_identifier: null,
    name: "Curio Chain",
    shortname: "",
    native_coin_id: "curio-governance",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/180/thumb/curio.jpeg?1707915573",
      small: "https://coin-images.coingecko.com/asset_platforms/images/180/small/curio.jpeg?1707915573",
      large: "https://coin-images.coingecko.com/asset_platforms/images/180/large/curio.jpeg?1707915573"
    }
  },
  {
    id: "kava",
    chain_identifier: 2222,
    name: "Kava",
    shortname: "",
    native_coin_id: "kava",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/2/thumb/kava.jpeg?1707096364",
      small: "https://coin-images.coingecko.com/asset_platforms/images/2/small/kava.jpeg?1707096364",
      large: "https://coin-images.coingecko.com/asset_platforms/images/2/large/kava.jpeg?1707096364"
    }
  },
  {
    id: "zkfair",
    chain_identifier: null,
    name: "ZKFair",
    shortname: "",
    native_coin_id: "zkfair",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/170/thumb/zkfair.png?1706606821",
      small: "https://coin-images.coingecko.com/asset_platforms/images/170/small/zkfair.png?1706606821",
      large: "https://coin-images.coingecko.com/asset_platforms/images/170/large/zkfair.png?1706606821"
    }
  },
  {
    id: "opbnb",
    chain_identifier: 204,
    name: "opBNB",
    shortname: "",
    native_coin_id: "binancecoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/144/thumb/opbnb.png?1706606469",
      small: "https://coin-images.coingecko.com/asset_platforms/images/144/small/opbnb.png?1706606469",
      large: "https://coin-images.coingecko.com/asset_platforms/images/144/large/opbnb.png?1706606469"
    }
  },
  {
    id: "quicksilver",
    chain_identifier: null,
    name: "Quicksilver",
    shortname: "",
    native_coin_id: "quicksilver",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "matrix",
    chain_identifier: null,
    name: "Matrix",
    shortname: "",
    native_coin_id: "matrix-ai-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/90/thumb/cdZZA0rR_400x400.jpeg?1706606671",
      small: "https://coin-images.coingecko.com/asset_platforms/images/90/small/cdZZA0rR_400x400.jpeg?1706606671",
      large: "https://coin-images.coingecko.com/asset_platforms/images/90/large/cdZZA0rR_400x400.jpeg?1706606671"
    }
  },
  {
    id: "ShibChain",
    chain_identifier: null,
    name: "ShibChain",
    shortname: "",
    native_coin_id: "shibchain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/99/thumb/N0fKTQVS_400x400.jpeg?1706606675",
      small: "https://coin-images.coingecko.com/asset_platforms/images/99/small/N0fKTQVS_400x400.jpeg?1706606675",
      large: "https://coin-images.coingecko.com/asset_platforms/images/99/large/N0fKTQVS_400x400.jpeg?1706606675"
    }
  },
  {
    id: "fusion-network",
    chain_identifier: null,
    name: "Fusion Network",
    shortname: "fusion-network",
    native_coin_id: "fsn",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "libre",
    chain_identifier: null,
    name: "Libre",
    shortname: "",
    native_coin_id: "libre",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/117/thumb/libre.jpeg?1706606678",
      small: "https://coin-images.coingecko.com/asset_platforms/images/117/small/libre.jpeg?1706606678",
      large: "https://coin-images.coingecko.com/asset_platforms/images/117/large/libre.jpeg?1706606678"
    }
  },
  {
    id: "gochain",
    chain_identifier: null,
    name: "GoChain",
    shortname: "",
    native_coin_id: "gochain",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "achain",
    chain_identifier: null,
    name: "Achain",
    shortname: "",
    native_coin_id: "achain",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "omni",
    chain_identifier: null,
    name: "Omni",
    shortname: "",
    native_coin_id: "omni",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "enq-enecuum",
    chain_identifier: null,
    name: "Enecuum",
    shortname: "",
    native_coin_id: "enq-enecuum",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "metaverse-etp",
    chain_identifier: null,
    name: "Metaverse ETP",
    shortname: "",
    native_coin_id: "metaverse-etp",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "ardor",
    chain_identifier: null,
    name: "Ardor",
    shortname: "",
    native_coin_id: "ardor",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "stratis",
    chain_identifier: null,
    name: "Stratis",
    shortname: "",
    native_coin_id: "stratis",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "openledger",
    chain_identifier: null,
    name: "OpenLedger",
    shortname: "",
    native_coin_id: "openledger",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "qtum",
    chain_identifier: null,
    name: "Qtum",
    shortname: "",
    native_coin_id: "qtum",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "bitcoin-cash",
    chain_identifier: null,
    name: "Simple Ledger Protocol (Bitcoin Cash)",
    shortname: "SLP",
    native_coin_id: "bitcoin-cash",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "ontology",
    chain_identifier: null,
    name: "Ontology",
    shortname: "",
    native_coin_id: "ontology",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "aleph-zero",
    chain_identifier: null,
    name: "Aleph Zero",
    shortname: "",
    native_coin_id: "aleph-zero",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/171/thumb/aleph.jpeg?1706606823",
      small: "https://coin-images.coingecko.com/asset_platforms/images/171/small/aleph.jpeg?1706606823",
      large: "https://coin-images.coingecko.com/asset_platforms/images/171/large/aleph.jpeg?1706606823"
    }
  },
  {
    id: "drc-20",
    chain_identifier: null,
    name: "DRC-20",
    shortname: "",
    native_coin_id: "dogecoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/169/thumb/drc20.png?1706606826",
      small: "https://coin-images.coingecko.com/asset_platforms/images/169/small/drc20.png?1706606826",
      large: "https://coin-images.coingecko.com/asset_platforms/images/169/large/drc20.png?1706606826"
    }
  },
  {
    id: "berachain",
    chain_identifier: null,
    name: "Berachain",
    shortname: "",
    native_coin_id: "berachain-bera",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/176/thumb/berachain.jpeg?1706606828",
      small: "https://coin-images.coingecko.com/asset_platforms/images/176/small/berachain.jpeg?1706606828",
      large: "https://coin-images.coingecko.com/asset_platforms/images/176/large/berachain.jpeg?1706606828"
    }
  },
  {
    id: "zetachain",
    chain_identifier: 7000,
    name: "ZetaChain",
    shortname: "",
    native_coin_id: "zetachain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/178/thumb/zeta.jpeg?1706844749",
      small: "https://coin-images.coingecko.com/asset_platforms/images/178/small/zeta.jpeg?1706844749",
      large: "https://coin-images.coingecko.com/asset_platforms/images/178/large/zeta.jpeg?1706844749"
    }
  },
  {
    id: "shiden network",
    chain_identifier: 336,
    name: "Shiden Network",
    shortname: "",
    native_coin_id: "shiden",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/65/thumb/tFOtjrr3_400x400.jpeg?1706606564",
      small: "https://coin-images.coingecko.com/asset_platforms/images/65/small/tFOtjrr3_400x400.jpeg?1706606564",
      large: "https://coin-images.coingecko.com/asset_platforms/images/65/large/tFOtjrr3_400x400.jpeg?1706606564"
    }
  },
  {
    id: "syscoin",
    chain_identifier: 57,
    name: "Syscoin NEVM",
    shortname: "syscoin",
    native_coin_id: "syscoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/63/thumb/xDiIFaKO_400x400.jpg?1706606573",
      small: "https://coin-images.coingecko.com/asset_platforms/images/63/small/xDiIFaKO_400x400.jpg?1706606573",
      large: "https://coin-images.coingecko.com/asset_platforms/images/63/large/xDiIFaKO_400x400.jpg?1706606573"
    }
  },
  {
    id: "casper-network",
    chain_identifier: null,
    name: "Casper Network",
    shortname: "",
    native_coin_id: "casper-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/181/thumb/casper.png?1707983474",
      small: "https://coin-images.coingecko.com/asset_platforms/images/181/small/casper.png?1707983474",
      large: "https://coin-images.coingecko.com/asset_platforms/images/181/large/casper.png?1707983474"
    }
  },
  {
    id: "xpla",
    chain_identifier: null,
    name: "XPLA",
    shortname: "",
    native_coin_id: "xpla",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/175/thumb/xpla.jpeg?1707990587",
      small: "https://coin-images.coingecko.com/asset_platforms/images/175/small/xpla.jpeg?1707990587",
      large: "https://coin-images.coingecko.com/asset_platforms/images/175/large/xpla.jpeg?1707990587"
    }
  },
  {
    id: "karura",
    chain_identifier: null,
    name: "Karura",
    shortname: "",
    native_coin_id: "karura",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/79/thumb/karura.jpeg?1706606510",
      small: "https://coin-images.coingecko.com/asset_platforms/images/79/small/karura.jpeg?1706606510",
      large: "https://coin-images.coingecko.com/asset_platforms/images/79/large/karura.jpeg?1706606510"
    }
  },
  {
    id: "rails-network",
    chain_identifier: null,
    name: "Rails Network",
    shortname: "",
    native_coin_id: "steam-exchange",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/183/thumb/railsnetwork.jpeg?1708321107",
      small: "https://coin-images.coingecko.com/asset_platforms/images/183/small/railsnetwork.jpeg?1708321107",
      large: "https://coin-images.coingecko.com/asset_platforms/images/183/large/railsnetwork.jpeg?1708321107"
    }
  },
  {
    id: "chromia",
    chain_identifier: null,
    name: "Chromia",
    shortname: "",
    native_coin_id: "chromaway",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "sx-network",
    chain_identifier: null,
    name: "SX Network",
    shortname: "sxn",
    native_coin_id: "sx-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/80/thumb/yIxICJL7_400x400.jpeg?1706606514",
      small: "https://coin-images.coingecko.com/asset_platforms/images/80/small/yIxICJL7_400x400.jpeg?1706606514",
      large: "https://coin-images.coingecko.com/asset_platforms/images/80/large/yIxICJL7_400x400.jpeg?1706606514"
    }
  },
  {
    id: "cube",
    chain_identifier: null,
    name: "Cube",
    shortname: "",
    native_coin_id: "cube-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/81/thumb/cube.png?1706606516",
      small: "https://coin-images.coingecko.com/asset_platforms/images/81/small/cube.png?1706606516",
      large: "https://coin-images.coingecko.com/asset_platforms/images/81/large/cube.png?1706606516"
    }
  },
  {
    id: "bifrost-network",
    chain_identifier: null,
    name: "Bifrost Network",
    shortname: "",
    native_coin_id: "bifrost",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/240/thumb/bifrost.png?1713513431",
      small: "https://coin-images.coingecko.com/asset_platforms/images/240/small/bifrost.png?1713513431",
      large: "https://coin-images.coingecko.com/asset_platforms/images/240/large/bifrost.png?1713513431"
    }
  },
  {
    id: "metis-andromeda",
    chain_identifier: 1088,
    name: "Metis Andromeda",
    shortname: "",
    native_coin_id: "metis-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/54/thumb/metis.png?1706606712",
      small: "https://coin-images.coingecko.com/asset_platforms/images/54/small/metis.png?1706606712",
      large: "https://coin-images.coingecko.com/asset_platforms/images/54/large/metis.png?1706606712"
    }
  },
  {
    id: "neo",
    chain_identifier: null,
    name: "NEO",
    shortname: "",
    native_coin_id: "neo",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/480/thumb/NEO.jpg?1724898791",
      small: "https://coin-images.coingecko.com/asset_platforms/images/480/small/NEO.jpg?1724898791",
      large: "https://coin-images.coingecko.com/asset_platforms/images/480/large/NEO.jpg?1724898791"
    }
  },
  {
    id: "filecoin",
    chain_identifier: 314,
    name: "Filecoin",
    shortname: "",
    native_coin_id: "filecoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/197/thumb/filecoin.jpeg?1710122717",
      small: "https://coin-images.coingecko.com/asset_platforms/images/197/small/filecoin.jpeg?1710122717",
      large: "https://coin-images.coingecko.com/asset_platforms/images/197/large/filecoin.jpeg?1710122717"
    }
  },
  {
    id: "cellframe",
    chain_identifier: null,
    name: "Cellframe",
    shortname: "",
    native_coin_id: "cellframe",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/250/thumb/cellframe.jpeg?1714641609",
      small: "https://coin-images.coingecko.com/asset_platforms/images/250/small/cellframe.jpeg?1714641609",
      large: "https://coin-images.coingecko.com/asset_platforms/images/250/large/cellframe.jpeg?1714641609"
    }
  },
  {
    id: "zilliqa",
    chain_identifier: null,
    name: "Zilliqa",
    shortname: "",
    native_coin_id: "zilliqa",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "defi-kingdoms-blockchain",
    chain_identifier: 53935,
    name: "DFK Chain",
    shortname: "DFK Chain",
    native_coin_id: "defi-kingdoms",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/70/thumb/dfk-chain-logo-darkBlue.png?1706606503",
      small: "https://coin-images.coingecko.com/asset_platforms/images/70/small/dfk-chain-logo-darkBlue.png?1706606503",
      large: "https://coin-images.coingecko.com/asset_platforms/images/70/large/dfk-chain-logo-darkBlue.png?1706606503"
    }
  },
  {
    id: "cosmos",
    chain_identifier: null,
    name: "Cosmos",
    shortname: "",
    native_coin_id: "cosmos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/1481/thumb/cosmos.png?1706606771",
      small: "https://coin-images.coingecko.com/asset_platforms/images/1481/small/cosmos.png?1706606771",
      large: "https://coin-images.coingecko.com/asset_platforms/images/1481/large/cosmos.png?1706606771"
    }
  },
  {
    id: "hypra-network",
    chain_identifier: 622277,
    name: "Hypra Network",
    shortname: "",
    native_coin_id: "hypra",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/195/thumb/hypra.jpeg?1709713617",
      small: "https://coin-images.coingecko.com/asset_platforms/images/195/small/hypra.jpeg?1709713617",
      large: "https://coin-images.coingecko.com/asset_platforms/images/195/large/hypra.jpeg?1709713617"
    }
  },
  {
    id: "thorchain",
    chain_identifier: null,
    name: "Thorchain",
    shortname: "",
    native_coin_id: "thorchain",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "sge",
    chain_identifier: null,
    name: "SGE",
    shortname: "",
    native_coin_id: "six-sigma",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/185/thumb/sge.png?1708329472",
      small: "https://coin-images.coingecko.com/asset_platforms/images/185/small/sge.png?1708329472",
      large: "https://coin-images.coingecko.com/asset_platforms/images/185/large/sge.png?1708329472"
    }
  },
  {
    id: "alverychain",
    chain_identifier: 3797,
    name: "Alveychain",
    shortname: "",
    native_coin_id: "alvey-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/309/thumb/alvey.jpeg?1724918370",
      small: "https://coin-images.coingecko.com/asset_platforms/images/309/small/alvey.jpeg?1724918370",
      large: "https://coin-images.coingecko.com/asset_platforms/images/309/large/alvey.jpeg?1724918370"
    }
  },
  {
    id: "binancecoin",
    chain_identifier: null,
    name: "BNB Beacon Chain",
    shortname: "BEP2",
    native_coin_id: "binancecoin",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "stellar",
    chain_identifier: null,
    name: "Stellar",
    shortname: "",
    native_coin_id: "stellar",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/100/thumb/stellar.jpeg?1708492494",
      small: "https://coin-images.coingecko.com/asset_platforms/images/100/small/stellar.jpeg?1708492494",
      large: "https://coin-images.coingecko.com/asset_platforms/images/100/large/stellar.jpeg?1708492494"
    }
  },
  {
    id: "theta",
    chain_identifier: 361,
    name: "Theta",
    shortname: "",
    native_coin_id: "theta-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/59/thumb/jxOQobOB_400x400.jpeg?1706606764",
      small: "https://coin-images.coingecko.com/asset_platforms/images/59/small/jxOQobOB_400x400.jpeg?1706606764",
      large: "https://coin-images.coingecko.com/asset_platforms/images/59/large/jxOQobOB_400x400.jpeg?1706606764"
    }
  },
  {
    id: "xdai",
    chain_identifier: 100,
    name: "Gnosis Chain",
    shortname: "",
    native_coin_id: "xdai",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/11062/thumb/Aatar_green_white.png?1706606458",
      small: "https://coin-images.coingecko.com/asset_platforms/images/11062/small/Aatar_green_white.png?1706606458",
      large: "https://coin-images.coingecko.com/asset_platforms/images/11062/large/Aatar_green_white.png?1706606458"
    }
  },
  {
    id: "re-al",
    chain_identifier: 111188,
    name: "Re.al",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/264/thumb/re.al.jpeg?1717754295",
      small: "https://coin-images.coingecko.com/asset_platforms/images/264/small/re.al.jpeg?1717754295",
      large: "https://coin-images.coingecko.com/asset_platforms/images/264/large/re.al.jpeg?1717754295"
    }
  },
  {
    id: "kasplex",
    chain_identifier: null,
    name: "Kaspa",
    shortname: "",
    native_coin_id: "kaspa",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22172/thumb/kasplex-2.png?1727064572",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22172/small/kasplex-2.png?1727064572",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22172/large/kasplex-2.png?1727064572"
    }
  },
  {
    id: "osmosis",
    chain_identifier: null,
    name: "Osmosis",
    shortname: "Osmo",
    native_coin_id: "osmosis",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/25/thumb/osmosis-osmo-logo.png?1708335599",
      small: "https://coin-images.coingecko.com/asset_platforms/images/25/small/osmosis-osmo-logo.png?1708335599",
      large: "https://coin-images.coingecko.com/asset_platforms/images/25/large/osmosis-osmo-logo.png?1708335599"
    }
  },
  {
    id: "ancient8",
    chain_identifier: 888888888,
    name: "Ancient8",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/259/thumb/ancient8.jpeg?1715926598",
      small: "https://coin-images.coingecko.com/asset_platforms/images/259/small/ancient8.jpeg?1715926598",
      large: "https://coin-images.coingecko.com/asset_platforms/images/259/large/ancient8.jpeg?1715926598"
    }
  },
  {
    id: "akash",
    chain_identifier: null,
    name: "Akash",
    shortname: "",
    native_coin_id: "akash-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/201/thumb/akash.jpeg?1711344327",
      small: "https://coin-images.coingecko.com/asset_platforms/images/201/small/akash.jpeg?1711344327",
      large: "https://coin-images.coingecko.com/asset_platforms/images/201/large/akash.jpeg?1711344327"
    }
  },
  {
    id: "gravity-alpha",
    chain_identifier: 1625,
    name: "Gravity Alpha",
    shortname: "gravity-alpha",
    native_coin_id: "g-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/290/thumb/Gravity_Logo_Icon_Black.png?1724722766",
      small: "https://coin-images.coingecko.com/asset_platforms/images/290/small/Gravity_Logo_Icon_Black.png?1724722766",
      large: "https://coin-images.coingecko.com/asset_platforms/images/290/large/Gravity_Logo_Icon_Black.png?1724722766"
    }
  },
  {
    id: "velas",
    chain_identifier: 106,
    name: "Velas",
    shortname: "velas",
    native_coin_id: "velas",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/62/thumb/velas.jpg?1706606575",
      small: "https://coin-images.coingecko.com/asset_platforms/images/62/small/velas.jpg?1706606575",
      large: "https://coin-images.coingecko.com/asset_platforms/images/62/large/velas.jpg?1706606575"
    }
  },
  {
    id: "noble",
    chain_identifier: null,
    name: "Noble",
    shortname: "",
    native_coin_id: "cosmos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/190/thumb/noble.png?1708584370",
      small: "https://coin-images.coingecko.com/asset_platforms/images/190/small/noble.png?1708584370",
      large: "https://coin-images.coingecko.com/asset_platforms/images/190/large/noble.png?1708584370"
    }
  },
  {
    id: "near-protocol",
    chain_identifier: null,
    name: "Near Protocol",
    shortname: "near-protocol",
    native_coin_id: "near",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/34/thumb/near.jpeg?1706870535",
      small: "https://coin-images.coingecko.com/asset_platforms/images/34/small/near.jpeg?1706870535",
      large: "https://coin-images.coingecko.com/asset_platforms/images/34/large/near.jpeg?1706870535"
    }
  },
  {
    id: "manta-pacific",
    chain_identifier: 169,
    name: "Manta Pacific",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/150/thumb/manta.png?1706606605",
      small: "https://coin-images.coingecko.com/asset_platforms/images/150/small/manta.png?1706606605",
      large: "https://coin-images.coingecko.com/asset_platforms/images/150/large/manta.png?1706606605"
    }
  },
  {
    id: "wemix-network",
    chain_identifier: 1111,
    name: "Wemix Network",
    shortname: "",
    native_coin_id: "wemix-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/113/thumb/wemix.png?1706606488",
      small: "https://coin-images.coingecko.com/asset_platforms/images/113/small/wemix.png?1706606488",
      large: "https://coin-images.coingecko.com/asset_platforms/images/113/large/wemix.png?1706606488"
    }
  },
  {
    id: "immutable",
    chain_identifier: 13371,
    name: "Immutable zkEVM",
    shortname: "",
    native_coin_id: "immutable-x",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/159/thumb/immutable.jpeg?1706606759",
      small: "https://coin-images.coingecko.com/asset_platforms/images/159/small/immutable.jpeg?1706606759",
      large: "https://coin-images.coingecko.com/asset_platforms/images/159/large/immutable.jpeg?1706606759"
    }
  },
  {
    id: "map-protocol",
    chain_identifier: 22776,
    name: "Map Protocol",
    shortname: "",
    native_coin_id: "marcopolo",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/208/thumb/map_protocol.jpeg?1711516308",
      small: "https://coin-images.coingecko.com/asset_platforms/images/208/small/map_protocol.jpeg?1711516308",
      large: "https://coin-images.coingecko.com/asset_platforms/images/208/large/map_protocol.jpeg?1711516308"
    }
  },
  {
    id: "coinex-smart-chain",
    chain_identifier: 52,
    name: "CoinEx Smart Chain",
    shortname: "CSC",
    native_coin_id: "coinex-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/77/thumb/5IeSYSyn_400x400.jpeg?1706606528",
      small: "https://coin-images.coingecko.com/asset_platforms/images/77/small/5IeSYSyn_400x400.jpeg?1706606528",
      large: "https://coin-images.coingecko.com/asset_platforms/images/77/large/5IeSYSyn_400x400.jpeg?1706606528"
    }
  },
  {
    id: "zora-network",
    chain_identifier: 7777777,
    name: "Zora",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/196/thumb/zora.jpeg?1709871117",
      small: "https://coin-images.coingecko.com/asset_platforms/images/196/small/zora.jpeg?1709871117",
      large: "https://coin-images.coingecko.com/asset_platforms/images/196/large/zora.jpeg?1709871117"
    }
  },
  {
    id: "merlin-chain",
    chain_identifier: 4200,
    name: "Merlin Chain",
    shortname: "",
    native_coin_id: "wrapped-bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/188/thumb/merlin-chain.jpeg?1708522313",
      small: "https://coin-images.coingecko.com/asset_platforms/images/188/small/merlin-chain.jpeg?1708522313",
      large: "https://coin-images.coingecko.com/asset_platforms/images/188/large/merlin-chain.jpeg?1708522313"
    }
  },
  {
    id: "injective",
    chain_identifier: null,
    name: "Injective",
    shortname: "",
    native_coin_id: "injective-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/134/thumb/injective.jpeg?1706606600",
      small: "https://coin-images.coingecko.com/asset_platforms/images/134/small/injective.jpeg?1706606600",
      large: "https://coin-images.coingecko.com/asset_platforms/images/134/large/injective.jpeg?1706606600"
    }
  },
  {
    id: "binance-smart-chain",
    chain_identifier: 56,
    name: "BNB Smart Chain",
    shortname: "BSC",
    native_coin_id: "binancecoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/1/thumb/bnb_smart_chain.png?1706606721",
      small: "https://coin-images.coingecko.com/asset_platforms/images/1/small/bnb_smart_chain.png?1706606721",
      large: "https://coin-images.coingecko.com/asset_platforms/images/1/large/bnb_smart_chain.png?1706606721"
    }
  },
  {
    id: "defichain-evm",
    chain_identifier: 1130,
    name: "DeFiChain EVM",
    shortname: "",
    native_coin_id: "defichain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/308/thumb/defichain.jpg?1724990979",
      small: "https://coin-images.coingecko.com/asset_platforms/images/308/small/defichain.jpg?1724990979",
      large: "https://coin-images.coingecko.com/asset_platforms/images/308/large/defichain.jpg?1724990979"
    }
  },
  {
    id: "orenium",
    chain_identifier: null,
    name: "Orenium",
    shortname: "",
    native_coin_id: "orenium-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/233/thumb/orenium.png?1712550641",
      small: "https://coin-images.coingecko.com/asset_platforms/images/233/small/orenium.png?1712550641",
      large: "https://coin-images.coingecko.com/asset_platforms/images/233/large/orenium.png?1712550641"
    }
  },
  {
    id: "beamprivacy",
    chain_identifier: null,
    name: "BeamPrivacy",
    shortname: "",
    native_coin_id: "beam",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/186/thumb/beamprivacy.jpeg?1708333110",
      small: "https://coin-images.coingecko.com/asset_platforms/images/186/small/beamprivacy.jpeg?1708333110",
      large: "https://coin-images.coingecko.com/asset_platforms/images/186/large/beamprivacy.jpeg?1708333110"
    }
  },
  {
    id: "saita-chain",
    chain_identifier: 1209,
    name: "Saita Chain",
    shortname: "",
    native_coin_id: "saitachain-coin-2",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "solana",
    chain_identifier: null,
    name: "Solana",
    shortname: "Solana",
    native_coin_id: "solana",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/5/thumb/solana.png?1706606708",
      small: "https://coin-images.coingecko.com/asset_platforms/images/5/small/solana.png?1706606708",
      large: "https://coin-images.coingecko.com/asset_platforms/images/5/large/solana.png?1706606708"
    }
  },
  {
    id: "cyber",
    chain_identifier: 7560,
    name: "Cyber",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/281/thumb/cyber.jpg?1720164649",
      small: "https://coin-images.coingecko.com/asset_platforms/images/281/small/cyber.jpg?1720164649",
      large: "https://coin-images.coingecko.com/asset_platforms/images/281/large/cyber.jpg?1720164649"
    }
  },
  {
    id: "oasis",
    chain_identifier: 42262,
    name: "Oasis Emerald",
    shortname: "oasis",
    native_coin_id: "oasis-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/58/thumb/Oasis_Emerald.jpg?1724842810",
      small: "https://coin-images.coingecko.com/asset_platforms/images/58/small/Oasis_Emerald.jpg?1724842810",
      large: "https://coin-images.coingecko.com/asset_platforms/images/58/large/Oasis_Emerald.jpg?1724842810"
    }
  },
  {
    id: "ergo",
    chain_identifier: null,
    name: "Ergo",
    shortname: "",
    native_coin_id: "ergo",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/157/thumb/ergo.jpeg?1706606535",
      small: "https://coin-images.coingecko.com/asset_platforms/images/157/small/ergo.jpeg?1706606535",
      large: "https://coin-images.coingecko.com/asset_platforms/images/157/large/ergo.jpeg?1706606535"
    }
  },
  {
    id: "turan-network",
    chain_identifier: null,
    name: "TTcoin Smart Chain",
    shortname: "",
    native_coin_id: "turan-network",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "hoo-smart-chain",
    chain_identifier: 70,
    name: "Hoo Smart Chain",
    shortname: "",
    native_coin_id: "hoo-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/60/thumb/hooo.jpg?1706606539",
      small: "https://coin-images.coingecko.com/asset_platforms/images/60/small/hooo.jpg?1706606539",
      large: "https://coin-images.coingecko.com/asset_platforms/images/60/large/hooo.jpg?1706606539"
    }
  },
  {
    id: "onchain",
    chain_identifier: 27563,
    name: "Onchain",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/311/thumb/bpmi3su1uwpri22dtmlqhnwgr3ip.png?1725610791",
      small: "https://coin-images.coingecko.com/asset_platforms/images/311/small/bpmi3su1uwpri22dtmlqhnwgr3ip.png?1725610791",
      large: "https://coin-images.coingecko.com/asset_platforms/images/311/large/bpmi3su1uwpri22dtmlqhnwgr3ip.png?1725610791"
    }
  },
  {
    id: "ordinals",
    chain_identifier: null,
    name: "Bitcoin",
    shortname: "Ordinals",
    native_coin_id: "bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/127/thumb/ordinals.png?1706606816",
      small: "https://coin-images.coingecko.com/asset_platforms/images/127/small/ordinals.png?1706606816",
      large: "https://coin-images.coingecko.com/asset_platforms/images/127/large/ordinals.png?1706606816"
    }
  },
  {
    id: "juno",
    chain_identifier: null,
    name: "Juno",
    shortname: "",
    native_coin_id: "juno-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/164/thumb/juno.png?1706606409",
      small: "https://coin-images.coingecko.com/asset_platforms/images/164/small/juno.png?1706606409",
      large: "https://coin-images.coingecko.com/asset_platforms/images/164/large/juno.png?1706606409"
    }
  },
  {
    id: "elrond",
    chain_identifier: null,
    name: "MultiversX",
    shortname: "elrond",
    native_coin_id: "elrond-erd-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/50/thumb/multiverse.jpeg?1711356002",
      small: "https://coin-images.coingecko.com/asset_platforms/images/50/small/multiverse.jpeg?1711356002",
      large: "https://coin-images.coingecko.com/asset_platforms/images/50/large/multiverse.jpeg?1711356002"
    }
  },
  {
    id: "Bitcichain",
    chain_identifier: 1907,
    name: "Bitcichain",
    shortname: "Bitcichain",
    native_coin_id: "bitcicoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/29/thumb/bitcichain.png?1706606405",
      small: "https://coin-images.coingecko.com/asset_platforms/images/29/small/bitcichain.png?1706606405",
      large: "https://coin-images.coingecko.com/asset_platforms/images/29/large/bitcichain.png?1706606405"
    }
  },
  {
    id: "beam",
    chain_identifier: 4337,
    name: "Beam",
    shortname: "",
    native_coin_id: "beam",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/155/thumb/beam.png?1706606625",
      small: "https://coin-images.coingecko.com/asset_platforms/images/155/small/beam.png?1706606625",
      large: "https://coin-images.coingecko.com/asset_platforms/images/155/large/beam.png?1706606625"
    }
  },
  {
    id: "bitcanna",
    chain_identifier: null,
    name: "BitCanna",
    shortname: "",
    native_coin_id: "bitcanna",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/202/thumb/bitcanna.png?1711345819",
      small: "https://coin-images.coingecko.com/asset_platforms/images/202/small/bitcanna.png?1711345819",
      large: "https://coin-images.coingecko.com/asset_platforms/images/202/large/bitcanna.png?1711345819"
    }
  },
  {
    id: "crescent",
    chain_identifier: null,
    name: "Crescent",
    shortname: "",
    native_coin_id: "crescent-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/206/thumb/crescent.jpeg?1711349933",
      small: "https://coin-images.coingecko.com/asset_platforms/images/206/small/crescent.jpeg?1711349933",
      large: "https://coin-images.coingecko.com/asset_platforms/images/206/large/crescent.jpeg?1711349933"
    }
  },
  {
    id: "mantle",
    chain_identifier: 5000,
    name: "Mantle",
    shortname: "",
    native_coin_id: "mantle",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/140/thumb/mantle.jpeg?1706606701",
      small: "https://coin-images.coingecko.com/asset_platforms/images/140/small/mantle.jpeg?1706606701",
      large: "https://coin-images.coingecko.com/asset_platforms/images/140/large/mantle.jpeg?1706606701"
    }
  },
  {
    id: "core",
    chain_identifier: 1116,
    name: "Core",
    shortname: "",
    native_coin_id: "coredaoorg",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/119/thumb/core.jpeg?1706606603",
      small: "https://coin-images.coingecko.com/asset_platforms/images/119/small/core.jpeg?1706606603",
      large: "https://coin-images.coingecko.com/asset_platforms/images/119/large/core.jpeg?1706606603"
    }
  },
  {
    id: "bouncebit",
    chain_identifier: 6001,
    name: "BounceBit",
    shortname: "",
    native_coin_id: "bouncebit",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/260/thumb/bouncebit.jpeg?1715927323",
      small: "https://coin-images.coingecko.com/asset_platforms/images/260/small/bouncebit.jpeg?1715927323",
      large: "https://coin-images.coingecko.com/asset_platforms/images/260/large/bouncebit.jpeg?1715927323"
    }
  },
  {
    id: "polis-chain",
    chain_identifier: 333999,
    name: "Polis Chain",
    shortname: "",
    native_coin_id: "polis",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/64/thumb/Polischain.jpeg?1706606424",
      small: "https://coin-images.coingecko.com/asset_platforms/images/64/small/Polischain.jpeg?1706606424",
      large: "https://coin-images.coingecko.com/asset_platforms/images/64/large/Polischain.jpeg?1706606424"
    }
  },
  {
    id: "q-mainnet",
    chain_identifier: 35441,
    name: "Q Mainnet",
    shortname: "",
    native_coin_id: "q-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/289/thumb/q_protocol.png?1724400233",
      small: "https://coin-images.coingecko.com/asset_platforms/images/289/small/q_protocol.png?1724400233",
      large: "https://coin-images.coingecko.com/asset_platforms/images/289/large/q_protocol.png?1724400233"
    }
  },
  {
    id: "mixin-network",
    chain_identifier: null,
    name: "Mixin Network",
    shortname: "",
    native_coin_id: "mixin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/75/thumb/FMsal8MK_400x400.jpg?1706606449",
      small: "https://coin-images.coingecko.com/asset_platforms/images/75/small/FMsal8MK_400x400.jpg?1706606449",
      large: "https://coin-images.coingecko.com/asset_platforms/images/75/large/FMsal8MK_400x400.jpg?1706606449"
    }
  },
  {
    id: "defichain",
    chain_identifier: null,
    name: "DeFiChain",
    shortname: "",
    native_coin_id: "defichain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/66/thumb/defichain.jpg?1706606455",
      small: "https://coin-images.coingecko.com/asset_platforms/images/66/small/defichain.jpg?1706606455",
      large: "https://coin-images.coingecko.com/asset_platforms/images/66/large/defichain.jpg?1706606455"
    }
  },
  {
    id: "ronin",
    chain_identifier: 2020,
    name: "Ronin",
    shortname: "ron",
    native_coin_id: "ronin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/45/thumb/ronin.png?1706606769",
      small: "https://coin-images.coingecko.com/asset_platforms/images/45/small/ronin.png?1706606769",
      large: "https://coin-images.coingecko.com/asset_platforms/images/45/large/ronin.png?1706606769"
    }
  },
  {
    id: "furya",
    chain_identifier: null,
    name: "Furya",
    shortname: "",
    native_coin_id: "fanfury",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/168/thumb/Furya.png?1706606465",
      small: "https://coin-images.coingecko.com/asset_platforms/images/168/small/Furya.png?1706606465",
      large: "https://coin-images.coingecko.com/asset_platforms/images/168/large/Furya.png?1706606465"
    }
  },
  {
    id: "lightlink",
    chain_identifier: 1890,
    name: "LightLink",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/147/thumb/lightlink.jpeg?1706606467",
      small: "https://coin-images.coingecko.com/asset_platforms/images/147/small/lightlink.jpeg?1706606467",
      large: "https://coin-images.coingecko.com/asset_platforms/images/147/large/lightlink.jpeg?1706606467"
    }
  },
  {
    id: "planq-network",
    chain_identifier: 7070,
    name: "Planq Network",
    shortname: "",
    native_coin_id: "planq",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/275/thumb/planq.jpeg?1717991052",
      small: "https://coin-images.coingecko.com/asset_platforms/images/275/small/planq.jpeg?1717991052",
      large: "https://coin-images.coingecko.com/asset_platforms/images/275/large/planq.jpeg?1717991052"
    }
  },
  {
    id: "eos-evm",
    chain_identifier: 17777,
    name: "EOS EVM",
    shortname: "",
    native_coin_id: "eos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/124/thumb/QaCYVHgJ_400x400.jpg?1706606618",
      small: "https://coin-images.coingecko.com/asset_platforms/images/124/small/QaCYVHgJ_400x400.jpg?1706606618",
      large: "https://coin-images.coingecko.com/asset_platforms/images/124/large/QaCYVHgJ_400x400.jpg?1706606618"
    }
  },
  {
    id: "ultron",
    chain_identifier: 1231,
    name: "Ultron",
    shortname: "",
    native_coin_id: "ultron",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/125/thumb/ultron_edit.png?1706606620",
      small: "https://coin-images.coingecko.com/asset_platforms/images/125/small/ultron_edit.png?1706606620",
      large: "https://coin-images.coingecko.com/asset_platforms/images/125/large/ultron_edit.png?1706606620"
    }
  },
  {
    id: "tenet",
    chain_identifier: 1559,
    name: "Tenet",
    shortname: "",
    native_coin_id: "tenet-1b000f7b-59cb-4e06-89ce-d62b32d362b9",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/137/thumb/tenet.jpeg?1706606623",
      small: "https://coin-images.coingecko.com/asset_platforms/images/137/small/tenet.jpeg?1706606623",
      large: "https://coin-images.coingecko.com/asset_platforms/images/137/large/tenet.jpeg?1706606623"
    }
  },
  {
    id: "comdex",
    chain_identifier: null,
    name: "COMDEX",
    shortname: "",
    native_coin_id: "comdex",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/203/thumb/comdex.png?1711347449",
      small: "https://coin-images.coingecko.com/asset_platforms/images/203/small/comdex.png?1711347449",
      large: "https://coin-images.coingecko.com/asset_platforms/images/203/large/comdex.png?1711347449"
    }
  },
  {
    id: "rss3-vsl",
    chain_identifier: null,
    name: " RSS3 VSL",
    shortname: "",
    native_coin_id: "rss3",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/235/thumb/rss3.jpeg?1713000882",
      small: "https://coin-images.coingecko.com/asset_platforms/images/235/small/rss3.jpeg?1713000882",
      large: "https://coin-images.coingecko.com/asset_platforms/images/235/large/rss3.jpeg?1713000882"
    }
  },
  {
    id: "sui",
    chain_identifier: null,
    name: "Sui",
    shortname: "",
    native_coin_id: "sui",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/126/thumb/sui-ocean-square.png?1727791325",
      small: "https://coin-images.coingecko.com/asset_platforms/images/126/small/sui-ocean-square.png?1727791325",
      large: "https://coin-images.coingecko.com/asset_platforms/images/126/large/sui-ocean-square.png?1727791325"
    }
  },
  {
    id: "canto",
    chain_identifier: 7700,
    name: "Canto",
    shortname: "",
    native_coin_id: "canto",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/92/thumb/canto-network.png?1706606584",
      small: "https://coin-images.coingecko.com/asset_platforms/images/92/small/canto-network.png?1706606584",
      large: "https://coin-images.coingecko.com/asset_platforms/images/92/large/canto-network.png?1706606584"
    }
  },
  {
    id: "celer-network",
    chain_identifier: null,
    name: "Celer Network",
    shortname: "Celer",
    native_coin_id: "celer-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/68/thumb/celer.jpeg?1706606730",
      small: "https://coin-images.coingecko.com/asset_platforms/images/68/small/celer.jpeg?1706606730",
      large: "https://coin-images.coingecko.com/asset_platforms/images/68/large/celer.jpeg?1706606730"
    }
  },
  {
    id: "loopring",
    chain_identifier: null,
    name: "Loopring",
    shortname: "",
    native_coin_id: "loopring",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/167/thumb/loopring.png?1706606734",
      small: "https://coin-images.coingecko.com/asset_platforms/images/167/small/loopring.png?1706606734",
      large: "https://coin-images.coingecko.com/asset_platforms/images/167/large/loopring.png?1706606734"
    }
  },
  {
    id: "chiliz",
    chain_identifier: null,
    name: "Chiliz",
    shortname: "",
    native_coin_id: "chiliz",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/8834/thumb/chiliz.png?1710723850",
      small: "https://coin-images.coingecko.com/asset_platforms/images/8834/small/chiliz.png?1710723850",
      large: "https://coin-images.coingecko.com/asset_platforms/images/8834/large/chiliz.png?1710723850"
    }
  },
  {
    id: "flare-network",
    chain_identifier: 14,
    name: "Flare Network",
    shortname: "",
    native_coin_id: "flare-networks",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/114/thumb/Flare.jpeg?1706606598",
      small: "https://coin-images.coingecko.com/asset_platforms/images/114/small/Flare.jpeg?1706606598",
      large: "https://coin-images.coingecko.com/asset_platforms/images/114/large/Flare.jpeg?1706606598"
    }
  },
  {
    id: "clover",
    chain_identifier: null,
    name: "Clover",
    shortname: "",
    native_coin_id: "clover-finance",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/128/thumb/clv.jpeg?1706606743",
      small: "https://coin-images.coingecko.com/asset_platforms/images/128/small/clv.jpeg?1706606743",
      large: "https://coin-images.coingecko.com/asset_platforms/images/128/large/clv.jpeg?1706606743"
    }
  },
  {
    id: "bifrost-kusama",
    chain_identifier: null,
    name: "Bifrost Kusama",
    shortname: "",
    native_coin_id: "bifrost-native-coin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/141/thumb/bnc.png?1706606748",
      small: "https://coin-images.coingecko.com/asset_platforms/images/141/small/bnc.png?1706606748",
      large: "https://coin-images.coingecko.com/asset_platforms/images/141/large/bnc.png?1706606748"
    }
  },
  {
    id: "arbitrum-one",
    chain_identifier: 42161,
    name: "Arbitrum One",
    shortname: "Arbitrum",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/33/thumb/AO_logomark.png?1706606717",
      small: "https://coin-images.coingecko.com/asset_platforms/images/33/small/AO_logomark.png?1706606717",
      large: "https://coin-images.coingecko.com/asset_platforms/images/33/large/AO_logomark.png?1706606717"
    }
  },
  {
    id: "ki-chain",
    chain_identifier: null,
    name: "Ki Chain",
    shortname: "",
    native_coin_id: "ki",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/162/thumb/ki_chain.png?1706606755",
      small: "https://coin-images.coingecko.com/asset_platforms/images/162/small/ki_chain.png?1706606755",
      large: "https://coin-images.coingecko.com/asset_platforms/images/162/large/ki_chain.png?1706606755"
    }
  },
  {
    id: "songbird",
    chain_identifier: 19,
    name: "Songbird",
    shortname: "",
    native_coin_id: "songbird",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/101/thumb/songbird.jpeg?1706606724",
      small: "https://coin-images.coingecko.com/asset_platforms/images/101/small/songbird.jpeg?1706606724",
      large: "https://coin-images.coingecko.com/asset_platforms/images/101/large/songbird.jpeg?1706606724"
    }
  },
  {
    id: "fantom",
    chain_identifier: 250,
    name: "Fantom",
    shortname: "",
    native_coin_id: "fantom",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/17/thumb/fantom.png?1706606773",
      small: "https://coin-images.coingecko.com/asset_platforms/images/17/small/fantom.png?1706606773",
      large: "https://coin-images.coingecko.com/asset_platforms/images/17/large/fantom.png?1706606773"
    }
  },
  {
    id: "base",
    chain_identifier: 8453,
    name: "Base",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/131/thumb/base-network.png?1720533039",
      small: "https://coin-images.coingecko.com/asset_platforms/images/131/small/base-network.png?1720533039",
      large: "https://coin-images.coingecko.com/asset_platforms/images/131/large/base-network.png?1720533039"
    }
  },
  {
    id: "optimistic-ethereum",
    chain_identifier: 10,
    name: "Optimism",
    shortname: "Optimism",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/41/thumb/optimism.png?1706606778",
      small: "https://coin-images.coingecko.com/asset_platforms/images/41/small/optimism.png?1706606778",
      large: "https://coin-images.coingecko.com/asset_platforms/images/41/large/optimism.png?1706606778"
    }
  },
  {
    id: "scroll",
    chain_identifier: 534352,
    name: "Scroll",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/153/thumb/scroll.jpeg?1706606782",
      small: "https://coin-images.coingecko.com/asset_platforms/images/153/small/scroll.jpeg?1706606782",
      large: "https://coin-images.coingecko.com/asset_platforms/images/153/large/scroll.jpeg?1706606782"
    }
  },
  {
    id: "omniflix",
    chain_identifier: null,
    name: "Omniflix",
    shortname: "",
    native_coin_id: "omniflix-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/204/thumb/omniflix.jpeg?1711348040",
      small: "https://coin-images.coingecko.com/asset_platforms/images/204/small/omniflix.jpeg?1711348040",
      large: "https://coin-images.coingecko.com/asset_platforms/images/204/large/omniflix.jpeg?1711348040"
    }
  },
  {
    id: "gravity-bridge",
    chain_identifier: null,
    name: "Gravity Bridge",
    shortname: "",
    native_coin_id: "graviton",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/205/thumb/gravity-bridge.png?1711348894",
      small: "https://coin-images.coingecko.com/asset_platforms/images/205/small/gravity-bridge.png?1711348894",
      large: "https://coin-images.coingecko.com/asset_platforms/images/205/large/gravity-bridge.png?1711348894"
    }
  },
  {
    id: "shimmer_evm",
    chain_identifier: 148,
    name: "ShimmerEVM",
    shortname: "",
    native_coin_id: "shimmer",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/154/thumb/shimmer.jpeg?1706606407",
      small: "https://coin-images.coingecko.com/asset_platforms/images/154/small/shimmer.jpeg?1706606407",
      large: "https://coin-images.coingecko.com/asset_platforms/images/154/large/shimmer.jpeg?1706606407"
    }
  },
  {
    id: "mode",
    chain_identifier: 34443,
    name: "Mode",
    shortname: "",
    native_coin_id: "mode",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/191/thumb/mode.jpeg?1708666179",
      small: "https://coin-images.coingecko.com/asset_platforms/images/191/small/mode.jpeg?1708666179",
      large: "https://coin-images.coingecko.com/asset_platforms/images/191/large/mode.jpeg?1708666179"
    }
  },
  {
    id: "aptos",
    chain_identifier: null,
    name: "Aptos",
    shortname: "",
    native_coin_id: "aptos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/116/thumb/aptos_round.png?1706606789",
      small: "https://coin-images.coingecko.com/asset_platforms/images/116/small/aptos_round.png?1706606789",
      large: "https://coin-images.coingecko.com/asset_platforms/images/116/large/aptos_round.png?1706606789"
    }
  },
  {
    id: "chihuahua",
    chain_identifier: null,
    name: "Chihuahua",
    shortname: "",
    native_coin_id: "chihuahua-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/211/thumb/chihuahuachain.jpeg?1711956042",
      small: "https://coin-images.coingecko.com/asset_platforms/images/211/small/chihuahuachain.jpeg?1711956042",
      large: "https://coin-images.coingecko.com/asset_platforms/images/211/large/chihuahuachain.jpeg?1711956042"
    }
  },
  {
    id: "coreum",
    chain_identifier: null,
    name: "Coreum",
    shortname: "",
    native_coin_id: "coreum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/212/thumb/coreum.jpeg?1711956628",
      small: "https://coin-images.coingecko.com/asset_platforms/images/212/small/coreum.jpeg?1711956628",
      large: "https://coin-images.coingecko.com/asset_platforms/images/212/large/coreum.jpeg?1711956628"
    }
  },
  {
    id: "cudos",
    chain_identifier: null,
    name: "Cudos",
    shortname: "",
    native_coin_id: "cudos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/213/thumb/cudos.jpeg?1711958110",
      small: "https://coin-images.coingecko.com/asset_platforms/images/213/small/cudos.jpeg?1711958110",
      large: "https://coin-images.coingecko.com/asset_platforms/images/213/large/cudos.jpeg?1711958110"
    }
  },
  {
    id: "desmos",
    chain_identifier: null,
    name: "Desmos",
    shortname: "",
    native_coin_id: "desmos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/214/thumb/desmos.jpeg?1711958405",
      small: "https://coin-images.coingecko.com/asset_platforms/images/214/small/desmos.jpeg?1711958405",
      large: "https://coin-images.coingecko.com/asset_platforms/images/214/large/desmos.jpeg?1711958405"
    }
  },
  {
    id: "dydx",
    chain_identifier: null,
    name: "dYdX",
    shortname: "",
    native_coin_id: "dydx-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/215/thumb/dydx.jpeg?1711958553",
      small: "https://coin-images.coingecko.com/asset_platforms/images/215/small/dydx.jpeg?1711958553",
      large: "https://coin-images.coingecko.com/asset_platforms/images/215/large/dydx.jpeg?1711958553"
    }
  },
  {
    id: "dymension",
    chain_identifier: null,
    name: "Dymension",
    shortname: "",
    native_coin_id: "dymension",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/216/thumb/dymension.jpeg?1711958638",
      small: "https://coin-images.coingecko.com/asset_platforms/images/216/small/dymension.jpeg?1711958638",
      large: "https://coin-images.coingecko.com/asset_platforms/images/216/large/dymension.jpeg?1711958638"
    }
  },
  {
    id: "iris",
    chain_identifier: null,
    name: "IRIS",
    shortname: "",
    native_coin_id: "iris-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/217/thumb/irisnetwork.jpeg?1711960270",
      small: "https://coin-images.coingecko.com/asset_platforms/images/217/small/irisnetwork.jpeg?1711960270",
      large: "https://coin-images.coingecko.com/asset_platforms/images/217/large/irisnetwork.jpeg?1711960270"
    }
  },
  {
    id: "ixo",
    chain_identifier: null,
    name: "IXO",
    shortname: "",
    native_coin_id: "ixo",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/218/thumb/ixo.jpeg?1711960435",
      small: "https://coin-images.coingecko.com/asset_platforms/images/218/small/ixo.jpeg?1711960435",
      large: "https://coin-images.coingecko.com/asset_platforms/images/218/large/ixo.jpeg?1711960435"
    }
  },
  {
    id: "kyve",
    chain_identifier: null,
    name: "KYVE",
    shortname: "",
    native_coin_id: "kyve-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/219/thumb/kyve.jpeg?1711960818",
      small: "https://coin-images.coingecko.com/asset_platforms/images/219/small/kyve.jpeg?1711960818",
      large: "https://coin-images.coingecko.com/asset_platforms/images/219/large/kyve.jpeg?1711960818"
    }
  },
  {
    id: "likecoin",
    chain_identifier: null,
    name: "LIKECOIN",
    shortname: "",
    native_coin_id: "likecoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/220/thumb/likecoin.jpeg?1711961152",
      small: "https://coin-images.coingecko.com/asset_platforms/images/220/small/likecoin.jpeg?1711961152",
      large: "https://coin-images.coingecko.com/asset_platforms/images/220/large/likecoin.jpeg?1711961152"
    }
  },
  {
    id: "lum",
    chain_identifier: null,
    name: "LUM",
    shortname: "",
    native_coin_id: "lum-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/221/thumb/lum.jpeg?1711962082",
      small: "https://coin-images.coingecko.com/asset_platforms/images/221/small/lum.jpeg?1711962082",
      large: "https://coin-images.coingecko.com/asset_platforms/images/221/large/lum.jpeg?1711962082"
    }
  },
  {
    id: "mars-protocol",
    chain_identifier: null,
    name: "Mars Protocol",
    shortname: "",
    native_coin_id: "mars-protocol-a7fcbcfb-fd61-4017-92f0-7ee9f9cc6da3",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/222/thumb/mars_protocol.png?1711962248",
      small: "https://coin-images.coingecko.com/asset_platforms/images/222/small/mars_protocol.png?1711962248",
      large: "https://coin-images.coingecko.com/asset_platforms/images/222/large/mars_protocol.png?1711962248"
    }
  },
  {
    id: "nibiru",
    chain_identifier: null,
    name: "Nibiru",
    shortname: "",
    native_coin_id: "nibiru",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/223/thumb/nibiru.jpeg?1711962547",
      small: "https://coin-images.coingecko.com/asset_platforms/images/223/small/nibiru.jpeg?1711962547",
      large: "https://coin-images.coingecko.com/asset_platforms/images/223/large/nibiru.jpeg?1711962547"
    }
  },
  {
    id: "onomy",
    chain_identifier: null,
    name: "Onomy",
    shortname: "",
    native_coin_id: "onomy-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/224/thumb/onomy-protocol.jpeg?1711962986",
      small: "https://coin-images.coingecko.com/asset_platforms/images/224/small/onomy-protocol.jpeg?1711962986",
      large: "https://coin-images.coingecko.com/asset_platforms/images/224/large/onomy-protocol.jpeg?1711962986"
    }
  },
  {
    id: "persistence",
    chain_identifier: null,
    name: "Persistence",
    shortname: "",
    native_coin_id: "persistence",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/225/thumb/Persistence_One.jpeg?1711963960",
      small: "https://coin-images.coingecko.com/asset_platforms/images/225/small/Persistence_One.jpeg?1711963960",
      large: "https://coin-images.coingecko.com/asset_platforms/images/225/large/Persistence_One.jpeg?1711963960"
    }
  },
  {
    id: "quasar",
    chain_identifier: null,
    name: "Quasar",
    shortname: "",
    native_coin_id: "quasar-2",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "regen",
    chain_identifier: null,
    name: "Regen",
    shortname: "",
    native_coin_id: "regen",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/227/thumb/regen.png?1711964852",
      small: "https://coin-images.coingecko.com/asset_platforms/images/227/small/regen.png?1711964852",
      large: "https://coin-images.coingecko.com/asset_platforms/images/227/large/regen.png?1711964852"
    }
  },
  {
    id: "sommelier",
    chain_identifier: null,
    name: "Sommelier",
    shortname: "",
    native_coin_id: "sommelier",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/228/thumb/sommelier.png?1711965222",
      small: "https://coin-images.coingecko.com/asset_platforms/images/228/small/sommelier.png?1711965222",
      large: "https://coin-images.coingecko.com/asset_platforms/images/228/large/sommelier.png?1711965222"
    }
  },
  {
    id: "stride",
    chain_identifier: null,
    name: "Stride",
    shortname: "",
    native_coin_id: "stride",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/229/thumb/STRD.png?1711965647",
      small: "https://coin-images.coingecko.com/asset_platforms/images/229/small/STRD.png?1711965647",
      large: "https://coin-images.coingecko.com/asset_platforms/images/229/large/STRD.png?1711965647"
    }
  },
  {
    id: "teritori",
    chain_identifier: null,
    name: "Teritori",
    shortname: "",
    native_coin_id: "teritori",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/230/thumb/teritori.jpeg?1711965808",
      small: "https://coin-images.coingecko.com/asset_platforms/images/230/small/teritori.jpeg?1711965808",
      large: "https://coin-images.coingecko.com/asset_platforms/images/230/large/teritori.jpeg?1711965808"
    }
  },
  {
    id: "ux-chain",
    chain_identifier: null,
    name: "UX Chain",
    shortname: "",
    native_coin_id: "umee",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/231/thumb/ux.jpeg?1711966080",
      small: "https://coin-images.coingecko.com/asset_platforms/images/231/small/ux.jpeg?1711966080",
      large: "https://coin-images.coingecko.com/asset_platforms/images/231/large/ux.jpeg?1711966080"
    }
  },
  {
    id: "flow-evm",
    chain_identifier: 747,
    name: "Flow EVM",
    shortname: "",
    native_coin_id: "flow",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22173/thumb/flow.jpg?1727072603",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22173/small/flow.jpg?1727072603",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22173/large/flow.jpg?1727072603"
    }
  },
  {
    id: "mayachain",
    chain_identifier: null,
    name: "MAYAChain",
    shortname: "",
    native_coin_id: "cacao",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/232/thumb/maya.jpeg?1712287090",
      small: "https://coin-images.coingecko.com/asset_platforms/images/232/small/maya.jpeg?1712287090",
      large: "https://coin-images.coingecko.com/asset_platforms/images/232/large/maya.jpeg?1712287090"
    }
  },
  {
    id: "ql1",
    chain_identifier: 766,
    name: "QL1",
    shortname: "",
    native_coin_id: "shiba-predator",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22176/thumb/qom.png?1727689494",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22176/small/qom.png?1727689494",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22176/large/qom.png?1727689494"
    }
  },
  {
    id: "galachain",
    chain_identifier: null,
    name: "GalaChain",
    shortname: "",
    native_coin_id: "gala",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/237/thumb/gala.jpeg?1713164830",
      small: "https://coin-images.coingecko.com/asset_platforms/images/237/small/gala.jpeg?1713164830",
      large: "https://coin-images.coingecko.com/asset_platforms/images/237/large/gala.jpeg?1713164830"
    }
  },
  {
    id: "xt-smart-chain",
    chain_identifier: 520,
    name: "XT Smart Chain",
    shortname: "",
    native_coin_id: "xtcom-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/285/thumb/xt.jpg?1722395971",
      small: "https://coin-images.coingecko.com/asset_platforms/images/285/small/xt.jpg?1722395971",
      large: "https://coin-images.coingecko.com/asset_platforms/images/285/large/xt.jpg?1722395971"
    }
  },
  {
    id: "endurance",
    chain_identifier: 648,
    name: "Endurance",
    shortname: "",
    native_coin_id: "endurance",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/267/thumb/endurance.jpeg?1717148781",
      small: "https://coin-images.coingecko.com/asset_platforms/images/267/small/endurance.jpeg?1717148781",
      large: "https://coin-images.coingecko.com/asset_platforms/images/267/large/endurance.jpeg?1717148781"
    }
  },
  {
    id: "bitgert",
    chain_identifier: 32520,
    name: "Bitgert Chain",
    shortname: "Bitgert Brise",
    native_coin_id: "bitrise-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/83/thumb/bitgert.jpeg?1713154905",
      small: "https://coin-images.coingecko.com/asset_platforms/images/83/small/bitgert.jpeg?1713154905",
      large: "https://coin-images.coingecko.com/asset_platforms/images/83/large/bitgert.jpeg?1713154905"
    }
  },
  {
    id: "stacks",
    chain_identifier: null,
    name: "Stacks",
    shortname: "",
    native_coin_id: "blockstack",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/97/thumb/Stacks.png?1706606555",
      small: "https://coin-images.coingecko.com/asset_platforms/images/97/small/Stacks.png?1706606555",
      large: "https://coin-images.coingecko.com/asset_platforms/images/97/large/Stacks.png?1706606555"
    }
  },
  {
    id: "ham",
    chain_identifier: 5112,
    name: "Ham",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/295/thumb/ham.png?1724906038",
      small: "https://coin-images.coingecko.com/asset_platforms/images/295/small/ham.png?1724906038",
      large: "https://coin-images.coingecko.com/asset_platforms/images/295/large/ham.png?1724906038"
    }
  },
  {
    id: "bsquared-network",
    chain_identifier: null,
    name: "BSquared Network",
    shortname: "",
    native_coin_id: "bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/239/thumb/bsquared-network.jpeg?1713432732",
      small: "https://coin-images.coingecko.com/asset_platforms/images/239/small/bsquared-network.jpeg?1713432732",
      large: "https://coin-images.coingecko.com/asset_platforms/images/239/large/bsquared-network.jpeg?1713432732"
    }
  },
  {
    id: "flow",
    chain_identifier: null,
    name: "Flow",
    shortname: "",
    native_coin_id: "flow",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/152/thumb/flow.jpeg?1706606393",
      small: "https://coin-images.coingecko.com/asset_platforms/images/152/small/flow.jpeg?1706606393",
      large: "https://coin-images.coingecko.com/asset_platforms/images/152/large/flow.jpeg?1706606393"
    }
  },
  {
    id: "kadena",
    chain_identifier: null,
    name: "Kadena",
    shortname: "",
    native_coin_id: "kadena",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/105/thumb/KADENA.jpg?1712302262",
      small: "https://coin-images.coingecko.com/asset_platforms/images/105/small/KADENA.jpg?1712302262",
      large: "https://coin-images.coingecko.com/asset_platforms/images/105/large/KADENA.jpg?1712302262"
    }
  },
  {
    id: "massa",
    chain_identifier: null,
    name: "Massa",
    shortname: "",
    native_coin_id: "massa",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/241/thumb/massa.jpeg?1713847307",
      small: "https://coin-images.coingecko.com/asset_platforms/images/241/small/massa.jpeg?1713847307",
      large: "https://coin-images.coingecko.com/asset_platforms/images/241/large/massa.jpeg?1713847307"
    }
  },
  {
    id: "bitlayer",
    chain_identifier: 200901,
    name: "Bitlayer",
    shortname: "",
    native_coin_id: "bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/244/thumb/bitlayer.jpeg?1713928802",
      small: "https://coin-images.coingecko.com/asset_platforms/images/244/small/bitlayer.jpeg?1713928802",
      large: "https://coin-images.coingecko.com/asset_platforms/images/244/large/bitlayer.jpeg?1713928802"
    }
  },
  {
    id: "lukso",
    chain_identifier: 42,
    name: "Lukso",
    shortname: "",
    native_coin_id: "lukso-token-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/199/thumb/lukso.jpeg?1710834078",
      small: "https://coin-images.coingecko.com/asset_platforms/images/199/small/lukso.jpeg?1710834078",
      large: "https://coin-images.coingecko.com/asset_platforms/images/199/large/lukso.jpeg?1710834078"
    }
  },
  {
    id: "bevm",
    chain_identifier: 11501,
    name: "BEVM",
    shortname: "",
    native_coin_id: "bitcoin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/245/thumb/bevm.jpeg?1713945524",
      small: "https://coin-images.coingecko.com/asset_platforms/images/245/small/bevm.jpeg?1713945524",
      large: "https://coin-images.coingecko.com/asset_platforms/images/245/large/bevm.jpeg?1713945524"
    }
  },
  {
    id: "tdvv-sidechain",
    chain_identifier: null,
    name: "tDVV SideChain",
    shortname: "",
    native_coin_id: "aelf",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "octaspace",
    chain_identifier: 800001,
    name: "OctaSpace",
    shortname: "",
    native_coin_id: "octaspace",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/249/thumb/octaspace.jpeg?1714442969",
      small: "https://coin-images.coingecko.com/asset_platforms/images/249/small/octaspace.jpeg?1714442969",
      large: "https://coin-images.coingecko.com/asset_platforms/images/249/large/octaspace.jpeg?1714442969"
    }
  },
  {
    id: "radix",
    chain_identifier: null,
    name: "Radix",
    shortname: "",
    native_coin_id: "radix",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/107/thumb/radix.jpeg?1706606757",
      small: "https://coin-images.coingecko.com/asset_platforms/images/107/small/radix.jpeg?1706606757",
      large: "https://coin-images.coingecko.com/asset_platforms/images/107/large/radix.jpeg?1706606757"
    }
  },
  {
    id: "polkadot",
    chain_identifier: null,
    name: "Polkadot",
    shortname: "",
    native_coin_id: "polkadot",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/12171/thumb/polkadot.jpeg?1706606400",
      small: "https://coin-images.coingecko.com/asset_platforms/images/12171/small/polkadot.jpeg?1706606400",
      large: "https://coin-images.coingecko.com/asset_platforms/images/12171/large/polkadot.jpeg?1706606400"
    }
  },
  {
    id: "blast",
    chain_identifier: 81457,
    name: "Blast",
    shortname: "",
    native_coin_id: "blast-old",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/192/thumb/blast.jpeg?1709085131",
      small: "https://coin-images.coingecko.com/asset_platforms/images/192/small/blast.jpeg?1709085131",
      large: "https://coin-images.coingecko.com/asset_platforms/images/192/large/blast.jpeg?1709085131"
    }
  },
  {
    id: "oraichain",
    chain_identifier: null,
    name: "Oraichain",
    shortname: "",
    native_coin_id: "oraichain-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/252/thumb/oraichain.jpeg?1714730939",
      small: "https://coin-images.coingecko.com/asset_platforms/images/252/small/oraichain.jpeg?1714730939",
      large: "https://coin-images.coingecko.com/asset_platforms/images/252/large/oraichain.jpeg?1714730939"
    }
  },
  {
    id: "cronos",
    chain_identifier: 25,
    name: "Cronos",
    shortname: "CRO",
    native_coin_id: "crypto-com-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/46/thumb/cronos.jpeg?1706606792",
      small: "https://coin-images.coingecko.com/asset_platforms/images/46/small/cronos.jpeg?1706606792",
      large: "https://coin-images.coingecko.com/asset_platforms/images/46/large/cronos.jpeg?1706606792"
    }
  },
  {
    id: "wax",
    chain_identifier: null,
    name: "WAX",
    shortname: "",
    native_coin_id: "wax",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/254/thumb/wax.png?1715049433",
      small: "https://coin-images.coingecko.com/asset_platforms/images/254/small/wax.png?1715049433",
      large: "https://coin-images.coingecko.com/asset_platforms/images/254/large/wax.png?1715049433"
    }
  },
  {
    id: "zklink-nova",
    chain_identifier: 810180,
    name: " zkLink Nova",
    shortname: "",
    native_coin_id: "zklink",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/251/thumb/4PmOBjg9_400x400.png?1715060990",
      small: "https://coin-images.coingecko.com/asset_platforms/images/251/small/4PmOBjg9_400x400.png?1715060990",
      large: "https://coin-images.coingecko.com/asset_platforms/images/251/large/4PmOBjg9_400x400.png?1715060990"
    }
  },
  {
    id: "aurora",
    chain_identifier: 1313161554,
    name: "Aurora",
    shortname: "aurora",
    native_coin_id: "aurora-near",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/53/thumb/Aurora-logo-token-darkBG.png?1706606474",
      small: "https://coin-images.coingecko.com/asset_platforms/images/53/small/Aurora-logo-token-darkBG.png?1706606474",
      large: "https://coin-images.coingecko.com/asset_platforms/images/53/large/Aurora-logo-token-darkBG.png?1706606474"
    }
  },
  {
    id: "celo",
    chain_identifier: 42220,
    name: "Celo",
    shortname: "celo",
    native_coin_id: "celo",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/21/thumb/celo.jpeg?1711358666",
      small: "https://coin-images.coingecko.com/asset_platforms/images/21/small/celo.jpeg?1711358666",
      large: "https://coin-images.coingecko.com/asset_platforms/images/21/large/celo.jpeg?1711358666"
    }
  },
  {
    id: "archway",
    chain_identifier: null,
    name: "Archway",
    shortname: "",
    native_coin_id: "archway",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/200/thumb/Archway.jpeg?1715137138",
      small: "https://coin-images.coingecko.com/asset_platforms/images/200/small/Archway.jpeg?1715137138",
      large: "https://coin-images.coingecko.com/asset_platforms/images/200/large/Archway.jpeg?1715137138"
    }
  },
  {
    id: "xrp",
    chain_identifier: null,
    name: "XRP Ledger",
    shortname: "xrp",
    native_coin_id: "ripple",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/36/thumb/xrp-logo.jpg?1706606750",
      small: "https://coin-images.coingecko.com/asset_platforms/images/36/small/xrp-logo.jpg?1706606750",
      large: "https://coin-images.coingecko.com/asset_platforms/images/36/large/xrp-logo.jpg?1706606750"
    }
  },
  {
    id: "energi",
    chain_identifier: 39797,
    name: "Energi",
    shortname: "",
    native_coin_id: "energi",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/76/thumb/energi.jpeg?1706606568",
      small: "https://coin-images.coingecko.com/asset_platforms/images/76/small/energi.jpeg?1706606568",
      large: "https://coin-images.coingecko.com/asset_platforms/images/76/large/energi.jpeg?1706606568"
    }
  },
  {
    id: "polygon-zkevm",
    chain_identifier: 1101,
    name: "Polygon zkEVM",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/122/thumb/polygonzkevm.jpg?1706606698",
      small: "https://coin-images.coingecko.com/asset_platforms/images/122/small/polygonzkevm.jpg?1706606698",
      large: "https://coin-images.coingecko.com/asset_platforms/images/122/large/polygonzkevm.jpg?1706606698"
    }
  },
  {
    id: "evmos",
    chain_identifier: 9001,
    name: "Evmos",
    shortname: "evmos",
    native_coin_id: "evmos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/74/thumb/evmos.png?1711350079",
      small: "https://coin-images.coingecko.com/asset_platforms/images/74/small/evmos.png?1711350079",
      large: "https://coin-images.coingecko.com/asset_platforms/images/74/large/evmos.png?1711350079"
    }
  },
  {
    id: "terra",
    chain_identifier: null,
    name: "Terra Classic",
    shortname: "",
    native_coin_id: "terra-luna",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/18/thumb/Terra_Classic.jpg?1724898761",
      small: "https://coin-images.coingecko.com/asset_platforms/images/18/small/Terra_Classic.jpg?1724898761",
      large: "https://coin-images.coingecko.com/asset_platforms/images/18/large/Terra_Classic.jpg?1724898761"
    }
  },
  {
    id: "secret",
    chain_identifier: null,
    name: "Secret",
    shortname: "",
    native_coin_id: "secret",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/43/thumb/scrt.png?1706606753",
      small: "https://coin-images.coingecko.com/asset_platforms/images/43/small/scrt.png?1706606753",
      large: "https://coin-images.coingecko.com/asset_platforms/images/43/large/scrt.png?1706606753"
    }
  },
  {
    id: "iotex",
    chain_identifier: 4689,
    name: "IoTeX",
    shortname: "iotex",
    native_coin_id: "iotex",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/16/thumb/iotex.png?1706606819",
      small: "https://coin-images.coingecko.com/asset_platforms/images/16/small/iotex.png?1706606819",
      large: "https://coin-images.coingecko.com/asset_platforms/images/16/large/iotex.png?1706606819"
    }
  },
  {
    id: "lisk",
    chain_identifier: 1135,
    name: "Lisk",
    shortname: "lisk",
    native_coin_id: "lisk",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/283/thumb/lisk.webp?1720782445",
      small: "https://coin-images.coingecko.com/asset_platforms/images/283/small/lisk.webp?1720782445",
      large: "https://coin-images.coingecko.com/asset_platforms/images/283/large/lisk.webp?1720782445"
    }
  },
  {
    id: "astar",
    chain_identifier: 592,
    name: "Astar",
    shortname: "",
    native_coin_id: "astar",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/71/thumb/Astar.jpeg?1706606537",
      small: "https://coin-images.coingecko.com/asset_platforms/images/71/small/Astar.jpeg?1706606537",
      large: "https://coin-images.coingecko.com/asset_platforms/images/71/large/Astar.jpeg?1706606537"
    }
  },
  {
    id: "fuse",
    chain_identifier: 122,
    name: "Fuse",
    shortname: "",
    native_coin_id: "fuse-network-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/55/thumb/2023-02-14_11.03.01.jpg?1706606586",
      small: "https://coin-images.coingecko.com/asset_platforms/images/55/small/2023-02-14_11.03.01.jpg?1706606586",
      large: "https://coin-images.coingecko.com/asset_platforms/images/55/large/2023-02-14_11.03.01.jpg?1706606586"
    }
  },
  {
    id: "metal-l2",
    chain_identifier: null,
    name: "Metal L2",
    shortname: "metal-l2",
    native_coin_id: "metal",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22174/thumb/metal.jpg?1727078117",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22174/small/metal.jpg?1727078117",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22174/large/metal.jpg?1727078117"
    }
  },
  {
    id: "jibchain",
    chain_identifier: 8899,
    name: "Jibchain",
    shortname: "",
    native_coin_id: "wrapped-jbc",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/294/thumb/jibchain.png?1724905779",
      small: "https://coin-images.coingecko.com/asset_platforms/images/294/small/jibchain.png?1724905779",
      large: "https://coin-images.coingecko.com/asset_platforms/images/294/large/jibchain.png?1724905779"
    }
  },
  {
    id: "xdc-network",
    chain_identifier: 50,
    name: "XDC Network",
    shortname: "xdc xinfin",
    native_coin_id: "xdce-crowd-sale",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/27/thumb/xdc_%281%29.png?1706606530",
      small: "https://coin-images.coingecko.com/asset_platforms/images/27/small/xdc_%281%29.png?1706606530",
      large: "https://coin-images.coingecko.com/asset_platforms/images/27/large/xdc_%281%29.png?1706606530"
    }
  },
  {
    id: "terra-2",
    chain_identifier: null,
    name: "Terra",
    shortname: "",
    native_coin_id: "terra-luna-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/120/thumb/terra.png?1706606650",
      small: "https://coin-images.coingecko.com/asset_platforms/images/120/small/terra.png?1706606650",
      large: "https://coin-images.coingecko.com/asset_platforms/images/120/large/terra.png?1706606650"
    }
  },
  {
    id: "bob-network",
    chain_identifier: null,
    name: "Bob Network",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/255/thumb/bob.jpeg?1715229826",
      small: "https://coin-images.coingecko.com/asset_platforms/images/255/small/bob.jpeg?1715229826",
      large: "https://coin-images.coingecko.com/asset_platforms/images/255/large/bob.jpeg?1715229826"
    }
  },
  {
    id: "wanchain",
    chain_identifier: 888,
    name: "Wanchain",
    shortname: "",
    native_coin_id: "wanchain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/3482/thumb/Wanchain.png?1724898670",
      small: "https://coin-images.coingecko.com/asset_platforms/images/3482/small/Wanchain.png?1724898670",
      large: "https://coin-images.coingecko.com/asset_platforms/images/3482/large/Wanchain.png?1724898670"
    }
  },
  {
    id: "ethereumpow",
    chain_identifier: null,
    name: "EthereumPoW",
    shortname: "",
    native_coin_id: "ethereum-pow-iou",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/102/thumb/ETHW.png?1706606548",
      small: "https://coin-images.coingecko.com/asset_platforms/images/102/small/ETHW.png?1706606548",
      large: "https://coin-images.coingecko.com/asset_platforms/images/102/large/ETHW.png?1706606548"
    }
  },
  {
    id: "bittorrent",
    chain_identifier: 199,
    name: "BitTorrent",
    shortname: "",
    native_coin_id: "bittorrent",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/108/thumb/bttc.png?1706606477",
      small: "https://coin-images.coingecko.com/asset_platforms/images/108/small/bttc.png?1706606477",
      large: "https://coin-images.coingecko.com/asset_platforms/images/108/large/bttc.png?1706606477"
    }
  },
  {
    id: "elastos",
    chain_identifier: 20,
    name: "Elastos Smart Contract Chain",
    shortname: "Elastos",
    native_coin_id: "elastos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/69/thumb/elastos.jpeg?1706606521",
      small: "https://coin-images.coingecko.com/asset_platforms/images/69/small/elastos.jpeg?1706606521",
      large: "https://coin-images.coingecko.com/asset_platforms/images/69/large/elastos.jpeg?1706606521"
    }
  },
  {
    id: "smartbch",
    chain_identifier: 10000,
    name: "SmartBCH",
    shortname: "",
    native_coin_id: "bitcoin-cash",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/51/thumb/bch.png?1706606492",
      small: "https://coin-images.coingecko.com/asset_platforms/images/51/small/bch.png?1706606492",
      large: "https://coin-images.coingecko.com/asset_platforms/images/51/large/bch.png?1706606492"
    }
  },
  {
    id: "step-network",
    chain_identifier: 1234,
    name: "Step Network",
    shortname: "",
    native_coin_id: "stepex",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/98/thumb/step.jpeg?1706606616",
      small: "https://coin-images.coingecko.com/asset_platforms/images/98/small/step.jpeg?1706606616",
      large: "https://coin-images.coingecko.com/asset_platforms/images/98/large/step.jpeg?1706606616"
    }
  },
  {
    id: "migaloo",
    chain_identifier: null,
    name: "Migaloo",
    shortname: "",
    native_coin_id: "white-whale",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/163/thumb/migaloo.jpg?1706606696",
      small: "https://coin-images.coingecko.com/asset_platforms/images/163/small/migaloo.jpg?1706606696",
      large: "https://coin-images.coingecko.com/asset_platforms/images/163/large/migaloo.jpg?1706606696"
    }
  },
  {
    id: "hedera-hashgraph",
    chain_identifier: 295,
    name: "Hedera Hashgraph",
    shortname: "hashgraph",
    native_coin_id: "hedera-hashgraph",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/30/thumb/hedera.jpeg?1706606460",
      small: "https://coin-images.coingecko.com/asset_platforms/images/30/small/hedera.jpeg?1706606460",
      large: "https://coin-images.coingecko.com/asset_platforms/images/30/large/hedera.jpeg?1706606460"
    }
  },
  {
    id: "thundercore",
    chain_identifier: 108,
    name: "ThunderCore",
    shortname: "",
    native_coin_id: "thunder-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/86/thumb/thundercore.jpg?1706606627",
      small: "https://coin-images.coingecko.com/asset_platforms/images/86/small/thundercore.jpg?1706606627",
      large: "https://coin-images.coingecko.com/asset_platforms/images/86/large/thundercore.jpg?1706606627"
    }
  },
  {
    id: "proof-of-memes",
    chain_identifier: 18159,
    name: "Proof of Memes",
    shortname: "",
    native_coin_id: "proof-of-memes-pomchain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/112/thumb/proof_of_meme.jpeg?1706606430",
      small: "https://coin-images.coingecko.com/asset_platforms/images/112/small/proof_of_meme.jpeg?1706606430",
      large: "https://coin-images.coingecko.com/asset_platforms/images/112/large/proof_of_meme.jpeg?1706606430"
    }
  },
  {
    id: "mainnetz",
    chain_identifier: 2016,
    name: "MainnetZ",
    shortname: "",
    native_coin_id: "mainnetz",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/282/thumb/MainnetZ.png?1724842719",
      small: "https://coin-images.coingecko.com/asset_platforms/images/282/small/MainnetZ.png?1724842719",
      large: "https://coin-images.coingecko.com/asset_platforms/images/282/large/MainnetZ.png?1724842719"
    }
  },
  {
    id: "hydra",
    chain_identifier: null,
    name: "Hydra",
    shortname: "",
    native_coin_id: "hydra",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/85/thumb/hydra.jpeg?1706606737",
      small: "https://coin-images.coingecko.com/asset_platforms/images/85/small/hydra.jpeg?1706606737",
      large: "https://coin-images.coingecko.com/asset_platforms/images/85/large/hydra.jpeg?1706606737"
    }
  },
  {
    id: "niza",
    chain_identifier: null,
    name: "Niza",
    shortname: "",
    native_coin_id: "niza-global",
    image: {
      thumb: null,
      small: null,
      large: null
    }
  },
  {
    id: "uton",
    chain_identifier: null,
    name: "UTON",
    shortname: "",
    native_coin_id: "play-to-create",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/268/thumb/uton.png?1717463902",
      small: "https://coin-images.coingecko.com/asset_platforms/images/268/small/uton.png?1717463902",
      large: "https://coin-images.coingecko.com/asset_platforms/images/268/large/uton.png?1717463902"
    }
  },
  {
    id: "saakuru",
    chain_identifier: 7225878,
    name: "Saakuru",
    shortname: "",
    native_coin_id: "wrapped-oasys",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/280/thumb/saakuru.jpg?1719803308",
      small: "https://coin-images.coingecko.com/asset_platforms/images/280/small/saakuru.jpg?1719803308",
      large: "https://coin-images.coingecko.com/asset_platforms/images/280/large/saakuru.jpg?1719803308"
    }
  },
  {
    id: "hyperliquid",
    chain_identifier: null,
    name: "Hyperliquid",
    shortname: "",
    native_coin_id: "usd-coin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/243/thumb/hyperliquid.png?1713924434",
      small: "https://coin-images.coingecko.com/asset_platforms/images/243/small/hyperliquid.png?1713924434",
      large: "https://coin-images.coingecko.com/asset_platforms/images/243/large/hyperliquid.png?1713924434"
    }
  },
  {
    id: "oasis-sapphire",
    chain_identifier: 23294,
    name: "Oasis Sapphire",
    shortname: "",
    native_coin_id: "oasis-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/187/thumb/oasis.png?1708397299",
      small: "https://coin-images.coingecko.com/asset_platforms/images/187/small/oasis.png?1708397299",
      large: "https://coin-images.coingecko.com/asset_platforms/images/187/large/oasis.png?1708397299"
    }
  },
  {
    id: "vanar-chain",
    chain_identifier: 2040,
    name: "Vanar Chain",
    shortname: "",
    native_coin_id: "vanar-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/269/thumb/vanar.jpeg?1717500553",
      small: "https://coin-images.coingecko.com/asset_platforms/images/269/small/vanar.jpeg?1717500553",
      large: "https://coin-images.coingecko.com/asset_platforms/images/269/large/vanar.jpeg?1717500553"
    }
  },
  {
    id: "iota-evm",
    chain_identifier: 8822,
    name: "Iota EVM",
    shortname: "",
    native_coin_id: "iota",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/276/thumb/iota_logo_black_symbol_only.png?1718858198",
      small: "https://coin-images.coingecko.com/asset_platforms/images/276/small/iota_logo_black_symbol_only.png?1718858198",
      large: "https://coin-images.coingecko.com/asset_platforms/images/276/large/iota_logo_black_symbol_only.png?1718858198"
    }
  },
  {
    id: "nahmii",
    chain_identifier: 4061,
    name: "Nahmii",
    shortname: "nahmii",
    native_coin_id: "nahmii",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/270/thumb/nahmii.jpeg?1717568564",
      small: "https://coin-images.coingecko.com/asset_platforms/images/270/small/nahmii.jpeg?1717568564",
      large: "https://coin-images.coingecko.com/asset_platforms/images/270/large/nahmii.jpeg?1717568564"
    }
  },
  {
    id: "boba-bnb",
    chain_identifier: 56288,
    name: "Boba BNB",
    shortname: "",
    native_coin_id: "boba-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/271/thumb/boba.jpeg?1717594533",
      small: "https://coin-images.coingecko.com/asset_platforms/images/271/small/boba.jpeg?1717594533",
      large: "https://coin-images.coingecko.com/asset_platforms/images/271/large/boba.jpeg?1717594533"
    }
  },
  {
    id: "zedxion",
    chain_identifier: 83872,
    name: "Zedxion Smart Chain",
    shortname: "Zedxion",
    native_coin_id: "zedxion-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/265/thumb/zedxion.png?1716526781",
      small: "https://coin-images.coingecko.com/asset_platforms/images/265/small/zedxion.png?1716526781",
      large: "https://coin-images.coingecko.com/asset_platforms/images/265/large/zedxion.png?1716526781"
    }
  },
  {
    id: "vyvo-smart-chain",
    chain_identifier: 8889,
    name: "Vyvo Smart Chain",
    shortname: "",
    native_coin_id: "vyvo-smart-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/274/thumb/Vyvo.jpg?1724843210",
      small: "https://coin-images.coingecko.com/asset_platforms/images/274/small/Vyvo.jpg?1724843210",
      large: "https://coin-images.coingecko.com/asset_platforms/images/274/large/Vyvo.jpg?1724843210"
    }
  },
  {
    id: "astar-zkevm",
    chain_identifier: 3776,
    name: "Astar zkEVM",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/198/thumb/astar.png?1710138207",
      small: "https://coin-images.coingecko.com/asset_platforms/images/198/small/astar.png?1710138207",
      large: "https://coin-images.coingecko.com/asset_platforms/images/198/large/astar.png?1710138207"
    }
  },
  {
    id: "oasys",
    chain_identifier: 248,
    name: "Oasys",
    shortname: "",
    native_coin_id: "oasys",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/110/thumb/oasys.jpeg?1706606566",
      small: "https://coin-images.coingecko.com/asset_platforms/images/110/small/oasys.jpeg?1706606566",
      large: "https://coin-images.coingecko.com/asset_platforms/images/110/large/oasys.jpeg?1706606566"
    }
  },
  {
    id: "milkomeda-cardano",
    chain_identifier: 2001,
    name: "Milkomeda (Cardano)",
    shortname: "",
    native_coin_id: "cardano",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/67/thumb/milkomeda-cardano.png?1706606640",
      small: "https://coin-images.coingecko.com/asset_platforms/images/67/small/milkomeda-cardano.png?1706606640",
      large: "https://coin-images.coingecko.com/asset_platforms/images/67/large/milkomeda-cardano.png?1706606640"
    }
  },
  {
    id: "haqq-network",
    chain_identifier: 11235,
    name: "Haqq Network",
    shortname: "",
    native_coin_id: "islamic-coin",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/272/thumb/haqq-sign.png?1717594659",
      small: "https://coin-images.coingecko.com/asset_platforms/images/272/small/haqq-sign.png?1717594659",
      large: "https://coin-images.coingecko.com/asset_platforms/images/272/large/haqq-sign.png?1717594659"
    }
  },
  {
    id: "genesys-network",
    chain_identifier: 16507,
    name: "Genesys Network",
    shortname: "",
    native_coin_id: "genesys",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/177/thumb/genesys.png?1706680778",
      small: "https://coin-images.coingecko.com/asset_platforms/images/177/small/genesys.png?1706680778",
      large: "https://coin-images.coingecko.com/asset_platforms/images/177/large/genesys.png?1706680778"
    }
  },
  {
    id: "sora",
    chain_identifier: null,
    name: "Sora",
    shortname: "",
    native_coin_id: "sora",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/39/thumb/sora-xor-logo.png?1718358407",
      small: "https://coin-images.coingecko.com/asset_platforms/images/39/small/sora-xor-logo.png?1718358407",
      large: "https://coin-images.coingecko.com/asset_platforms/images/39/large/sora-xor-logo.png?1718358407"
    }
  },
  {
    id: "polygon-pos",
    chain_identifier: 137,
    name: "Polygon POS",
    shortname: "MATIC",
    native_coin_id: "matic-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/15/thumb/polygon_pos.png?1706606645",
      small: "https://coin-images.coingecko.com/asset_platforms/images/15/small/polygon_pos.png?1706606645",
      large: "https://coin-images.coingecko.com/asset_platforms/images/15/large/polygon_pos.png?1706606645"
    }
  },
  {
    id: "avalanche",
    chain_identifier: 43114,
    name: "Avalanche",
    shortname: "AVAX",
    native_coin_id: "avalanche-2",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/12/thumb/avalanche.png?1706606775",
      small: "https://coin-images.coingecko.com/asset_platforms/images/12/small/avalanche.png?1706606775",
      large: "https://coin-images.coingecko.com/asset_platforms/images/12/large/avalanche.png?1706606775"
    }
  },
  {
    id: "bitrock",
    chain_identifier: 7171,
    name: "Bitrock",
    shortname: "",
    native_coin_id: "bitrock",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/156/thumb/bitrock.jpeg?1706606780",
      small: "https://coin-images.coingecko.com/asset_platforms/images/156/small/bitrock.jpeg?1706606780",
      large: "https://coin-images.coingecko.com/asset_platforms/images/156/large/bitrock.jpeg?1706606780"
    }
  },
  {
    id: "moonriver",
    chain_identifier: 1285,
    name: "Moonriver",
    shortname: "moonriver",
    native_coin_id: "moonriver",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/38/thumb/moonriver.jpg?1725875159",
      small: "https://coin-images.coingecko.com/asset_platforms/images/38/small/moonriver.jpg?1725875159",
      large: "https://coin-images.coingecko.com/asset_platforms/images/38/large/moonriver.jpg?1725875159"
    }
  },
  {
    id: "sanko",
    chain_identifier: 1996,
    name: "Sanko",
    shortname: "",
    native_coin_id: "dream-machine-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/262/thumb/dmt.png?1717607022",
      small: "https://coin-images.coingecko.com/asset_platforms/images/262/small/dmt.png?1717607022",
      large: "https://coin-images.coingecko.com/asset_platforms/images/262/large/dmt.png?1717607022"
    }
  },
  {
    id: "zircuit",
    chain_identifier: 48900,
    name: "Zircuit",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/287/thumb/zircuit.png?1722845779",
      small: "https://coin-images.coingecko.com/asset_platforms/images/287/small/zircuit.png?1722845779",
      large: "https://coin-images.coingecko.com/asset_platforms/images/287/large/zircuit.png?1722845779"
    }
  },
  {
    id: "boba",
    chain_identifier: 288,
    name: "Boba Network",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/47/thumb/BOBA.png?1706606462",
      small: "https://coin-images.coingecko.com/asset_platforms/images/47/small/BOBA.png?1706606462",
      large: "https://coin-images.coingecko.com/asset_platforms/images/47/large/BOBA.png?1706606462"
    }
  },
  {
    id: "linea",
    chain_identifier: 59144,
    name: "Linea",
    shortname: "",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/135/thumb/linea.jpeg?1706606705",
      small: "https://coin-images.coingecko.com/asset_platforms/images/135/small/linea.jpeg?1706606705",
      large: "https://coin-images.coingecko.com/asset_platforms/images/135/large/linea.jpeg?1706606705"
    }
  },
  {
    id: "aelf",
    chain_identifier: null,
    name: "AELF",
    shortname: "",
    native_coin_id: "aelf",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/247/thumb/alelf.jpg?1724842476",
      small: "https://coin-images.coingecko.com/asset_platforms/images/247/small/alelf.jpg?1724842476",
      large: "https://coin-images.coingecko.com/asset_platforms/images/247/large/alelf.jpg?1724842476"
    }
  },
  {
    id: "conflux",
    chain_identifier: 1030,
    name: "Conflux",
    shortname: "conflux",
    native_coin_id: "conflux-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/52/thumb/conflux.png?1724842516",
      small: "https://coin-images.coingecko.com/asset_platforms/images/52/small/conflux.png?1724842516",
      large: "https://coin-images.coingecko.com/asset_platforms/images/52/large/conflux.png?1724842516"
    }
  },
  {
    id: "eos",
    chain_identifier: null,
    name: "EOS",
    shortname: "",
    native_coin_id: "eos",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/738/thumb/eos.jpg?1724842548",
      small: "https://coin-images.coingecko.com/asset_platforms/images/738/small/eos.jpg?1724842548",
      large: "https://coin-images.coingecko.com/asset_platforms/images/738/large/eos.jpg?1724842548"
    }
  },
  {
    id: "ethereum-classic",
    chain_identifier: 61,
    name: "Ethereum Classic",
    shortname: "",
    native_coin_id: "ethereum-classic",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/453/thumb/ethereum_classic.jpg?1724842608",
      small: "https://coin-images.coingecko.com/asset_platforms/images/453/small/ethereum_classic.jpg?1724842608",
      large: "https://coin-images.coingecko.com/asset_platforms/images/453/large/ethereum_classic.jpg?1724842608"
    }
  },
  {
    id: "huobi-token",
    chain_identifier: 128,
    name: "Huobi ECO Chain Mainnet",
    shortname: "HECO",
    native_coin_id: "huobi-token",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/11/thumb/Huobi_ECO_Chain_Mainnet.jpg?1724842645",
      small: "https://coin-images.coingecko.com/asset_platforms/images/11/small/Huobi_ECO_Chain_Mainnet.jpg?1724842645",
      large: "https://coin-images.coingecko.com/asset_platforms/images/11/large/Huobi_ECO_Chain_Mainnet.jpg?1724842645"
    }
  },
  {
    id: "kucoin-community-chain",
    chain_identifier: 321,
    name: "Kucoin Community Chain",
    shortname: "KCC",
    native_coin_id: "kucoin-shares",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/26/thumb/Kucoin_Community_Chain.jpg?1724842687",
      small: "https://coin-images.coingecko.com/asset_platforms/images/26/small/Kucoin_Community_Chain.jpg?1724842687",
      large: "https://coin-images.coingecko.com/asset_platforms/images/26/large/Kucoin_Community_Chain.jpg?1724842687"
    }
  },
  {
    id: "qitmeer-network",
    chain_identifier: 813,
    name: "Qitmeer Network",
    shortname: "",
    native_coin_id: "qitmeer-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/297/thumb/qitmeer.jpeg?1724912468",
      small: "https://coin-images.coingecko.com/asset_platforms/images/297/small/qitmeer.jpeg?1724912468",
      large: "https://coin-images.coingecko.com/asset_platforms/images/297/large/qitmeer.jpeg?1724912468"
    }
  },
  {
    id: "meter",
    chain_identifier: 82,
    name: "Meter",
    shortname: "",
    native_coin_id: "meter",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/57/thumb/Meter.jpg?1724842747",
      small: "https://coin-images.coingecko.com/asset_platforms/images/57/small/Meter.jpg?1724842747",
      large: "https://coin-images.coingecko.com/asset_platforms/images/57/large/Meter.jpg?1724842747"
    }
  },
  {
    id: "vechain",
    chain_identifier: 100009,
    name: "VeChain",
    shortname: "",
    native_coin_id: "vechain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/1167/thumb/vechain.png?1724843001",
      small: "https://coin-images.coingecko.com/asset_platforms/images/1167/small/vechain.png?1724843001",
      large: "https://coin-images.coingecko.com/asset_platforms/images/1167/large/vechain.png?1724843001"
    }
  },
  {
    id: "tomochain",
    chain_identifier: 88,
    name: "Viction",
    shortname: "",
    native_coin_id: "tomochain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/3416/thumb/viction.jpg?1724843158",
      small: "https://coin-images.coingecko.com/asset_platforms/images/3416/small/viction.jpg?1724843158",
      large: "https://coin-images.coingecko.com/asset_platforms/images/3416/large/viction.jpg?1724843158"
    }
  },
  {
    id: "world-chain",
    chain_identifier: 480,
    name: "World Chain",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22180/thumb/Worldcoin-logomark-light.png?1728377966",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22180/small/Worldcoin-logomark-light.png?1728377966",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22180/large/Worldcoin-logomark-light.png?1728377966"
    }
  },
  {
    id: "enuls",
    chain_identifier: 119,
    name: "ENULS",
    shortname: "",
    native_coin_id: "nuls",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/298/thumb/enuls.jpg?1724912998",
      small: "https://coin-images.coingecko.com/asset_platforms/images/298/small/enuls.jpg?1724912998",
      large: "https://coin-images.coingecko.com/asset_platforms/images/298/large/enuls.jpg?1724912998"
    }
  },
  {
    id: "platon-network",
    chain_identifier: 210425,
    name: "PlatON Network",
    shortname: "",
    native_coin_id: "platon-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/292/thumb/PlatON-Black.png?1724840056",
      small: "https://coin-images.coingecko.com/asset_platforms/images/292/small/PlatON-Black.png?1724840056",
      large: "https://coin-images.coingecko.com/asset_platforms/images/292/large/PlatON-Black.png?1724840056"
    }
  },
  {
    id: "moonbeam",
    chain_identifier: 1284,
    name: "Moonbeam",
    shortname: "",
    native_coin_id: "moonbeam",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/56/thumb/cropped-Moonbeam-Favicon-550px.png?1706606533",
      small: "https://coin-images.coingecko.com/asset_platforms/images/56/small/cropped-Moonbeam-Favicon-550px.png?1706606533",
      large: "https://coin-images.coingecko.com/asset_platforms/images/56/large/cropped-Moonbeam-Favicon-550px.png?1706606533"
    }
  },
  {
    id: "moonchain",
    chain_identifier: 18686,
    name: "Moonchain",
    shortname: "",
    native_coin_id: "mxc",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/299/thumb/moonchain.png?1724913384",
      small: "https://coin-images.coingecko.com/asset_platforms/images/299/small/moonchain.png?1724913384",
      large: "https://coin-images.coingecko.com/asset_platforms/images/299/large/moonchain.png?1724913384"
    }
  },
  {
    id: "lung-chain",
    chain_identifier: 78887,
    name: "Lung Chain",
    shortname: "",
    native_coin_id: "lunagens",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/300/thumb/lung.jpeg?1724913828",
      small: "https://coin-images.coingecko.com/asset_platforms/images/300/small/lung.jpeg?1724913828",
      large: "https://coin-images.coingecko.com/asset_platforms/images/300/large/lung.jpeg?1724913828"
    }
  },
  {
    id: "maxxchain",
    chain_identifier: 10201,
    name: "MaxxChain",
    shortname: "",
    native_coin_id: "power",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/301/thumb/maxxchain.png?1724913995",
      small: "https://coin-images.coingecko.com/asset_platforms/images/301/small/maxxchain.png?1724913995",
      large: "https://coin-images.coingecko.com/asset_platforms/images/301/large/maxxchain.png?1724913995"
    }
  },
  {
    id: "elysium",
    chain_identifier: 1339,
    name: "Elysium",
    shortname: "",
    native_coin_id: "lava",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/303/thumb/elysium.jpeg?1724915402",
      small: "https://coin-images.coingecko.com/asset_platforms/images/303/small/elysium.jpeg?1724915402",
      large: "https://coin-images.coingecko.com/asset_platforms/images/303/large/elysium.jpeg?1724915402"
    }
  },
  {
    id: "graphlinq-chain",
    chain_identifier: 614,
    name: "GraphLinq Chain",
    shortname: "",
    native_coin_id: "graphlinq-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/306/thumb/graphlinq.jpeg?1724916874",
      small: "https://coin-images.coingecko.com/asset_platforms/images/306/small/graphlinq.jpeg?1724916874",
      large: "https://coin-images.coingecko.com/asset_platforms/images/306/large/graphlinq.jpeg?1724916874"
    }
  },
  {
    id: "pulsechain",
    chain_identifier: 369,
    name: "Pulsechain",
    shortname: "",
    native_coin_id: "pulsechain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/130/thumb/pulse.jpeg?1706606525",
      small: "https://coin-images.coingecko.com/asset_platforms/images/130/small/pulse.jpeg?1706606525",
      large: "https://coin-images.coingecko.com/asset_platforms/images/130/large/pulse.jpeg?1706606525"
    }
  },
  {
    id: "multivac",
    chain_identifier: 62621,
    name: "MultiVAC",
    shortname: "",
    native_coin_id: "multivac",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/307/thumb/multivac.jpeg?1724917227",
      small: "https://coin-images.coingecko.com/asset_platforms/images/307/small/multivac.jpeg?1724917227",
      large: "https://coin-images.coingecko.com/asset_platforms/images/307/large/multivac.jpeg?1724917227"
    }
  },
  {
    id: "inevm",
    chain_identifier: 2525,
    name: "inEVM",
    shortname: "",
    native_coin_id: "injective-protocol",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/291/thumb/inevm.png?1724832860",
      small: "https://coin-images.coingecko.com/asset_platforms/images/291/small/inevm.png?1724832860",
      large: "https://coin-images.coingecko.com/asset_platforms/images/291/large/inevm.png?1724832860"
    }
  },
  {
    id: "larissa",
    chain_identifier: 9898,
    name: "Larissa",
    shortname: "",
    native_coin_id: "larissa-blockchain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/305/thumb/larissa.jpeg?1724916642",
      small: "https://coin-images.coingecko.com/asset_platforms/images/305/small/larissa.jpeg?1724916642",
      large: "https://coin-images.coingecko.com/asset_platforms/images/305/large/larissa.jpeg?1724916642"
    }
  },
  {
    id: "combo",
    chain_identifier: 9980,
    name: "Combo",
    shortname: "",
    native_coin_id: "wbnb",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/304/thumb/combo.jpeg?1724916064",
      small: "https://coin-images.coingecko.com/asset_platforms/images/304/small/combo.jpeg?1724916064",
      large: "https://coin-images.coingecko.com/asset_platforms/images/304/large/combo.jpeg?1724916064"
    }
  },
  {
    id: "redstone",
    chain_identifier: 690,
    name: "Redstone",
    shortname: "",
    native_coin_id: "weth",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/302/thumb/redstone.jpeg?1724914668",
      small: "https://coin-images.coingecko.com/asset_platforms/images/302/small/redstone.jpeg?1724914668",
      large: "https://coin-images.coingecko.com/asset_platforms/images/302/large/redstone.jpeg?1724914668"
    }
  },
  {
    id: "ethereum",
    chain_identifier: 1,
    name: "Ethereum",
    shortname: "Ethereum",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/279/thumb/ethereum.png?1706606803",
      small: "https://coin-images.coingecko.com/asset_platforms/images/279/small/ethereum.png?1706606803",
      large: "https://coin-images.coingecko.com/asset_platforms/images/279/large/ethereum.png?1706606803"
    }
  },
  {
    id: "dogechain",
    chain_identifier: 2000,
    name: "Dogechain",
    shortname: "",
    native_coin_id: "dogechain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/89/thumb/dogechain.jpeg?1706606542",
      small: "https://coin-images.coingecko.com/asset_platforms/images/89/small/dogechain.jpeg?1706606542",
      large: "https://coin-images.coingecko.com/asset_platforms/images/89/large/dogechain.jpeg?1706606542"
    }
  },
  {
    id: "zksync",
    chain_identifier: 324,
    name: "zkSync",
    shortname: "zkSync",
    native_coin_id: "ethereum",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/121/thumb/zksync.jpeg?1706606814",
      small: "https://coin-images.coingecko.com/asset_platforms/images/121/small/zksync.jpeg?1706606814",
      large: "https://coin-images.coingecko.com/asset_platforms/images/121/large/zksync.jpeg?1706606814"
    }
  },
  {
    id: "cronos-zkevm",
    chain_identifier: 388,
    name: "Cronos zkEVM",
    shortname: "",
    native_coin_id: "cronos-zkevm-cro",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/288/thumb/rsz_cronos_zkevm.jpg?1723777590",
      small: "https://coin-images.coingecko.com/asset_platforms/images/288/small/rsz_cronos_zkevm.jpg?1723777590",
      large: "https://coin-images.coingecko.com/asset_platforms/images/288/large/rsz_cronos_zkevm.jpg?1723777590"
    }
  },
  {
    id: "x-layer",
    chain_identifier: 196,
    name: "X Layer",
    shortname: "",
    native_coin_id: "okb",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/238/thumb/x_logo.jpeg?1713243242",
      small: "https://coin-images.coingecko.com/asset_platforms/images/238/small/x_logo.jpeg?1713243242",
      large: "https://coin-images.coingecko.com/asset_platforms/images/238/large/x_logo.jpeg?1713243242"
    }
  },
  {
    id: "sei-network",
    chain_identifier: null,
    name: "Sei Network",
    shortname: "",
    native_coin_id: "sei-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/148/thumb/Sei_Logo_-_Transparent.png?1706606762",
      small: "https://coin-images.coingecko.com/asset_platforms/images/148/small/Sei_Logo_-_Transparent.png?1706606762",
      large: "https://coin-images.coingecko.com/asset_platforms/images/148/large/Sei_Logo_-_Transparent.png?1706606762"
    }
  },
  {
    id: "axelar",
    chain_identifier: null,
    name: "Axelar",
    shortname: "",
    native_coin_id: "axelar",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22177/thumb/axelar.webp?1727842598",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22177/small/axelar.webp?1727842598",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22177/large/axelar.webp?1727842598"
    }
  },
  {
    id: "8bit-chain",
    chain_identifier: null,
    name: "8Bit Chain",
    shortname: "",
    native_coin_id: "8bit-chain",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22178/thumb/8bit.jpeg?1727855288",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22178/small/8bit.jpeg?1727855288",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22178/large/8bit.jpeg?1727855288"
    }
  },
  {
    id: "qubic",
    chain_identifier: null,
    name: "Qubic",
    shortname: "",
    native_coin_id: "qubic-network",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22175/thumb/qubic.jpg?1727689209",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22175/small/qubic.jpg?1727689209",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22175/large/qubic.jpg?1727689209"
    }
  },
  {
    id: "chia",
    chain_identifier: null,
    name: "Chia",
    shortname: "",
    native_coin_id: "chia",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/22179/thumb/chia.jpg?1727921871",
      small: "https://coin-images.coingecko.com/asset_platforms/images/22179/small/chia.jpg?1727921871",
      large: "https://coin-images.coingecko.com/asset_platforms/images/22179/large/chia.jpg?1727921871"
    }
  },
  {
    id: "klay-token",
    chain_identifier: 8217,
    name: "Kaia",
    shortname: "",
    native_coin_id: "kaia",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/9672/thumb/klatyn.png?1706606810",
      small: "https://coin-images.coingecko.com/asset_platforms/images/9672/small/klatyn.png?1706606810",
      large: "https://coin-images.coingecko.com/asset_platforms/images/9672/large/klatyn.png?1706606810"
    }
  },
  {
    id: "sei-v2",
    chain_identifier: 1329,
    name: "Sei V2",
    shortname: "",
    native_coin_id: "wrapped-sei",
    image: {
      thumb: "https://coin-images.coingecko.com/asset_platforms/images/273/thumb/sei.png?1717643675",
      small: "https://coin-images.coingecko.com/asset_platforms/images/273/small/sei.png?1717643675",
      large: "https://coin-images.coingecko.com/asset_platforms/images/273/large/sei.png?1717643675"
    }
  }
];

export const getChainById = (chainId: string): BullFolio.Chain | null => {
  const chain = chains.find((x) => x.id === chainId);
  return chain;
}