import { useEffect, useState } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Flex, FormLabel, Icon, Image, Input, Radio, RadioGroup, Select, SimpleGrid, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useCoins } from "contexts/CoinsContext";
import { useUser } from "contexts/UserContext";
import TopCoinsTable, { RowObjCoins } from "./components/TopCoinsTable";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import numeral from "numeral";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from "components/icons/IconBox";
import { MdBarChart } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";
import { getCurrencySymbol } from "helpers/formatters";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { CATEGORIES } from "variables/categories";
import { FaCircle } from "react-icons/fa";
import { useWatchlists } from "contexts/WatchlistsContext";
import { IoMdClose } from "react-icons/io";


export default function TopCoins() {
	// Chakra Color Mode
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const [coins, setCoins] = useState<BullFolio.CoinData.Data[] | null>(null);
	const [queryResult, setQueryResult] = useState<BullFolio.StratCoinConditon[] | null>(null);
	const [watchlists, setWatchlists] = useState<BullFolio.Watchlist[] | null>(null);
	const [watchlistInputVal, setWatchlistInputVal] = useState<string>("all");
	const [loadingText, setLoadingText] = useState<string>("Loading coins...");
	const [isLoading, setIsLoading] = useState(false);
	const [strategies, setStrategies] = useState<BullFolio.Strategy[] | null>(null);
	const [marketData, setMarketData] = useState<BullFolio.MarketData | null>(null);
	const [categories, setCategories] = useState<string[]>([]);
	const [tempCategory, setTempCategory] = useState<string>();
	const [sort, setSort] = useState<BullFolio.QueryCoins.Sort>({direction: "desc", value: "data.market_cap"});

	// query inputs
	const [strategyCondition, setStrategyCondition] = useState<BullFolio.QueryCoins.StrategyCondition>({});
	const [page, setPage] = useState<string>("1");
	const [size, setSize] = useState<string>("100");

	const { getAllCoins, getMarketData, queryCoins } = useCoins();
	const { user, userData } = useUser();
	const { getMyStrategies } = useStrategies();
	const { getAllWatchlists } = useWatchlists();

	const query = async () => {
		setCoins([]);
		setIsLoading(true);
		setLoadingText("Querying tokens...");
		const strategiesIds = Object.keys(strategyCondition);
		const _strategies: BullFolio.Strategy[] = [];
		strategies.forEach((x) => {
			if(strategiesIds.includes(x.id)) {
				_strategies.push(x);
			};
		});
		let _tokenIds: string[] = [];
		if(watchlistInputVal === "all") {
			_tokenIds = userData.allIds.coins.map(x => x.id)
		} else if(watchlistInputVal === "combined") {
			watchlists.forEach((watchlist) => {
				watchlist.tokens.forEach((token) => {
					if(!_tokenIds.includes(token.id)) {
						_tokenIds.push(token.id);
					}
				});
			})
		} else {
			const _watchlist = watchlists.find((x) => x.id === watchlistInputVal);
			_tokenIds = _watchlist.tokens.map(x => x.id);
		}
		console.log(strategyCondition);
		console.log(_strategies);
		console.log(_tokenIds);
		let _categories: string[] | null = null;
		if (categories.length>0) {
			_categories = categories;
		}
		const res = await queryCoins(Number(page), Number(size), _strategies, strategyCondition, _tokenIds, sort, _categories);
		console.log(res);
		setCoins(res.map((x) => x.data));
		setQueryResult(res.map((x) => x.conditionMet));
		setIsLoading(false);
	};

	const handleStrategyConditionInput = (val: string, strategyId: string) => {
		if(val === "none") {
			const newCondition = {...strategyCondition};
			delete newCondition[strategyId];
			setStrategyCondition(newCondition);
		}else{
			setStrategyCondition({...strategyCondition, [strategyId]: val as BullFolio.QueryCoins.ConditionType})
		}
	}

	const createTableData = (): RowObjCoins[] => {
		if(coins) {
			return coins.map((coin, index) => {
				return ({
					id: coin.id?.toString(),
					name: [coin.name, coin.symbol, coin.image, coin.id],
					rank: coin.market_cap_rank?.toString(),
					price: `${getCurrencySymbol(userData.baseCurrency)}${numeral(coin.current_price).format(`0,0.00`)}`,
					changeHour: coin.price_change_percentage_1h_in_currency?.toFixed(1) || "",
					changeDay: coin.price_change_percentage_24h?.toFixed(1) || "",
					changeWeek: coin.price_change_percentage_7d_in_currency?.toFixed(1) || "",
					marketCap: `${getCurrencySymbol(userData.baseCurrency)}${numeral(coin.market_cap).format(`0,0.00`)}`,
					strategyData: queryResult ? queryResult[index] : {},
				});
			});
		}
	};

	const loadNewCoins = async(page: number) => {
		if(coins.length >= page * 100) {
			console.log("all tokens present")
		}else{
			console.log("loading ", page);
			const res = await getAllCoins(page);
			const prev = [...coins, ...res.coins].sort((a, b) => a.market_cap_rank - b.market_cap_rank);
			setCoins(prev);
			console.log("set");
			console.log(prev);
		}
	}

	const rightStrategies = () => {
		if(strategies && strategyCondition) {
			const strategiesIds = Object.keys(strategyCondition);
			const strategiesToReturn: BullFolio.Strategy[] = [];
			strategies.forEach((x) => {
				if(strategiesIds.includes(x.id)) {
					strategiesToReturn.push(x);
				};
			});
			return strategiesToReturn;
		}else{
			return [];
		}
	};

	const getSortValue = () => {
		return `${sort.direction}:${sort.value}`;
	}

	const handleChangeSort = (s: string) => {
		const direction = s.split(":")[0];
		const value = s.split(":")[1];
		setSort({
			direction: direction as BullFolio.QueryCoins.SortDir,
			value: value as BullFolio.QueryCoins.SortVal
		})
	}

	const updateCategories = () => {
		if (tempCategory === "all") setCategories([]);
		else if (!categories.includes(tempCategory)) {
			categories.push(tempCategory);
		}
	}

	const removeCategory = (c: string) => {
		const _categories = categories.filter(item => item !== c);
		setCategories(_categories);
	}

	useEffect(() => {
		if(user && userData) {
			(async () => {
				const res = await getAllCoins(1);
				setCoins(res.coins);
				setLoadingText("Loading watchlists...");
				const _watchlists = await getAllWatchlists();
				setWatchlists(_watchlists);
				setLoadingText("Loading strategies...");
				const _strategies = await getMyStrategies();
				setStrategies(_strategies);
				setLoadingText("Loading market data...");
				const _marketData = await getMarketData();
				setMarketData(_marketData);
				console.log("market data: ", _marketData);
			})();
		}
	}, [user, userData]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{coins && strategies ? (
				<Box>
					{marketData ? (
						<SimpleGrid columns={4} gap='20px' overflow="scroll" pb="8" sx={
							{ 
						 '::-webkit-scrollbar':{
										display:'none'
								}
						 }
					 }>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
									/>
								}
								name='Total Market Cap'
								value={numeral(marketData.curr.total_market_cap.usd).format("$0,0")}
							/>
							<MiniStatistics
								startContent={
									<Image
										w='56px'
										h='56px'
										src="https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400"
									/>
								}
								endContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={
											<Text fontWeight={"900"} color={brandColor}>{(marketData.curr.market_cap_percentage.btc - marketData?.prev?.market_cap_percentage?.btc || 0) >= 0 ? "+" : null}{(marketData.curr.market_cap_percentage.btc - marketData?.prev?.market_cap_percentage?.btc || 0).toFixed(1)}%</Text>
										}
									/>
								}
								name='Bitcoin Dominance'
								value={`${marketData.curr.market_cap_percentage.btc.toFixed(2)}%`}
							/>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
									/>
								}
								name='Volume 24H'
								value={numeral(marketData.curr.total_volume.usd).format("$0,0")}
							/>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
									/>
								}
								name='Total Coins'
								value={marketData.curr.active_cryptocurrencies}
							/>
						</SimpleGrid>
					):null}
					<Card>
						<Accordion allowToggle>
							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex='1' textAlign='left'>
											<Text fontWeight={"extrabold"} fontSize={"lg"}>Filters & Sort</Text>
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4} pt={2}>
									<Box>
										<Text fontWeight={"extrabold"}>Strategies:</Text>
										{strategies?.map((strategy, index) => {
											return(
												<Flex justifyContent={"space-between"} key={index}>
													<Text width={"20vw"}>{strategy.name}:</Text>
													<RadioGroup
														value={strategyCondition[strategy.id] === undefined ? "none" : strategyCondition[strategy.id]}
														onChange={(val) => handleStrategyConditionInput(val, strategy.id)}
													>
														<Stack spacing={"10"} direction="row">
															<Radio value={"buy"}>{strategy.events.find((x) => x.type === "buy").name} (BUY)</Radio>
															<Radio value={"sell"}>{strategy.events.find((x) => x.type === "sell").name} (SELL)</Radio>
															<Radio value={"any"}>{strategy.events.find((x) => x.type === "buy").name} or {strategy.events.find((x) => x.type === "sell").name}</Radio>
															<Radio value={"none"}>Don't check</Radio>
														</Stack>
													</RadioGroup>
												</Flex>
											)
										})}
										{strategies?.length === 0 ? (
											<Text>No strategies created.</Text>
										):null}
									</Box>
									<Box mt="2">
										<Text fontWeight={"extrabold"}>Watchlists:</Text>
										<RadioGroup
											value={watchlistInputVal}
											onChange={(val) => setWatchlistInputVal(val)}
										>
											<Stack spacing={"10"} direction="row">
												<Radio value={"all"}>All Tokens</Radio>
												<Radio value={"combined"}>Combined Watchlists</Radio>
												{watchlists?.map((watchlist, index) => {
													return(
														<Radio value={watchlist.id} key={index}>{watchlist.name}</Radio>
													)
												})}											
											</Stack>
										</RadioGroup>
										{watchlists?.length === 0 ? (
											<Text>No watchlists created.</Text>
										):null}
									</Box>
									<Box mt="2">
										<Text fontWeight={"extrabold"}>Filters:</Text>
										<Box>
											<Flex>
												<Text>Categories:</Text>
												<Flex mb="4" px="6">
													{categories.map((c, index) => {
														return(
															<Flex key={index}>
																<p>{c}</p>
																<Button ml="2" onClick={() => removeCategory(c)} size="xs">
																	<IoMdClose />
																</Button>
															</Flex>
														)
													})}
													{categories.length === 0 ? (
														<Text>All</Text>
													):null}
												</Flex>
											</Flex>
											<Flex>
												<Select width={"25vw"} mr="3" onChange={(e) => setTempCategory(e.target.value)}>
													<option value="all">All</option>
													{CATEGORIES.map((x, i) => {
														return(
															<option key={i} value={x}>{x}</option>
														)
													})}
												</Select>
												<Button onClick={() => updateCategories()}>Add</Button>
											</Flex>
										</Box>
									</Box>
									<Box mt="2">
										<Text fontWeight={"extrabold"}>Sort:</Text>
										<Select value={getSortValue()} onChange={(e) => handleChangeSort(e.target.value)}>
											<option value={"desc:data.market_cap"}>By Market Cap (high to low)</option>
											<option value={"asc:data.market_cap"}>By Market Cap (low to high)</option>
											<option value={"desc:data.current_price"}>Price (high to low)</option>
											<option value={"asc:data.current_price"}>Price (low to high)</option>
											<option value={"desc:data.price_change_percentage_1h_in_currency"}>1h Price Change (high to low)</option>
											<option value={"asc:data.price_change_percentage_1h_in_currency"}>1h Price Change (low to high)</option>
											<option value={"desc:data.price_change_percentage_24h"}>24h Price Change (high to low)</option>
											<option value={"asc:data.price_change_percentage_24h"}>24h Price Change (low to high)</option>
											<option value={"desc:data.price_change_percentage_7d_in_currency"}>7d Price Change (high to low)</option>
											<option value={"asc:data.price_change_percentage_7d_in_currency"}>7d Price Change (low to high)</option>
											<option value={"desc:data.fully_diluted_valuation"}>Fully Diluted Valuation (high to low)</option>
											<option value={"asc:data.fully_diluted_valuation"}>Fully Diluted Valuation (low to high)</option>
											
											<option value={"desc:levels.1h.s"}>Furthest from 1H support (high to low)</option>
											<option value={"asc:levels.1h.s"}>Closest to 1H support (low to high)</option>
											<option value={"desc:levels.1h.r"}>Furthest from 1H resistance (high to low)</option>
											<option value={"asc:levels.1h.r"}>Closest to 1H resistance (low to high)</option>

											<option value={"desc:levels.4h.s"}>Furthest from 4H support (high to low)</option>
											<option value={"asc:levels.4h.s"}>Closest to 4H support (low to high)</option>
											<option value={"desc:levels.4h.r"}>Furthest from 4H resistance (high to low)</option>
											<option value={"asc:levels.4h.r"}>Closest to 4H resistance (low to high)</option>

											<option value={"desc:levels.8h.s"}>Furthest from 8H support (high to low)</option>
											<option value={"asc:levels.8h.s"}>Closest to 8H support (low to high)</option>
											<option value={"desc:levels.8h.r"}>Furthest from 8H resistance (high to low)</option>
											<option value={"asc:levels.8h.r"}>Closest to 8H resistance (low to high)</option>

											<option value={"desc:levels.1d.s"}>Furthest from 1D support (high to low)</option>
											<option value={"asc:levels.1d.s"}>Closest to 1D support (low to high)</option>
											<option value={"desc:levels.1d.r"}>Furthest from 1D resistance (high to low)</option>
											<option value={"asc:levels.1d.r"}>Closest to 1D resistance (low to high)</option>

											<option value={"desc:levels.2d.s"}>Furthest from 2D support (high to low)</option>
											<option value={"asc:levels.2d.s"}>Closest to 2D support (low to high)</option>
											<option value={"desc:levels.2d.r"}>Furthest from 2D resistance (high to low)</option>
											<option value={"asc:levels.2d.r"}>Closest to 2D resistance (low to high)</option>

											<option value={"desc:levels.5d.s"}>Furthest from 5D support (high to low)</option>
											<option value={"asc:levels.5d.s"}>Closest to 5D support (low to high)</option>
											<option value={"desc:levels.5d.r"}>Furthest from 5D resistance (high to low)</option>
											<option value={"asc:levels.5d.r"}>Closest to 5D resistance (low to high)</option>

											<option value={"desc:levels.1w.s"}>Furthest from 1W support (high to low)</option>
											<option value={"asc:levels.1w.s"}>Closest to 1W support (low to high)</option>
											<option value={"desc:levels.1w.r"}>Furthest from 1W resistance (high to low)</option>
											<option value={"asc:levels.1w.r"}>Closest to 1W resistance (low to high)</option>
										</Select>
									</Box>
									<Box mt="2">
										<Text fontWeight={"extrabold"}>Other:</Text>
										<Flex>
											<Box mr="3">
												<FormLabel>Query Size</FormLabel>
												<Input
													value={size}
													onChange={(e: any) => setSize(e.target.value)}
													placeholder="Size"
												/>
											</Box>
											<Box>
												<FormLabel>Query Page</FormLabel>
												<Input
													value={page}
													onChange={(e: any) => setPage(e.target.value)}
													placeholder="Page"
												/>
											</Box>
										</Flex>
									</Box>
									<Flex mt="4">
										<Button variant={"darkBrand"} mr="3" onClick={() => query()}>Apply</Button>
										<Button>Reset to default</Button>
									</Flex>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Card>
					<Card mt="6">
						{coins.length>0 && !isLoading ? (
							<TopCoinsTable
								tableData={createTableData()}
								loadNewCoins={loadNewCoins}
								strategies={rightStrategies()}
							/>
						):null}
						{coins.length === 0 && !isLoading ? (
							<Text align={"center"} fontSize={"2xl"} fontWeight="bold">No coins matched your query.</Text>
						):null}
						{isLoading ? (
							<Loading text={loadingText} />
						):null}
					</Card>
				</Box>
			) : (
				<Loading text={loadingText} />
			)}

			{rightStrategies().length>0 ? (
				<Card mt="6">
					<Text fontSize={"3xl"} fontWeight="extrabold">Legend:</Text>
					<Flex justifyContent={"space-between"} w="25%" mt="3">
						<Flex>
							<Text color={"green.500"} textColor="green.500" fontSize={"4xl"}>
								<FaCircle />
							</Text>
							<Text ml="3" fontSize={"xl"} my="1">Buy</Text>
						</Flex>
						<Flex>
							<Text color={"red.500"} textColor="red.500" fontSize={"4xl"}>
								<FaCircle />
							</Text>
							<Text ml="3" fontSize={"xl"} my="1">Sell</Text>
						</Flex>
						<Flex>
							<Text color={"gray.400"} textColor="gray.400" fontSize={"4xl"}>
								<FaCircle />
							</Text>
							<Text ml="3" fontSize={"xl"} my="1">Nothing</Text>
						</Flex>
					</Flex>
				</Card>
			):null}
		</Box>
	);
}
