export const links = {
  home: "/admin/dashboards/default",
  signIn: "/auth/sign-in/default",
  signUp: "/auth/sign-up/default",
  profileOverview: "/admin/main/profile/overview",
  profileSettings: "/admin/main/profile/settings",
  updatePassword: "/auth/update-password/default",
  verifyEmail: "/auth/verify-email/default",
  setAddress: "/auth/",
  coinPage: "/admin/dashboards/overview",
  topNfts: "/admin/dashboards/nfts",
  nftPage: "/admin/dashboards/nfts/overview",
  portfolioOverview: "/admin/portfolio/overview",
  balances: "/admin/portfolio/balances",
  transactions: "/admin/portfolio/transactions",
  nfts: "/admin/portfolio/nfts",
  myAlerts: "/admin/alerts/my",
  createAlert: "/admin/alerts/create",
  createStrategy: "/admin/strategy/create",
  strategyMarketplace: "/admin/strategy/marketplace",
  myStrategies: "/admin/strategy/my",
  strategyPage: "/admin/strategy/page",
  bundles: "/admin/portfolio/bundles",
  coingecko: "https://coingecko.com",
  watchlistPage: "/admin/watchlist/watchlist",
  allWatchlists: "/admin/watchlist/all",
}