import { Text } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";


const AdminPanel = (props: { strategy: BullFolio.Strategy }) => {

  const { strategy } = props;

  return(
    <>
      <Card>
        <Text>Admin</Text>
      </Card>
    </>
  )
};

export default AdminPanel;