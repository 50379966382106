import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Center, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import { HSeparator } from "components/separator/Separator";
import { useUser } from "contexts/UserContext";
import { formatUrlNames, getCurrencySymbol, isURL } from "helpers/formatters";
import numeral from "numeral";
import { useEffect, useState } from "react";
import MarketsTable, { RowObjMarkets } from "./MarketsTable";
import Card from "components/card/Card";
import PlatformsTable, { RowObjPlatforms } from "./PlatformsTable";
import { getImageByExchangeId } from "helpers/exchanges";
import { getChainById } from "helpers/chains";

const widthSizes = {
  sm: '100%',
  md: "100%",
  lg: "60%",
};

const DetailsTable = (props: { coin: BullFolio.CoinData, currencySymbol: string }) => {
  const { coin, currencySymbol } = props;
  const textColor = useColorModeValue("navy.700", "white");

  const displayLinks = () => {
    if (coin?.metadata?.links) {
      let links: {name: string, url: string}[] = [];
      Object.entries(coin.metadata.links).forEach(([key, value]) => {
        if (isURL(value)) {
          links.push({name: key, url: value})
        }
      });
      return links.map((x, i) => {
        return(
          <Badge key={i} size={"xl"} variant="outline" mr="2" fontSize={"0.9rem"} mb="2"><Link target="_blank" href={x.url}>{formatUrlNames(x.name)}</Link></Badge>
        )
      });
    } else {
      return (
        <Text>No Links</Text>
      )
    }
  }

  const createMarketsTableData = (): RowObjMarkets[] => {
    if (!coin.metadata) return [];
    if (!coin.metadata.tickers) return [];

    return coin.metadata.tickers.map((market) => {
      return {
        trustScore: market.trust_score,
        url: market.trade_url,
        exchange: [market.market.name, getImageByExchangeId(market.market.identifier)],
        pair: [[market.base, market.coin_id], [market.target, market.target_coin_id]]
      }
    });
  }

  const createPlatformsTableData = (): RowObjPlatforms[] => {
    console.log(coin?.metadata?.detail_platforms);
    if (!coin.metadata) return [];
    if (!coin.metadata.detail_platforms) return [];

    const res: RowObjPlatforms[] = [];
    const data = coin.metadata.detail_platforms;
    for (const chainId in data) {
      if (data[chainId]) {
        const chainData = getChainById(chainId);
        console.log(data[chainId]);
        res.push({
          chain: [chainData.name, chainData.image.thumb],
          address: data[chainId]?.contract_address,
          decimals: data[chainId]?.decimal_place?.toString(),
        });
      }
    }

    return res;
  }

  return(
    <Box px="2">
      <Box mb="6" px="3">
        <Text color={textColor} fontSize='2xl' fontWeight='800' mr="3.5" mb="2.5">
          {coin.data.name} Categories
        </Text>
        {coin?.metadata?.categories?.map((category, i) => {
          return(
            <Badge key={i} size={"xl"} colorScheme="brand" mr="2" fontSize={"0.9rem"} mb="2">{category}</Badge>
          )
        })}
      </Box>

      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Text fontWeight={"bold"} fontSize={"lg"}>Links</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {displayLinks()}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Text fontWeight={"bold"} fontSize={"lg"}>Markets</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Card>
              {createMarketsTableData().length > 0 ? (
                <MarketsTable
                  tableData={createMarketsTableData()}
                />
              ):(
                <Center>
                  <Text>No markets found.</Text>
                </Center>
              )}
            </Card>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Text fontWeight={"bold"} fontSize={"lg"}>Platforms</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
          <Card>
              {createPlatformsTableData().length > 0 ? (
                <PlatformsTable
                  tableData={createPlatformsTableData()}
                />
              ):(
                <Center>
                  <Text>No platforms found.</Text>
                </Center>
              )}
            </Card>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Text fontWeight={"bold"} fontSize={"lg"}>Details</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} pt={2}>
            <Box width={widthSizes}>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Market Cap</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.market_cap).format(`0,00.00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Fully Diluted Valuation</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral((coin.data.max_supply || coin.data.total_supply) * coin.data.current_price).format("0,00.00")}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Market Cap / FDV</Text>
                  <Text fontSize='lg' fontWeight='500'>{(coin.data.market_cap / ((coin.data.max_supply || coin.data.total_supply) * coin.data.current_price)).toFixed(3)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Total Volume</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.total_volume).format(`0,00.00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Current Price</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.current_price).format(`0,00.00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">24H High</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.high_24h).format(`0,00.00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">24H Low</Text>
                  <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.low_24h).format(`0,00.00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Flex>
                    <Text color={textColor} fontSize='lg' fontWeight='600' mr="2">All Time High</Text>
                    <Text color={textColor} fontSize='lg' fontWeight='500' mr="6">({new Date(coin.data.ath_date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })})</Text>
                  </Flex>
                  <Box>
                    <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.ath).format(`0,00.00`)}</Text>
                    <Text fontSize='lg' fontWeight='500' color={coin.data.ath_change_percentage>=0 ? "green.500" : "red.500"}>{coin.data.ath_change_percentage.toFixed(2)}%</Text>
                  </Box>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Flex>
                    <Text color={textColor} fontSize='lg' fontWeight='600' mr="2">All Time Low</Text>
                    <Text color={textColor} fontSize='lg' fontWeight='500' mr="6">({new Date(coin.data.atl_date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })})</Text>
                  </Flex>
                  <Box>
                    <Text fontSize='lg' fontWeight='500'>{currencySymbol} {numeral(coin.data.atl).format(`0,00.00`)}</Text>
                    <Text fontSize='lg' fontWeight='500' color={coin.data.atl_change_percentage>=0 ? "green.500" : "red.500"}>{coin.data.atl_change_percentage.toFixed(2)}%</Text>
                  </Box>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Circulating Supply</Text>
                  <Text fontSize='lg' fontWeight='500'>{numeral(coin.data.circulating_supply).format(`0,00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Total Supply</Text>
                  <Text fontSize='lg' fontWeight='500'>{numeral(coin.data.total_supply).format(`0,00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Max Supply</Text>
                  <Text fontSize='lg' fontWeight='500'>{numeral(coin.data.max_supply).format(`0,00`)}</Text>
                </Flex>
              </Box>
              <Box mb="1">
                <Flex justifyContent={"space-between"}>
                  <Text color={textColor} fontSize='lg' fontWeight='600' mr="6">Market Cap Rank</Text>
                  <Text fontSize='lg' fontWeight='500'>#{coin.data.market_cap_rank}</Text>
                </Flex>
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default DetailsTable;