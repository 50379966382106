// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { BullFolio } from 'bullfolio-types';
// Custom components
import Card from 'components/card/Card';
import { useStrategies } from 'contexts/StrategiesContext';
import { createOpenSeaLink } from 'helpers/formatters';
import { links } from 'helpers/links';
// Assets
import { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { MdVerified } from 'react-icons/md';

export default function StrategyBox(props: {
	strategy: BullFolio.Strategy
}) {
	const { strategy } = props;
	const [ like, setLike ] = useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const openseaLink = createOpenSeaLink("", "", 0);
	const ethColor = useColorModeValue('gray.700', 'white');


	return (
		<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					{/*<Image
						src={strategy.image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
	/>*/}
					{true ? (
						<Button
							position='absolute'
							bg='white'
							_hover={{ bg: 'whiteAlpha.900' }}
							_active={{ bg: 'white' }}
							_focus={{ bg: 'white' }}
							p='0px !important'
							top='14px'
							right='14px'
							borderRadius='50%'
							minW='36px'
							h='36px'
						>
							<Icon
								transition='0.2s linear'
								w='20px'
								h='20px'
								as={MdVerified}
								color='brand.500'
							/>
						</Button>
					):null}
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{strategy.name}
							</Text>
							<Text
								noOfLines={3}
								color='secondaryGray.600'
								fontSize={{
									base: 'md'
								}}
								fontWeight='400'
								me='14px'>
								{strategy.description}
							</Text>
						</Flex>
						<AvatarGroup
							max={3}
							color={textColorBid}
							size='sm'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							fontSize='12px'>
							{[].map((avt, key) => <Avatar key={key} src={avt} />)}
						</AvatarGroup>
					</Flex>
					<Flex
						justify='space-between'
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='25px'
						width={"100%"}
					>
						<Link
							href={`#${links.strategyPage}?id=${strategy.id}`}
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							width="100%">
							<Button
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'
								width={"100%"}	
							>
								View
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
