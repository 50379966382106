import { Accordion, AccordionButton, AccordionItem, Badge, Button, Text, useColorModeValue, Box, AccordionIcon, AccordionPanel, Flex } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import { useAlerts } from "contexts/AlertsContext";
import { useCoins } from "contexts/CoinsContext";

const AlertBox = (props: { alert: BullFolio.Alert }) => {
  const { alert } = props;

  const textColor = useColorModeValue("navy.700", "white");
	const secondaryText = useColorModeValue('gray.700', 'white');
  const textDetailsFontSize = "sm";

  const { deleteAlert } = useAlerts();

  return(
    <Card>
      <Text textColor={textColor} fontWeight="extrabold" fontSize={"xl"}>{alert.name}</Text>
      <Text textColor={secondaryText} mb="3">{alert.description}</Text>
      <Flex>
        <Badge variant={"outline"} width="min-content" mb="4">{alert.recurring ? "Recurring" : "One-Time"}</Badge>
        {alert.condition.type === "strategy_event" ? (
          <Badge variant={"outline"} width="min-content" mb="4" ml="2">Strategy Event</Badge>
        ):null}
      </Flex>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                Details
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontWeight={"extrabold"}>
              Tokens
            </Text>
            <Box>
              {alert.tokens.type === "top" ? (
                <Text fontSize={textDetailsFontSize}>
                  Top {alert.tokens.top} Tokens.
                </Text>
              ):(
                alert.tokens.type === "array" ? (
                  <Text fontSize={textDetailsFontSize}>
                    {alert.tokens.array.map(tokenId => {
                      return(
                        <span>{tokenId} </span>
                      )
                    })}
                  </Text>
                ):(
                  <Flex justifyContent={"space-between"}>
                    <Box>
                      <Text fontSize={textDetailsFontSize}>Key</Text>
                      <Text fontSize={textDetailsFontSize}>{alert.tokens.value.key}</Text>
                    </Box>
                    <Box>
                      <Text fontSize={textDetailsFontSize}>Condition</Text>
                      <Text>{alert.tokens.value.condition}</Text>
                    </Box>
                    <Box>
                      <Text fontSize={textDetailsFontSize}>Value</Text>
                      <Text fontSize={textDetailsFontSize}>{alert.tokens.value.value}</Text>
                    </Box>
                  </Flex>
                )
              )}
            </Box>
            <Text fontWeight={"extrabold"} mt="2">
              Condition
            </Text>
            <Box>
              {alert.condition.type === "value_reached" ? (
                <Flex justifyContent={"space-between"}>
                  <Box>
                    <Text fontSize={textDetailsFontSize}>When</Text>
                    <Text fontSize={textDetailsFontSize}>Price</Text>
                    <Text fontSize={textDetailsFontSize}>On Timeframe</Text>
                  </Box>
                  <Box>
                    <Text fontSize={textDetailsFontSize}>Reaches</Text>
                    <Text fontSize={textDetailsFontSize}>{alert.condition.value_reached.against === "usd" ? "$" : "₿"} {alert.condition.value_reached.value}</Text>
                    <Text fontSize={textDetailsFontSize}>{alert.condition.value_reached.timeframe?.toUpperCase()} (close)</Text>
                  </Box>
                </Flex>
              ):(
                <Box>
                  <Flex justifyContent={"space-between"}>
                    <Text fontSize={textDetailsFontSize}><b>Strategy</b></Text>
                    <Text fontSize={textDetailsFontSize}>{alert.condition.strategy_event.strategyName}</Text>
                  </Flex>
                  <Flex justifyContent={"space-between"}>
                    <Text fontSize={textDetailsFontSize}><b>Events</b></Text>
                    <Text fontSize={textDetailsFontSize}>{alert.condition.strategy_event.events.join(", ")}</Text>
                  </Flex>
                </Box>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Button variant="brand" w="100%" onClick={() => deleteAlert(alert.id)} mt="6">
        Delete Alert
      </Button>
    </Card>
  );
};

export default AlertBox;