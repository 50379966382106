import { Box, Switch, Button, Flex, FormLabel, Select, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Text, Badge, Tooltip } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import { dissolveKey, generateHexColorFromString, getHoursFromTimeframe, getStartTimestampAndLastUpdated, getTimestamp, isOscillator, replaceNulls } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { handleCalculateIndicator } from "helpers/indicators";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";


export const BacktestContent = (props: { strategyData: BullFolio.Strategy, coins: BullFolio.CoinData.Data[], isModal: boolean }) => {
  const { strategyData, coins, isModal } = props;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const height = 400;
  const width = window.innerWidth * 0.75;

  const [startingBalance, setStartingBalance] = useState("1");
  const [positionSize, setPositionSize] = useState("0");
  const [span, setSpan] = useState("200");
  const [coinId, setCoinId] = useState("bitcoin");
  const [backTest, setBackTest] = useState<BullFolio.BackTest>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chartSeries, setChartSeries] = useState<any[]>([{
    data: []
  }]);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      type: "candlestick",
      height: 450,
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
        type: "xy"
      }
    },
    title: {
      text: 'Token Price Chart',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    },
  });
  const [oscillatorChartOptions, setOscillatorChartOptions] = useState<ApexOptions[]>([]);
  const [oscillatorChartSeries, setOscillatorChartSeries] = useState<any[][]>([]);

  const { backTestStrategy } = useStrategies();

  const handleBackTest = async () => {
    setIsLoading(true);
    console.log("getting")
    const res = await backTestStrategy(
      strategyData,
      coinId,
      Number(positionSize),
      Number(span),
      Number(startingBalance)
    );
    console.log(res);
    setBackTest(res);
    setIsLoading(false);
  };

  const calculateIndicators = (indicator: string) => {
    const dissolvedKey = dissolveKey(indicator);
    let _prices = backTest.prices;
    const indicatorData = replaceNulls(handleCalculateIndicator(dissolvedKey.type, _prices, dissolvedKey.settings), _prices[0]);
    const lenDif = _prices.length - indicatorData.length;
    const result:{x: Date, y: number}[] = [];
  
    _prices.forEach((price, i) => {
      const timestamp = getTimestamp(
        getStartTimestampAndLastUpdated(strategyData.timeframe, _prices.length).timestampInSeconds,
        getHoursFromTimeframe(strategyData.timeframe),
        i+1,
      );
      if(i >= lenDif+1) {
        const indicatorsIndex = i - lenDif;
        result.push({
          x: new Date(timestamp),
          y: indicatorData[indicatorsIndex]
        })
      }else{
        result.push({
          x: new Date(timestamp),
          y: indicatorData[0]
        })
      }
    });
  
    return [{
      data: result,
      type: "line",
      color: generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`),
      name: `${dissolvedKey.type.toUpperCase()} - ${dissolvedKey.settings}`,
    }];
  }
  
  const createOscillatorChart = (indicator: string) => {
    const dissolvedKey = dissolveKey(indicator);
    const chartData = calculateIndicators(indicator);
  
    if (indicator.includes("rsi_ma")) {
      // calculate rsi
      chartData.push(
        calculateIndicators(`rsi:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
      );
    }
  
    if(indicator.includes("rsi:")) {
      //calculate rsi ma
      chartData.push(
        calculateIndicators(`rsi_ma:${[...dissolvedKey.settings, 14].join(",")}:${dissolvedKey.timeframe}`)[0]
      );
    }
  
    if (indicator.includes("bbwp")) {
      if (indicator.includes("ma")) {
        // calculate bbwp
        chartData.push(
          calculateIndicators(`bbwp:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      } else {
        //calculate bbwp ma
        chartData.push(
          calculateIndicators(`bbwp_ma:${[...dissolvedKey.settings, 5].join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }
    }
  
    if (indicator.includes("stoch")) {
      if (indicator.includes("_k")) {
        // calculate d
        chartData.push(
          calculateIndicators(`stoch_rsi_d:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }else{
        // calculate k
        chartData.push(
          calculateIndicators(`stoch_rsi_k:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }
    }
  
    const chartOptions: ApexOptions = {
      chart: {
        type: "line",
        height: 150,
      },
      title: {
        text: indicator,
        align: 'left'
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        tooltip: {
          enabled: true
        }
      },
      colors: [generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`)],
      stroke: {
        width: 1.25
      },
      grid: {
        
      }
    };
    console.log("oscillator chart data");
    console.log(chartData);
    return {
      chartData,
      chartOptions,
    }
  }


  useEffect(() => {
    if(backTest) {
      console.log("Backtest data", backTest);
      let _prices = backTest.prices;
      const result:{x: Date, y: number[]}[] = [];

      _prices.forEach((x, index) => {
        if(index !== 0) {
          const openPrice = _prices[index-1];
          const closePrice = x;
          const timestamp = getTimestamp(
            getStartTimestampAndLastUpdated(strategyData.timeframe, _prices.length).timestampInSeconds,
            getHoursFromTimeframe(strategyData.timeframe),
            index,
          );
          
          result.push({
            y: [openPrice, openPrice, closePrice, closePrice],
            x: new Date(timestamp)
          });
        }
      });

      const _chartSeries: {data: {x: Date, y: number[] | number}[], type: string, name: string, color?: string }[] = [{
        data: result,
        type: "candlestick",
        name: "Chart",
        color: "#000000",
      }];

      const annotations: XAxisAnnotations[] = [];

      backTest.trades.trades.forEach((trade) => {
        // trade annotatoin
        annotations.push({
          x: trade.entryTimestamp,
          x2: trade.exitTimestamp,
          fillColor: trade.tradeGainPercentage>=0 ? '#B3F7CA' : "#ffb1a8",
          label: {
            text: trade.type.toUpperCase(),
            orientation: 'horizontal',
          }
        })
        // close anotation
        annotations.push(    {
          x: trade.exitTimestamp,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            orientation: 'horizontal',
            text: `${trade.realized ? "Realized" : "Unrealized"} ${trade.tradeGainPercentage.toFixed(2)}%`
          }
        });
      });

      setChartOptions({
        ...chartOptions,
        annotations: {
          xaxis: annotations,
        }
      });

      // add any indicators
      const indicators: string[] = [];
      strategyData.events.forEach((strategyEvent) => {
        strategyEvent.conditions.forEach((condition) => {
          const indicatorKey = `${condition.script.type}:${condition.script.settings.join(",")}:${getHoursFromTimeframe(strategyData.timeframe)}`;
          if(condition.value.indicator) {
            const indicatorKey2 = `${condition.value.indicator.type}:${condition.value.indicator.settings.join(",")}:${getHoursFromTimeframe(strategyData.timeframe)}`;
            //if(!indicators.includes(indicatorKey2) && !indicatorKey2.includes("sma:1")) {
              indicators.push(indicatorKey2);
            //}
          }
          //if(!indicators.includes(indicatorKey) && !indicatorKey.includes("sma:1")) {
            indicators.push(indicatorKey);
          //}
        })
      });

      indicators.forEach((indicator, i) => {
        const dissolvedKey = dissolveKey(indicator);
        if(!isOscillator(dissolvedKey.type)) {
          const _indicatorChartData = createOscillatorChart(indicator).chartData;
          console.log("adding");
          // add to chart
          _chartSeries.push({
            ..._indicatorChartData[0],
            color: generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`),
            name: `${dissolvedKey.type.toUpperCase()} - ${dissolvedKey.settings}`,
          });
        }else{
          const _indicatorChartData = createOscillatorChart(indicator);
          setOscillatorChartSeries([...oscillatorChartSeries, _indicatorChartData.chartData]);
          setOscillatorChartOptions([...oscillatorChartOptions, _indicatorChartData.chartOptions]);
        }
      });

      console.log(_chartSeries);

      setChartSeries(_chartSeries);
    }
  }, [backTest]);

  return(
    <Box>
      <Accordion allowToggle mb="4">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                Back-Testing Settings
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} pt={2}>
            <Box>
              <FormLabel
                display='flex'
                ms='10px'
                fontSize='sm'
                color={textColorPrimary}
                fontWeight='bold'
                _hover={{ cursor: 'pointer' }}
              >
                Token
              </FormLabel>
              <Select onChange={(e) => setCoinId(e.target.value)} value={coinId} mb="10px">
                {coins?.map(coin => {
                  return(
                    <option key={coin.id} value={coin.id}>{coin.name} ({coin.symbol.toUpperCase()})</option>
                  )
                })}
              </Select>
              <InputField
                label={`Starting Balance (in ${coinId || "BTC"})`}
                placeholder="0.5"
                mb="15px"
                value={startingBalance}
                onChange={(e: any) => setStartingBalance(e.target.value)}
              />
              <Box mb="10px">
                <FormLabel
                  display='flex'
                  ms='10px'
                  fontSize='sm'
                  color={textColorPrimary}
                  fontWeight='bold'
                  _hover={{ cursor: 'pointer' }}
                >
                  Position Size
                </FormLabel>
                <Flex ms="10px">
                  <Switch
                    id="max"
                    isChecked={positionSize === "0"}
                    onChange={() => positionSize === "0" ? setPositionSize("") : setPositionSize("0")}
                  />
                  <FormLabel htmlFor='isDisabled' ml="3">Use Max (position size will always equal balance)</FormLabel>
                </Flex>
                {positionSize !== "0" ? (
                  <>
                    <InputField
                      label={`Position size (in ${coinId || "BTC"})`}
                      placeholder="0.5"
                      value={positionSize}
                      onChange={(e: any) => setPositionSize(e.target.value)}
                    />
                  </>
                ):null}
              </Box>
              <InputField
                label={`Back-Test History in Days`}
                placeholder="200"
                mb="10px"
                value={span}
                onChange={(e: any) => setSpan(e.target.value)}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Button
        variant={"darkBrand"}
        onClick={() => handleBackTest()}
        mt="1"
        mb="2"
        w={"100%"}
      >
        Load Back-Testing Results
      </Button>

      {isLoading ? (
        <Loading text="Back-Testing your strategy, please wait..." />
      ):null}

      {backTest && !isLoading ? (
        <Box>
          <HSeparator my="6" />
          <Box px="1.5">
            <Text fontSize={"xl"} fontWeight={"bold"} mb="0">Overview</Text>
            <Text>Overview of backtest results. See details bellow.</Text>
          </Box>

          <Box px="1.5" mt="4">
            <Text fontSize={"lg"} fontWeight={"900"} mb="0">Details</Text>
            <Flex mb="1">
              <Text mr="4">Number of Trades</Text>
              <Tooltip label="All trades">
                <Badge variant={"outline"} width="min-content" ml="2">{backTest.trades.trades.length}</Badge>
              </Tooltip>
              <Tooltip label="Profitable trades">
                <Badge variant={"outline"} colorScheme="green" width="min-content" ml="2">{backTest.trades.profitable}</Badge>
              </Tooltip>
              <Tooltip label="Losing trades">
                <Badge variant={"outline"} colorScheme="red" width="min-content" ml="2">{backTest.trades.losers}</Badge>
              </Tooltip>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Net Profit</Text>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Percent Profitable</Text>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Profit Factor</Text>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Max Drawdown</Text>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Average Trader Return</Text>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Average Trade Length</Text>
            </Flex>
          </Box>

          <Accordion allowToggle allowMultiple={true} mt="6">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Price Chart
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pt={2}>
                <Box>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="candlestick" height={height} width={width} />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Balance Chart
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pt={2}>
                <Box>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="candlestick" height={height} width={width} />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Trades List
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pt={2}>
                <Box>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="candlestick" height={height} width={width} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      ):null}
    </Box>
  );
}