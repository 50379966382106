import { Accordion, AccordionButton, AccordionItem, Badge, Button, Text, useColorModeValue, Box, AccordionIcon, AccordionPanel, Flex, Link } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import { useCoins } from "contexts/CoinsContext";
import { createLinkForWatchlist } from "helpers/formatters";

const WatchlistBox = (props: { watchlist: BullFolio.Watchlist }) => {
  const { watchlist } = props;

  const textColor = useColorModeValue("navy.700", "white");
	const secondaryText = useColorModeValue('gray.700', 'white');
  const textDetailsFontSize = "sm";

  let txNum = 0;
  watchlist.tokens.forEach((token) => {
    txNum += token.transactions.length;
  });

  return(
    <Card>
      <Text textColor={textColor} fontWeight="extrabold" fontSize={"xl"}>{watchlist.name}</Text>
      <Text textColor={secondaryText} mb="3">{watchlist.description}</Text>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                Details
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={"extrabold"}>
                Tokens
              </Text>
              <Box>
                {watchlist.tokens.length}
              </Box>
            </Flex>
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={"extrabold"}>
                Transactions
              </Text>
              <Box>
                {txNum}
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Link href={`/#${createLinkForWatchlist(watchlist.id)}`}>
        <Button variant="brand" w="100%" mt="6">
          View Watchlist
        </Button>
      </Link>
    </Card>
  );
};

export default WatchlistBox;