import { useEffect, useState } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Flex, Icon, Image, SimpleGrid, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { useCoins } from "contexts/CoinsContext";
import { useUser } from "contexts/UserContext";
import WatchlistTable, { RowObjWatchlists } from "./components/WatchlistTable";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import numeral from "numeral";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from "components/icons/IconBox";
import { MdBarChart } from "react-icons/md";
import { BsBellFill } from "react-icons/bs";
import { getCurrencySymbol } from "helpers/formatters";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useWatchlists } from "contexts/WatchlistsContext";
import CreateWatchlistDrawer from "components/drawers/CreateWatchlistDrawer";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import { links } from "helpers/links";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import WatchlistBox from "./components/WatchlistBox";
import { FaTable } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";


const columnsNum = {
  sm: 1,
  md: 1,
  lg: 3
};


export default function AllWatchlists() {
	// Chakra Color Mode
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  
	const [value, setValue] = useState("");
	const [tableMode, setTableMode] = useState(true);
	const [watchlists, setWatchlist] = useState<BullFolio.Watchlist[]>([]);
	const [loadingText, setLoadingText] = useState<string>("Loading coins...");
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [strategies, setStrategies] = useState<BullFolio.Strategy[] | null>(null);

	const { getAllCoins, test } = useCoins();
	const { userData } = useUser();
	const { getMyStrategies } = useStrategies();
	const { getAllWatchlists } = useWatchlists();

	useEffect(() => {
		if(userData) {
			(async () => {
				setLoadingText("Loading watchlists...")
        setIsLoading(true);
        const res = await getAllWatchlists();
        console.log(res);
        setWatchlist(res);
        setIsLoading(false);
			})();
		}
	}, [userData]);

	const createTableData = (): RowObjWatchlists[] => {
		if(watchlists) {
			return watchlists.map((watchlist, index) => {
				let txNum = 0;
				watchlist.tokens.forEach((token) => {
					txNum += token.transactions.length;
				});
				return ({
					balance: "$0",
					coinsNum: watchlist.tokens.length.toString(),
					name: [watchlist.name, watchlist.id],
					profit: "$0",
					changeDay: "0",
					changeWeek: "0",
					txNum: txNum.toString(),
					delete: watchlist.id
				});
			});
		}
	};

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<BreadCrumpStrip
        links={[{
          href: `/`,
          name: "All My Watchlists"
        }]}
        additional={{ mb: "4" }}
      />
			<Box px="4" mb="6" mt="5">
				<Flex justifyContent={"space-between"}>
					<Box>
						<Text fontWeight={"extrabold"} fontSize={"5xl"}>All Watchlists</Text>
						<Flex>
							<Text fontWeight={"thin"} fontSize={"md"} mx="2.5">{watchlists?.length} WATHCLISTS</Text>
						</Flex>
					</Box>
					{/*<Box>
						<Text fontWeight={"extrabold"} fontSize={"3xl"} mb="-1" pb="-1">$1,345.90</Text>
						<Text fontWeight={"thin"} fontSize={"md"} mx="2.5" textColor={"green"} textAlign={"right"}>+ $231 (8,3%)</Text>
						<Text fontWeight={"normal"} fontSize={"md"} mx="2.5" mt="1.5" textAlign={"right"}>0.0172</Text>
						<Text fontWeight={"thin"} fontSize={"xs"} mx="2.5" textColor={"green"} textAlign={"right"}>+ 0.00012 (3,3%)</Text>
					</Box>*/}
				</Flex>
			</Box>
			{/*<Box px="4">
				<Accordion allowToggle>
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Box as="span" flex='1' textAlign='left'>
									<Text fontWeight={"bold"} fontSize={"lg"}>Graph</Text>
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} pt={2}>

						</AccordionPanel>
					</AccordionItem>

					<AccordionItem>
						<h2>
							<AccordionButton>
								<Box as="span" flex='1' textAlign='left'>
									<Text fontWeight={"bold"} fontSize={"lg"}>Pie Chart</Text>
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} pt={2}>
								
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Box>*/}
			<Box mt="4">
				<Flex justifyContent={"space-between"} px="4" mb="2">
					<Text pt="2" fontWeight={"bold"} fontSize={"2xl"}>Watchlists</Text>
					<Button variant="darkBrand" onClick={() => setOpen(true)}>Create New</Button>
				</Flex>
				{!isLoading && watchlists.length > 0 ? (
					<Flex mb="6" px="6">
						<Text fontSize={"xl"} color={textColorPrimary}>
							<IoGrid />
						</Text>
						<Switch
							isChecked={tableMode}
							onChange={() => setTableMode(!tableMode)}
							mr="2"
							ml="2"
							mt="0.5"
						/>
						<Text fontSize={"xl"} color={textColorPrimary}>
							<FaTable />
						</Text>
					</Flex>
				):null}
				{!isLoading && !tableMode && watchlists.length > 0 ? (
					<>
						<Card mb="6">
							<SearchBar
								value={value}
								onChange={(e:any) => setValue(e.target.value)}  
								h='44px'
								w={{ lg: "390px" }}
								borderRadius='16px'
								setSearchTerm={() => {}}
							/> 
						</Card>

						{watchlists?.length>0 ? (
							<SimpleGrid columns={columnsNum} gap="20px">
								{watchlists.filter(obj => obj.name.toLowerCase().includes(value.toLowerCase())).map(watchlist => {
									return (
										<Box key={watchlist.id}>
											<WatchlistBox watchlist={watchlist} />
										</Box>
									)
								})}
							</SimpleGrid>
						):null}

						<Button variant="darkBrand" mt="6" w={"100%"} onClick={() => setOpen(true)}>Create New Watchlist</Button>
					</>
				):null}

				{!isLoading && watchlists.length > 0 && tableMode ? (
					<Card>
						<WatchlistTable tableData={createTableData()} />
					</Card>
				):null}

				{!isLoading && watchlists.length === 0 ? (
					<Text align={"center"}>No watchlists created yet!</Text>
				):null}

				{isLoading ? (
					<Loading text="Getting your watchlists..."/>
				):null}
			</Box>

			<CreateWatchlistDrawer
        isOpen={open}
        close={() => setOpen(false)}
      />
		</Box>
	);
}
