import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import { BacktestContent } from "components/backtest/BackTestContent";
import { HSeparator } from "components/separator/Separator";

const drawerSizes = {
  sm: '750px',
  md: "750px",
  lg: "1750px"
};

const BackTestDrawer = (props: { strategyData: BullFolio.Strategy, isOpen: boolean, close: () => void, coins: BullFolio.CoinData.Data[] }) => {

  const { strategyData, isOpen, close, coins } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1250px"
        maxW={drawerSizes}
        borderRadius='8px'
        bg={drawerBg}
      >
        <DrawerBody pt="0" mt="0" className="custom-scroll">
          <Button width={"100%"} onClick={close} mt="4">
            Close
          </Button>
          <Box mt="4">
            <Text fontSize={"2xl"} fontWeight={"extrabold"}>Back-Testing Results</Text>
            <Text textAlign={"left"} fontSize={"xl"} fontWeight="400" mt="-1">Back-testing results for <b>{strategyData.name}</b> strategy.</Text>
          </Box>
          <HSeparator my="8" />
          <BacktestContent
            strategyData={strategyData}
            coins={coins}
            isModal={true}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default BackTestDrawer;