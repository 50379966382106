/* eslint-disable */ 
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Icon, Stack, Image, Link, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, Select} from '@chakra-ui/react'; 
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import * as React from 'react';
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { 
  PaginationState, 
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel, 
  getSortedRowModel, 
  flexRender, 
} from '@tanstack/react-table'
import { capitalizeFirstLetter, createLinkForCoin } from 'helpers/formatters';
import { BullFolio } from 'bullfolio-types';
import { FcBearish, FcBullish, FcNeutralTrading } from "react-icons/fc";
import IconBox from 'components/icons/IconBox';
import { CATEGORIES } from 'variables/categories';
import { FaTrashCan } from "react-icons/fa6";
import { useWatchlists } from 'contexts/WatchlistsContext';
import CoinDetailsDrawer from 'components/drawers/CoinDetailsDrawer';

export type RowObjCoins = {
	rank: string;
	name: string[];
	price: string;
	changeDay: string;
	percentage: string;
  marketCap: string;
  balance: string;
  balanceUsd: string;
  profit: string[];
  id: number;
};

const ROWS = 100;

export default function WatchlistCoinsTable(props: { watchlist: BullFolio.Watchlist, tableData: RowObjCoins[] }) {
	const { watchlist, tableData } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");  
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [open, setOpen] = React.useState(false);
  const [tokenId, setTokenId] = React.useState<string | null>(null);

  const openModal = (id: string) => {
    setTokenId(id);
    setOpen(true);
  }

  const { removeTokenFromWatchlist } = useWatchlists();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  let defaultData = tableData; 
  const [globalFilter, setGlobalFilter] = React.useState('')
    const columnHelper = createColumnHelper<RowObjCoins>();
 	const columns = [
		columnHelper.accessor('rank', {
			id: 'rank',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					MC. RANK
				</Text>
			),
			cell: (info: any) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
			)
		}), 
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NAME
				</Text>
			),
			cell: (info) => ( 
        <Flex onClick={() => openModal(info.getValue()[3])} cursor="pointer">
          <Image src={info.getValue()[2]} width="26px" height="26px" mr="4" rounded={"full"}  />
          <Text color={textColor} fontSize='lg' fontWeight='800' mr="2" mt="1">
            {info.getValue()[0]}
          </Text>
          <Text color={textColor} fontSize='md' fontWeight='500' mt="1">
            {(info.getValue()[1] || "").toUpperCase()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('price', {
			id: 'price',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PRICE
				</Text>
			),
			cell: (info) => ( 
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
			)
		}),
		columnHelper.accessor('changeDay', {
			id: 'changeDay',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CHANGE 24H
				</Text>
			),
			cell: (info) => (
        <Text
          color={info.getValue()[0] === '-' ? 'red.500' : 'green.500'}
          fontSize='sm'
          fontWeight='600'>
          {info.getValue()}%
        </Text>
			)
		}),
    columnHelper.accessor('marketCap', {
			id: 'marketCap',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					MARKET CAP
				</Text>
			),
			cell: (info) => (
        <Text color={textColor} fontSize='md' fontWeight='500'>
          {info.getValue()}
        </Text>
			)
		}),
    columnHelper.accessor("percentage", {
			id: 'percentage',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PERCENTAGE
				</Text>
			),
			cell: (info) => ( 
        <Text
          fontSize='sm'
          color={textColor}
          fontWeight='600'>
          {info.getValue()}
        </Text>
			)
		}),
    columnHelper.accessor("balance", {
			id: 'balance',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					BALANCE
				</Text>
			),
			cell: (info) => ( 
        <Text
          fontSize='sm'
          color={textColor}
          fontWeight='600'>
          {info.getValue()}
        </Text>
			)
		}),
    columnHelper.accessor("balanceUsd", {
			id: 'balanceUsd',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					BALANCE USD
				</Text>
			),
			cell: (info) => ( 
        <Text
          fontSize='sm'
          color={textColor}
          fontWeight='600'>
          {info.getValue()}
        </Text>
			)
		}),
    columnHelper.accessor("profit", {
			id: 'profit',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PROFIT
				</Text>
			),
			cell: (info) => ( 
        <Text
          color={info.getValue()[0][0] === '-' ? 'red.500' : 'green.500'}
          fontSize='sm'
          fontWeight='600'>
          {info.getValue()[0]}% ({info.getValue()[1]})
        </Text>
			)
		}),
		columnHelper.accessor('id', {
			id: 'id',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
          MORE
				</Text>
			),
			cell: (info) => ( 
        <Text
          cursor='pointer'
          color={textColor}
          fontSize='md'
          fontWeight='500'
          onClick={() => removeTokenFromWatchlist(watchlist, info.getValue())}
        >
          <FaTrashCan />
        </Text>
			)
		}),            
	];
    const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: ROWS,
    });
 
    const pagination = React.useMemo(
        () => ({
        pageIndex,
        pageSize,
        }),
        [pageIndex, pageSize]
    )
  const table = useReactTable({
    data: tableData,
    columns, 
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter, 
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const handlePaginationClick = async(page: number) => {
    table.setPageIndex(page - 1);
  }

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  return (
    <>
      <Flex
        direction='column'
        mt="1"
        w='100%'
        overflowX={"scroll"}>
            <Flex
            align={{ sm: "flex-start", lg: "flex-start" }}
            justify={{ sm: "flex-start", lg: "flex-start" }}
            w='100%'
            px='22px'
            mb='3'>
                <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
                placeholder="Search..."
                />
            </Flex> 
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <Th
                    pe='10px' 
                    borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : ( 
                    <Flex
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'> 
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                    </Flex> 
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <Td key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor={borderColor}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
     <Flex w="100%" justify="space-between" px="20px" pb="5px">
        {/* SET ROW NUMBER */}
          <Text
            fontSize='sm'
            color='gray.500'
            fontWeight='normal'
            mb={{ sm: "24px", md: "0px" }}> 
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            of {tableData.length} entries
          </Text> 
        {/* PAGINATION BUTTONS */}
        <div className="flex items-center gap-2"> 
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
                variant='no-effects'
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition='all .5s ease'
                w='40px'
                h='40px'
                borderRadius='50%'
                bg='transparent'
                border='1px solid'
                borderColor={useColorModeValue("gray.200", "white")}
                display={
                pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
                }
                _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
                }}>
                    <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
                </Button>
            {Array.from({ length: Math.ceil(tableData.length / ROWS) }, (_, i) => i + 1).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => handlePaginationClick(pageNumber)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })}
            <Button
                variant='no-effects'
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition='all .5s ease'
                w='40px'
                h='40px'
                borderRadius='50%'
                bg='transparent'
                border='1px solid'
                borderColor={useColorModeValue("gray.200", "white")}
                display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
                _hover={{
                    bg: "whiteAlpha.100",
                    opacity: "0.7",
                }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
        </Stack> 
      </div>
      
         </Flex> 
      </Flex>

      <CoinDetailsDrawer
        isOpen={open}
        close={() => setOpen(false)}
        tokenId={tokenId}
      />
    </>
  )
} 
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <SearchBar
            {...props}
            value={value}
            onChange={(e:any) => setValue(e.target.value)}  
            w={{ lg: "390px" }}
            borderRadius='16px'
            setSearchTerm={() => {}}
            /> 
  )
}