import { Box, Button, Center, Text, Link, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import Card from "components/card/Card";
import Loading from "components/Loading/Loading";
import { useAlerts } from "contexts/AlertsContext";
import { useUser } from "contexts/UserContext";
import { decodeUrlToObject, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useEffect, useState } from "react";


const Connect = () => {
  const textColor = useColorModeValue("navy.700", "white");

  const query = useQuery();
  const { connectApp, userData } = useUser();

  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(userData) {
      const dataRaw = query.get("data");
      console.log(dataRaw);
      const data = decodeUrlToObject(dataRaw) as BullFolio.ConnectData;
      console.log(data);
      if(data) {
        (async () => {
          setIsLoading(true);
          try{
            await connectApp(data);
            setSuccess(true);
          }catch(err) {
            setSuccess(false);
          }
          setIsLoading(false);
        })();
      }
    }
  }, [query.get("data"), userData]);

  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `${links.profileSettings}`,
          name: "Settings"
        }]}
        additional={{ mb: "4" }}
      />
      <Center mt="8">
        <Text fontWeight={"extrabold"} fontSize={"4xl"}>Connect Telegram</Text>
        <Box>
          {isLoading ? (
            <Center>
              <Box>
                <Loading text="Connecting, please wait ..." />
                <Text>Bot should send you a welcome message once connected.</Text>
              </Box>
            </Center>
          ):null}
          {!isLoading && !success ? (
            <Text>Connecting BullFolio account has failed, please try again or contact support.</Text>
          ):null}
          {!isLoading && success ? (
            <Box>
              <Text fontWeight={"bold"} fontSize={"2xl"}>Connected Successfully</Text>
              <Text color={textColor}>You will now receive alerts in Telegram. You can always change this in the account settings.</Text>
            </Box>
          ):null}
        </Box>
      </Center>
    </Box>
  );
};

export default Connect;