import { BullFolio } from "bullfolio-types";

export const calculateLevels = (prices: number[], n?: number, t?: number) => {
  const N = n || 2;
  const smoothingWindow = 15;
  const levelThresholdPercent = t || 5; // Percentage threshold for consolidation

  const supportIndices: number[] = findLocalExtrema(
    prices, smoothingWindow, (a, b) => a < b
  );
  const resistanceIndices: number[] = findLocalExtrema(
    prices, smoothingWindow, (a, b) => a > b
  );

  const smoothedPrices: number[] = calculateSmoothedCurve(
    prices, smoothingWindow
  );

  const supportLevels: {
    price: number;
    importance: number;
  }[] = supportIndices.map((index) => ({
    price: prices[index],
    importance: calculateImportance(index, true, prices, smoothedPrices),
  }));

  const resistanceLevels: {
    price: number;
    importance: number;
  }[] = resistanceIndices.map((index) => ({
    price: prices[index],
    importance: calculateImportance(index, false, prices, smoothedPrices),
  }));

  // Consolidate nearby levels with a percentage-based threshold
  const _levelsToReturn = consolidateLevels([...supportLevels, ...resistanceLevels], levelThresholdPercent)
  
  const levelsToReturn: number[] = [];

  _levelsToReturn.sort((a, b) => b.importance - a.importance);

  _levelsToReturn.slice(0, N).forEach((level) => {
    if (level) {
      levelsToReturn.push(level.price);
    }
  });

  return levelsToReturn;
};

export const timeframeToThreshold = (timeframe: BullFolio.Timeframe) => {
  switch(timeframe) {
    case "1d": return 5;
    case "2d": return 6;
    case "5d": return 8;
    case "1w": return 10;
    case "1h": return 1;
    case "4h": return 2;
    case "8h": return 3;
  }
}

const findLocalExtrema = (
  prices: number[],
  smoothingWindow: number,
  compareFunc: (a: number, b: number) => boolean
): number[] => {
  const extremaIndices: number[] = [];
  for (let i = smoothingWindow; i < prices.length - smoothingWindow; i++) {
    let isExtremum = true;
    for (let j = i - smoothingWindow; j <= i + smoothingWindow; j++) {
      if (i !== j && compareFunc(prices[i], prices[j])) {
        isExtremum = false;
        break;
      }
    }
    if (isExtremum) {
      extremaIndices.push(i);
    }
  }
  return extremaIndices;
};

const calculateSmoothedCurve = (
  prices: number[],
  smoothingWindow: number
): number[] => {
  const smoothedPrices: number[] = [];
  for (let i = smoothingWindow - 1; i < prices.length; i++) {
    const sum: number = prices
      .slice(i - smoothingWindow + 1, i + 1)
      .reduce((acc, val) => acc + val, 0);
    smoothedPrices.push(sum / smoothingWindow);
  }
  return smoothedPrices;
};

const calculateImportance = (
  index: number,
  isSupport: boolean,
  prices: number[],
  smoothedPrices: number[]
): number => {
  const deviation: number = isSupport ?
    smoothedPrices[index] - prices[index] :
    prices[index] - smoothedPrices[index];
  return Math.abs(deviation);
};

/**
 * Consolidates levels that are within a certain percentage threshold of each other.
 * For each group of close levels, calculates the average price and max importance.
 */
const consolidateLevels = (
  levels: { price: number; importance: number }[],
  thresholdPercent: number
): { price: number; importance: number }[] => {
  if (levels.length === 0) return [];

  // Sort levels by price
  levels.sort((a, b) => a.price - b.price);

  const consolidatedLevels: { price: number; importance: number }[] = [];
  let currentGroup: { price: number; importance: number }[] = [levels[0]];

  for (let i = 1; i < levels.length; i++) {
    const prevLevel = levels[i - 1];
    const currentLevel = levels[i];
    
    // Calculate dynamic threshold based on previous level price
    const dynamicThreshold = (thresholdPercent / 100) * prevLevel.price;

    if (Math.abs(currentLevel.price - prevLevel.price) <= dynamicThreshold) {
      currentGroup.push(currentLevel);
    } else {
      consolidatedLevels.push(averageLevel(currentGroup));
      currentGroup = [currentLevel];
    }
  }
  consolidatedLevels.push(averageLevel(currentGroup));

  return consolidatedLevels;
};

/**
 * Calculates the average price and max importance for a group of levels.
 */
const averageLevel = (
  levels: { price: number; importance: number }[]
): { price: number; importance: number } => {
  const totalImportance = levels.reduce((acc, level) => acc + level.importance, 0);
  const avgPrice = levels.reduce((acc, level) => acc + level.price * (level.importance / totalImportance), 0);
  const maxImportance = Math.max(...levels.map(level => level.importance));
  return { price: avgPrice, importance: maxImportance };
};
