import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import BackTestDrawer from "components/drawers/BackTestDrawer";
import { BacktestContent } from "components/backtest/BackTestContent";


const BacktestPanel = (props: { strategy: BullFolio.Strategy, coins: BullFolio.CoinData.Data[] | null }) => {

  const { strategy, coins } = props;

  return(
    <>
      <Card>
        <Box px="1.5">
          <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Performance Result</Text>
          <Text>Analyze performance of this strategy by backtesting.</Text>
        </Box>
      </Card>

      <Card mt="4">
        <BacktestContent
          strategyData={strategy}
          coins={coins}
          isModal={false}
        />
      </Card>
    </>
  )
};

export default BacktestPanel;