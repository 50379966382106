export const CATEGORIES = [
  "Decentralized Exchange (DEX)",
  "Coinbase Ventures Portfolio",
  "Protocol",
  "Governance",
  "Decentralized Finance (DeFi)",
  "Polkadot Ecosystem",
  "Avalanche Ecosystem",
  "Harmony Ecosystem",
  "Polygon Ecosystem",
  "Ethereum Ecosystem",
  "Artificial Intelligence (AI)",
  "Privacy Coins",
  "FTX Holdings",
  "Exchange-based Tokens",
  "BNB Chain Ecosystem",
  "Automated Market Maker (AMM)",
  "Number",
  "Near Protocol Ecosystem",
  "Defi Pulse Index (DPI)",
  "Lending/Borrowing",
  "Yield Farming",
  "Fantom Ecosystem",
  "Optimism Ecosystem",
  "SocialFi",
  "Solana Ecosystem",
  "DWF Labs Portfolio",
  "Infrastructure",
  "Base Ecosystem",
  "Smart Contract Platform",
  "Osmosis Ecosystem",
  "Cosmos Ecosystem",
  "Layer 1 (L1)",
  "DePIN",
  "Storage",
  "Proof of Stake (PoS)",
  "USD Stablecoin",
  "Stablecoins",
  "Payment Solutions",
  "Rollup",
  "Zero Knowledge (ZK)",
  "Proof of Work (PoW)",
  "Alephium Ecosystem",
  "Cryptocurrency",
  "Wallets",
  "Multicoin Capital Portfolio",
  "Cronos Ecosystem",
  "Metaverse",
  "NFT",
  "Alleged SEC Securities",
  "Algorand Ecosystem",
  "Gaming Governance Token",
  "Card Games",
  "Binance Launchpool",
  "Gaming (GameFi)",
  "Play To Earn",
  "Real World Assets (RWA)",
  "Binance Launchpad",
  "Yield Aggregator",
  "Restaking",
  "Rollups-as-a-Service (RaaS)",
  "Rebase Tokens",
  "Arbitrum Ecosystem",
  "Liquid Staking Governance Tokens",
  "Moonriver Ecosystem",
  "Eth 2.0 Staking",
  "Celo Ecosystem",
  "Gnosis Chain Ecosystem",
  "Technology & Science",
  "Gaming Utility Token",
  "Perpetuals",
  "Cardano Ecosystem",
  "Oracle",
  "Aptos Ecosystem",
  "Software",
  "Dog-Themed Coins",
  "Meme",
  "eGirl Capital Portfolio",
  "Arbitrum Nova Ecosystem",
  "Layer 2 (L2)",
  "Analytics",
  "Launchpad",
  "Music",
  "AI Agents",
  "Bridge Governance Tokens",
  "Interoperability",
  "Moonbeam Ecosystem",
  "Paradigm Portfolio",
  "Breeding",
  "Ronin Ecosystem",
  "Axie Infinity",
  "Business Services",
  "Gaming Blockchains",
  "Masternodes",
  "Liquid Staking Tokens",
  "Account Abstraction",
  "Edgeware Ecosystem",
  "RPG",
  "TRY Stablecoin",
  "Finance / Banking",
  "Centralized Exchange (CEX)",
  "Bitcoin Fork",
  "NFTFi",
  "NFT Marketplace",
  "Shibarium Ecosystem",
  "Neon Ecosystem",
  "Solana Meme Coins",
  "Klaytn Ecosystem",
  "Canto Ecosystem",
  "Modular Blockchain",
  "Data Availability",
  "Poolz Finance Launchpad",
  "Discord Bots",
  "ChainGPT Pad",
  "Telegram Apps",
  "Sports",
  "Identity",
  "Wrapped-Tokens",
  "cToken",
  "Compound Tokens",
  "Asset-backed Tokens",
  "Curve Ecosystem",
  "Metagovernance",
  "Kava Ecosystem",
  "Business Platform",
  "Big Data",
  "Sports Games",
  "Animal Racing",
  "ZkSync Ecosystem",
  "Velas Ecosystem",
  "Metis Ecosystem",
  "DaoMaker Ecosystem",
  "Simulation Games",
  "Entertainment",
  "Internet of Things (IOT)",
  "Scroll Ecosystem",
  "Elon Musk-Inspired Coins",
  "Derivatives",
  "AI-Themed Coins",
  "Collectibles",
  "MultiversX Ecosystem",
  "Environment",
  "Energy",
  "EthereumPoW Ecosystem",
  "Media",
  "Olympus Pro",
  "Seigniorage",
  "Gaming Platform",
  "MEV Protection",
  "Prediction Markets",
  "AI Meme Coins",
  "Guild and Scholarship",
  "Synthetic Issuer",
  "Hedera Ecosystem",
  "Communication",
  "Strategy Games",
  "Decentralized Science (DeSci)",
  "Healthcare",
  "Tokenized BTC",
  "Adventure Games",
  "Immutable Ecosystem",
  "Insurance",
  "Internet Computer Ecosystem",
  "IoTeX Ecosystem",
  "Mantle Ecosystem",
  "Tron Ecosystem",
  "Kadena Ecosystem",
  "Kujira Ecosystem",
  "Linea Ecosystem",
  "Loopring Ecosystem",
  "Options",
  "PolitiFi",
  "Manta Network Ecosystem",
  "Inscriptions",
  "BRC-20",
  "Parallelized EVM",
  "OEC Ecosystem",
  "X1 Ecosystem",
  "Layer 3 (L3)",
  "LSDFi",
  "PAAL AI",
  "ERC 404",
  "Tokenized Gold",
  "Liquid Restaking Governance Tokens",
  "LRTfi",
  "Fixed Interest",
  "Layer 0 (L0)",
  "Cat-Themed Coins",
  "Radix Ecosystem",
  "Structured Products",
  "Gambling",
  "Sei Ecosystem",
  "On-chain Gaming",
  "Shooting Games",
  "TON Ecosystem",
  "Starknet Ecosystem",
  "XDC Ecosystem",
  "EUR Stablecoin",
  "Stellar Ecosystem",
  "Move To Earn",
  "Sui Ecosystem",
  "Yearn Ecosystem",
  "Rollux Ecosystem",
  "Asset Manager",
  "Investment",
  "Terra Ecosystem",
  "Tezos Ecosystem",
  "Fractionalized NFT",
  "Theta Ecosystem",
  "Legal",
  "WEMIX Ecosystem",
  "XAI Ecosystem",
  "ZetaChain Ecosystem",
  "Injective Ecosystem",
  "Zilliqa Ecosystem"
];