import { UseToastOptions } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { BullFolio } from "bullfolio-types";
import { links } from "./links";


export const replaceNulls = (arr: any[], replacementValue: number) => {
  return arr.map(item => item === null ? replacementValue : item);
}  

export const getTimestamp = (timestampInSeconds: number, x: number, y: number): number => {
  // Convert timestamp from seconds to milliseconds
  let timestampInMilliseconds = timestampInSeconds * 1000;
  
  // Calculate total hours to add
  const totalHours = x * y;
  
  // Convert hours to milliseconds
  const millisecondsToAdd = totalHours * 60 * 60 * 1000;
  
  // Add the milliseconds to the original timestamp
  timestampInMilliseconds += millisecondsToAdd;
  
  return timestampInMilliseconds;
};  

export const getHoursFromTimeframe = (timeframe: BullFolio.Timeframe) => {
  switch (timeframe) {
  case "1h": return 1;
  case "4h": return 4;
  case "8h": return 8;
  case "1d": return 24;
  case "2d": return 48;
  case "5d": return 120;
  case "1w": return 168;
  }
};

export const indicatorsKeyToTimeframeKey = (
  indicatorsTimeframeKey: number
): BullFolio.Timeframe => {
  switch (indicatorsTimeframeKey) {
  case 1: return "1h";
  case 4: return "4h";
  case 8: return "8h";
  case 24: return "1d";
  case 48: return "2d";
  case 120: return "5d";
  case 168: return "1w";
  default: return "1h";
  }
};

export const getStartTimestampAndLastUpdated = (
  interval: BullFolio.Timeframe,
  count: number
) => {
  // Get the current time in UTC
  const now = new Date();
  const utcNow = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );

  let lastFullIntervalDate; let nextFullIntervalDate;

  switch (interval) {
  case "1h":
    // Round down to the last full hour
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate(),
        utcNow.getUTCHours(),
        0,
        0
      )
    );
    // Next full hour
    nextFullIntervalDate = new Date(lastFullIntervalDate.getTime() + 3600000);
    break;
  case "4h":
    // Round down to the last full 4-hour interval
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate(),
        Math.floor(utcNow.getUTCHours() / 4) * 4,
        0,
        0
      )
    );
    // Next full 4-hour interval
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 4 * 3600000
    );
    break;
  case "8h":
    // Round down to the last full 8-hour interval
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate(),
        Math.floor(utcNow.getUTCHours() / 8) * 8,
        0,
        0
      )
    );
    // Next full 8-hour interval
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 8 * 3600000
    );
    break;
  case "1d":
    // Round down to the start of the day
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate(),
        0,
        0,
        0
      )
    );
    // Next full day
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 24 * 3600000
    );
    break;
  case "2d":
    // Round down to the start of the 2-day interval
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate() - (utcNow.getUTCDate() % 2),
        0,
        0,
        0
      )
    );
    // Next full 2-day interval
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 2 * 24 * 3600000
    );
    break;
  case "5d":
    // Round down to the start of the 5-day interval
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate() - (utcNow.getUTCDate() % 5),
        0,
        0,
        0
      )
    );
    // Next full 5-day interval
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 5 * 24 * 3600000
    );
    break;
  case "1w":
    // Round down to the start of the week (assuming week starts on Monday)
    lastFullIntervalDate = new Date(
      Date.UTC(
        utcNow.getUTCFullYear(),
        utcNow.getUTCMonth(),
        utcNow.getUTCDate() - ((utcNow.getUTCDay() + 6) % 7),
        0,
        0,
        0
      )
    );
    // Next full week
    nextFullIntervalDate = new Date(
      lastFullIntervalDate.getTime() + 7 * 24 * 3600000
    );
    break;
  default:
    throw new Error("Unsupported interval type");
  }

  // Calculate the date for the specified number of intervals ago
  let intervalInMs;
  switch (interval) {
  case "1h":
    intervalInMs = 3600000; // 1 hour in milliseconds
    break;
  case "4h":
    intervalInMs = 4 * 3600000; // 4 hours in milliseconds
    break;
  case "8h":
    intervalInMs = 8 * 3600000; // 8 hours in milliseconds
    break;
  case "1d":
    intervalInMs = 24 * 3600000; // 1 day in milliseconds
    break;
  case "2d":
    intervalInMs = 2 * 24 * 3600000; // 2 days in milliseconds
    break;
  case "5d":
    intervalInMs = 5 * 24 * 3600000; // 5 days in milliseconds
    break;
  case "1w":
    intervalInMs = 7 * 24 * 3600000; // 1 week in milliseconds
    break;
  }

  const timestampInMs = lastFullIntervalDate.getTime() - (count * intervalInMs);
  // Convert milliseconds to seconds
  const timestampInSeconds = Math.floor(timestampInMs / 1000);
  // Calculate the number of hours to the next interval
  const msToNextInterval = nextFullIntervalDate.getTime() - utcNow.getTime();
  const hoursToNextInterval = msToNextInterval / 3600000;

  return {
    timestampInSeconds,
    hoursToNextInterval: Math.ceil(hoursToNextInterval),
  };
};

export const getToast = (
  type: "success"|"error"|"info"|"warning",
  title: string,
  message: string
): UseToastOptions => {
  return {
    duration: type==="success" ? 2000 : 4000,
    status: type,
    title: title,
    description: message,
    isClosable: true,
    position: "bottom-right"
  }
};

export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.substr(0, n)}...${str.substr(str.length - n, str.length)}`;
  }
  return "";
};

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getCurrencySymbol = (currency: BullFolio.MainCurrencies) => {
  if(currency==="usd") return "$";
  if(currency==="eur") return "€";
  if(currency==="jpy") return "¥";
  if(currency==="btc") return "₿";
  if(currency==="eth") return "Ξ";
};

export const createLinkForCoin = (id: string) => {
  return `${links.coinPage}?coinId=${id}`;
};

export const createLinkForWatchlist = (id: string) => {
  return `${links.watchlistPage}?id=${id}`;
};

export const createLinkForNft = (id: string) => {
  return `${links.nftPage}?collectionId=${id}`;
};

export const createOpenSeaLink = (chain: string, address: string, id: number) => {
  let _chain = "ethereum";
  switch(chain) {
    case "0x1": {
      _chain = "ethereum";
      break;
    }
    case "0x38": {
      _chain = "bsc";
      break;
    }
    case "0x89": {
      _chain = "matic";
      break;
    }
    case "0xa4b1": {
      _chain = "arbitrum";
      break;
    }
    case "0xa86a": {
      _chain = "avalanche";
      break;
    }
    case "0xfa": {
      _chain = "";
      break;
    }
    default: {
      _chain ="ethereum";
      break;
    }
  }
  return `https://opensea.io/assets/${_chain}/${address}/${id}`;
};

export const createNftId = (id: string) => {
  let lowercaseString = id.toLowerCase();
  let transformedString = lowercaseString.replace(/\s+/g, '_');
  return transformedString;
};

export const generateUID = () => {
  const uid = crypto.randomUUID();
  return uid;
};

export const stringToHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const generateHexColorFromString = (inputString: string) => {
  const hash = stringToHash(inputString);
  const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - color.length) + color;
};

export const chainIdToTicker = (id: string) => {
  switch(id) {
    case "0x1": return "ETH";
    case "0x38": return "BNB";
    case "0x89": return "MATIC";
    case "0xa4b1": return "ARB";
    case "0xa86a": return "AVAX";
    case "0xfa": return "FTM";
    default: return "ETH";
  }
};

export const getChainName = (id: string) => {
  switch(id) {
    case "0x1": return "Ethereum";
    case "0x38": return "BNB Chain";
    case "0x89": return "Polygon";
    case "0xa4b1": return "Arbitrum";
    case "0xa86a": return "Avalanche";
    case "0xfa": return "Fantom";
    default: return "Ethereum";
  }
};

export const chainIdToTokenId = (id: string) => {
  switch(id) {
    case "0x1": return "ethereum";
    case "0x38": return "binancecoin";
    case "0x89": return "matic-network";
    case "0xa4b1": return "arbitrum";
    case "0xa86a": return "avalanche-2";
    case "0xfa": return "fantom";
    default: return "ethereum";
  }
};

export const getChainLogoById = (id: string) => {
  switch(id) {
    case "0x1": return "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880";
    case "0x38": return "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1644979850";
    case "0x89": return "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912";
    case "0xa4b1": return "https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg?1680097630";
    case "0xa86a": return "https://assets.coingecko.com/coins/images/12559/large/Avalanche_Circle_RedWhite_Trans.png?1670992574";
    case "0xfa": return "https://assets.coingecko.com/coins/images/4001/large/Fantom_round.png?1669652346";
    default: return "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880";
  }
};

export const getChainExplorer = (id: string) => {
  switch(id) {
    case "0x1": return "https://etherscan.io/";
    case "0x38": return "https://bscscan.com/";
    case "0x89": return "https://polygonscan.com/";
    case "0xa4b1": return "https://arbiscan.io/";
    case "0xa86a": return "https://snowtrace.io/";
    case "0xfa": return "https://ftmscan.com/";
    default: return "https://etherscan.io/";
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const dissolveKey = (key: string) => {
  const [type, settingsStr, timeframe] = key.split(":");
  const settings = settingsStr.split(",").map(Number);

  return {
    type: type as BullFolio.Strategy.Event.Condition.Type,
    settings,
    timeframe: Number(timeframe),
  };
};

export const secondsToDayMonth = (seconds: number) => {
  const date = new Date(seconds * 1000); // Convert seconds to milliseconds
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based, so add 1

  // You may want to format the day and month to have leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}`;
};

export const isOscillator = (type: BullFolio.Strategy.Event.Condition.Type) => {
  switch(type) {
    case "dema": return false;
    case "ema": return false;
    case "rsi": return true;
    case "rsi_ma": return true;
    case "sma": return false;
    case "tema": return false;
    case "wma": return false;
    case "bbwp": return true;
    case "bbwp_ma": return true;
    case "stoch_rsi_d": return true;
    case "stoch_rsi_k": return true;
    case "tenkan_sen": return false;
    default: return false;
  };
};

export const splitPriceData = (
  pricesData: BullFolio.CoinData.Price[],
  timeframe: number
): BullFolio.CoinData.Price[][] => {
  const prices = [...pricesData];

  // check if timestamp is in correct format
  if (isNaN(Number(prices[0].timestamp.toString()[0]))) {
    pricesData.forEach((data, index) => {
      // wrong format - fix and save in prices
      if (isNaN(Number(data.timestamp.toString()[0]))) {
        prices[index] = {
          ...data,
          // @ts-ignore
          timestamp: (new Date (data.timestamp._seconds*1000)).toString()
        };
      }
    });
  }

  // Sort the array of objects by timestamp
  prices.sort(
    (a, b) => Number(new Date(a.timestamp)) - Number(new Date(b.timestamp))
  );

  // Initialize variables to keep track of the current group and the result
  let currentGroup: BullFolio.CoinData.Price[] = [];
  const result: BullFolio.CoinData.Price[][] = [];

  // Iterate through the sorted data
  for (let i = 0; i < prices.length; i++) {
    if (currentGroup.length === 0) {
      currentGroup.push(prices[i]);
    } else {
      // eslint-disable-next-line max-len
      // Calculate the time difference in hours between the current entry and the first entry in the group
      // eslint-disable-next-line max-len
      const timeDifference = (Number(new Date(prices[i].timestamp)) - Number(new Date(currentGroup[0].timestamp))) / (1000 * 60 * 60);

      if (timeDifference <= timeframe) {
        currentGroup.push(prices[i]);
      } else {
        // Start a new group
        result.push(currentGroup);
        currentGroup = [prices[i]];
      }
    }
  }

  // Add the last group to the result
  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  return result;
};

export const padArray = (array: any[], targetLength: number, paddingValue: any) => {
  const currentLength = array.length;
  
  // Check if padding is needed
  if (currentLength < targetLength) {
    const paddingCount = targetLength - currentLength;

    // Use a loop to prepend values to the array
    for (let i = 0; i < paddingCount; i++) {
      array.unshift(paddingValue);
    }
  }

  return array;
};

export const formatUrlNames = (inputString: string) => {
  // Remove all non-letters and non-numbers
  let cleanedString = inputString.replace(/[^a-zA-Z0-9\s]/g, ' ');
  // Convert the string to lowercase
  cleanedString = cleanedString.toLowerCase();
  // Capitalize the first letter of every word
  cleanedString = cleanedString.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
  });
  return cleanedString;
};

export const isURL = (str: string) => {
  // Regular expression for URL validation
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // Test if the string matches the URL regex
  return urlRegex.test(str);
};

export const encodeObjectToUrl = (obj: any) => {
  const jsonStr = JSON.stringify(obj);
  return encodeURIComponent(jsonStr);
};

export const decodeUrlToObject = (str: string) => {
  const decodedVal = decodeURIComponent(str);
  return JSON.parse(decodedVal);
};
