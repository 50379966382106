import { Box, Switch, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Select, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input, FormControl, Textarea, useToast } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import LineChart from 'components/charts/LineChart';
import { dissolveKey, generateHexColorFromString, getToast, isOscillator, secondsToDayMonth } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";
import { useCoins } from "contexts/CoinsContext";
import Content from "views/admin/coinPage/components/Content";
import { useWatchlists } from "contexts/WatchlistsContext";

const drawerSizes = {
  sm: '325px',
  md: "425px",
  lg: "625px"
};

const CreateWatchlistDrawer = (props: { isOpen: boolean, close: () => void }) => {

  const { isOpen, close } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const { createWatchlist } = useWatchlists();

  const toast = useToast();

  const create = async () => {
    setIsLoading(true);
    await createWatchlist(name, description, isPrivate);
    setIsLoading(false);
    toast(getToast("success", "Created!", "Watchlist created successfully!"));
    setName("");
    setDescription("");
    setIsPrivate(true);
  }

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Create Watchlist</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Create new watchlist to track your portfolio.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          {!isLoading ? (
            <Box>
              <FormLabel mb='0'>
                Watchlist Name
              </FormLabel>
              <Input
                placeholder="Watchlist Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                mb="3"
              />
              <FormLabel mb='0'>
                Watchlist Description
              </FormLabel>
              <Textarea
                placeholder="Watchlist Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                mb="3"
              />
              <FormControl display='flex' alignItems='center' mb="5">
                <FormLabel htmlFor='status' mb='0'>
                  Public
                </FormLabel>
                <Switch id='status' checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
                <FormLabel htmlFor='status' mb='0' ml="3">
                  Private
                </FormLabel>
              </FormControl>
              <Button w="100%" variant={"darkBrand"} onClick={() => create()}>
                Create
              </Button>
            </Box>
          ):null}
          {isLoading ? (
            <Loading text="Creating watchlist..." />
          ):null}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateWatchlistDrawer;