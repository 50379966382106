import { Badge, Box, Button, Flex, LightMode, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import { useStrategies } from "contexts/StrategiesContext";
import { useEffect } from "react";

const AboutPanel = (props: { strategy: BullFolio.Strategy }) => {
  const { strategy } = props;
  
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

  const { deleteStrategy } = useStrategies();

  return(
    <>
      <Card mb="4">
        <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Description</Text>
        <Text>{strategy.description}</Text>
      </Card>
      <Card mb="4">
        <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Other Information</Text>
        <Box px="2">
          <Flex mb="1">
            <Text mr="4">Against</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.against.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Timeframe</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.timeframe.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Type</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.type.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Events</Text>
            {strategy.events.map((x) => {
              return (
                <Badge key={x.type} variant={"outline"} width="min-content" ml="2">{x.type.toUpperCase()}</Badge>
              )
            })}
          </Flex>
        </Box>
      </Card>
      {strategy.events.map((strategyEvent, index) => {
        return(
          <Card mb="4" key={index}>
            <Text fontSize={"xl"} fontWeight={"bold"}>{strategyEvent.type.toUpperCase()} - {strategyEvent.name}</Text>
            <Text>{strategyEvent.description}</Text>
            <Text fontSize={"lg"} fontWeight={"bold"} mt="3">Conditions</Text>
            {strategyEvent.conditions.map((condition, i) => {
              return(
                <Flex key={i}>
                  <Text>{condition.script.type.toUpperCase()} ({condition.script.settings.join(",")})</Text>
                  <Text mx="2">{condition.condition}</Text>
                  {condition.value.range ? (
                    <Text>{condition.value.range.low} - {condition.value.range.high}</Text>
                  ):null}
                  {condition.value.value ? (
                    <Text>{condition.value.value}</Text>
                  ):null}
                  {condition.value.indicator ? (
                    <Text>{condition.value.indicator.type.toUpperCase()} ({condition.value.indicator.settings.join(",")})</Text>
                  ):null}
                </Flex>
              )
            })}
          </Card>
        )
      })}
      <Card flexDirection={{ base: 'column', md: 'row', lg: 'row' }} alignItems='center'>
        <Flex direction='column'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Delete Strategy
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            This action cannot be undone and WILL ERASE ALL DATA (including the logs)!
          </Text>
        </Flex>
        <LightMode>
          <Button
            colorScheme='red'
            variant='outline'
            mt={{ base: '20px', md: '0' }}
            _hover={{ bg: 'whiteAlpha.100' }}
            _focus={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            p='15px 40px'
            fontSize='sm'
            h='44px'
            fontWeight='500'
            ms='auto'
            onClick={() => deleteStrategy(strategy.id)}
          >
            Delete
          </Button>
        </LightMode>
      </Card>
    </>
  )
};

export default AboutPanel;