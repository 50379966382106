import { Box, Flex, Switch, SimpleGrid, Text, useColorModeValue, Tooltip, Center, Button, Select } from "@chakra-ui/react"
import { BullFolio } from "bullfolio-types";
import PieChart from "components/charts/PieChart";
import { chainIdToTicker, chainIdToTokenId, generateHexColorFromString } from "helpers/formatters";
import { useEffect, useState } from "react";
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import { useCoins } from "contexts/CoinsContext";
import numeral from "numeral";
import { useUser } from "contexts/UserContext";
import Loading from "components/Loading/Loading";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";


const Graph = (props: { watchlist: BullFolio.Watchlist, balanceUsd: string, balanceBtc: string }) => {
  const { watchlist, balanceBtc, balanceUsd } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const height = 400;
  const width = window.innerWidth * 0.75;
  
  const { queryCoins } = useCoins();
  
  const [allCoins, setAllCoins] = useState<BullFolio.CoinData[]>(null);
  const [vsBtc, setVsBtc] = useState(false);
  const [timeframe, setTimeframe] = useState<BullFolio.Timeframe | null>(null);
  const [chartData, setChartData] = useState<{
    data: {
      x: Date;
      y: number[] | number;
    }[];
    type: string;
    name: string;
    color?: string;
  }[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const chartOptions: ApexOptions = {
    chart: {
      type: "line",
      height: height,
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
        type: "xy",
      },
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    },
    colors: ["#11047A"],
    stroke: {
      width: 3
    },
  };


  const handleLoadChartData = () => {
    setTimeframe("1d");
  }
  
  const getChartData = async () => {
    setIsLoading(true);
  
    // Fetch all coins (each coin will have a `prices` field containing arrays of historical prices)
    const coins = allCoins || await queryCoins(1, 100, [], null, watchlist.tokens.map((t) => t.id), {direction: "desc", value: "data.market_cap"}, null);
    if (!allCoins) {
      setAllCoins(coins);
    }
  
    const btcCoin = coins.find(coin => coin.data.id === 'bitcoin');
    const btcPrices = btcCoin?.prices[timeframe]; // Get BTC prices for the selected timeframe
  
    // Find the earliest transaction date
    const earliestTransactionDate = getEarliestTransactionDate();
    const earliestTimestamp = earliestTransactionDate ? earliestTransactionDate.getTime() : null;
  
    // Initialize balance data
    const intervals = coins[0]?.prices[timeframe].length || 0; // Number of intervals (based on first token's prices array)
    const intervalDuration = intervalToMs(timeframe);
  
    // Create empty data points for each interval
    const data = Array(intervals).fill(null).map((_, index) => {
      const timestamp = Date.now() - (intervals - index) * intervalDuration;
  
      // Only include data points after the earliest transaction date
      return earliestTimestamp && timestamp >= earliestTimestamp ? {
        x: new Date(timestamp),
        y: 0, // Will be updated with the balance
      } : null;
    }).filter(d => d !== null); // Filter out null data points
  
    // Process each token and calculate balance at each interval
    watchlist.tokens.forEach(token => {
      const coin = coins.find(c => c.data.id === token.id);
      if (!coin) return; // Skip if the coin is not found
  
      let cumulativeBalance = token.currentBalance; // Start with the current balance of the token
  
      // Sort transactions by date to process them chronologically
      const sortedTransactions = [...token.transactions].sort((a, b) => new Date(a.buyDate).getTime() - new Date(b.buyDate).getTime());
  
      // Track the index of the next transaction to apply
      let nextTransactionIndex = 0;
  
      // Loop through each interval and calculate balance at each point
      coin.prices[timeframe].forEach((price, index) => {
        const timestamp = Date.now() - (intervals - index) * intervalDuration;
  
        // Apply transactions that happened before or at this interval
        while (nextTransactionIndex < sortedTransactions.length && new Date(sortedTransactions[nextTransactionIndex].buyDate).getTime() <= timestamp) {
          const transaction = sortedTransactions[nextTransactionIndex];
          cumulativeBalance += transaction.balanceChange; // Adjust balance based on transaction
          nextTransactionIndex++; // Move to the next transaction
        }
  
        // Calculate balance in USD based on current price
        let balanceInUsd = cumulativeBalance * price; 
  
        // Convert to BTC if needed
        if (vsBtc) {
          const btcPrice = btcPrices ? btcPrices[index] : 1; // Get BTC price at this index or fallback to 1
          balanceInUsd = balanceInUsd / btcPrice; // Convert to BTC
        }
  
        // Ensure the data point is updated correctly
        if (data[index]) {
          data[index].y += balanceInUsd; // Add this token's balance to the total balance at this interval
        }
  
        // Debug log for the balance at this interval
        console.log(`Interval ${index}: Cumulative Balance: ${cumulativeBalance}, Balance in USD: ${balanceInUsd}, Data Point:`, data[index]);
      });
    });
  
    console.log("Final Data:", data); // Log the final data for verification
  
    // Update chart data
    setChartData([{
      data: data,
      type: "line",
      name: `Portfolio Balance (${vsBtc ? 'BTC' : 'USD'})`,
      color: "#11047A"
    }]);
  
    setIsLoading(false);
  };
  
  // Function to find the earliest transaction date
  const getEarliestTransactionDate = (): Date | null => {
    let earliestDate: Date | null = null;
  
    watchlist.tokens.forEach(token => {
      token.transactions.forEach(transaction => {
        const txDate = new Date(transaction.buyDate);
        if (!earliestDate || txDate < earliestDate) {
          earliestDate = txDate;
        }
      });
    });
  
    return earliestDate;
  };
  
  // Use the useEffect to trigger the data loading when the timeframe changes
  useEffect(() => {
    if (timeframe) {
      (async () => {
        await getChartData();
      })();
    }
  }, [timeframe, vsBtc]);
  

  // Helper function to convert interval timeframes to milliseconds
const intervalToMs = (timeframe: BullFolio.Timeframe) => {
  switch (timeframe) {
    case '1h':
      return 60 * 60 * 1000; // 1 hour in ms
    case '4h':
      return 4 * 60 * 60 * 1000; // 4 hours in ms
    case '8h':
      return 8 * 60 * 60 * 1000; // 8 hours in ms
    case '1d':
      return 24 * 60 * 60 * 1000; // 1 day in ms
    case '2d':
      return 2 * 24 * 60 * 60 * 1000; // 2 days in ms
    case '5d':
      return 5 * 24 * 60 * 60 * 1000; // 5 days in ms
    case '1w':
      return 7 * 24 * 60 * 60 * 1000; // 1 week in ms
    default:
      return 0;
  }
};

  return(
    <Box px='10px' alignItems='center' flexDirection='column' w='100%'>
			<Flex
				px={{ base: '0px', '2xl': '10px' }}
				justifyContent='space-between'
				alignItems='center'
				w='100%'
        height={"100%"}
				mb='8px'>
				<Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
					Balance: {vsBtc ? balanceBtc : balanceUsd}
				</Text>
			</Flex>

      {chartData ? (
        <Box mt="2">
          <Flex>
            <Select width={"fit-content"} defaultValue={timeframe} onChange={(e) => setTimeframe(e.target.value as BullFolio.Timeframe)} ml="2">
              <option value={"1h"}>1 H</option>
              <option value={"4h"}>4 H</option>
              <option value={"8h"}>8 H</option>
              <option value={"1d"}>1 D</option>
              <option value={"2d"}>2 D</option>
              <option value={"5d"}>5 D</option>
              <option value={"1w"}>1 W</option>
            </Select>
            <Select width={"fit-content"} defaultValue={vsBtc ? "btc" : "usd"} onChange={(e) => setVsBtc(e.target.value === "btc" ? true : false)} ml="2">
              <option value={"usd"}>USD</option>
              <option value={"btc"}>BTC</option>
            </Select>
          </Flex>
        </Box>
      ):null}

      {chartData ? (
        <Card mt="4">
          <ReactApexChart options={chartOptions} series={chartData} type="line" height={height} width={width} />
          <Button size="xs" onClick={() => handleLoadChartData()}>
            Refresh
          </Button>
        </Card>
      ):null}

      {isLoading ? (
        <Loading
          text="Loading Chart . . ."
        />
      ):null}

      {!chartData ? (
        <Center>
          <Button variant="darkBrand" onClick={() => handleLoadChartData()}>
            Load Chart Data
          </Button>
        </Center>
      ):null}
		</Box>
  );
};

export default Graph;