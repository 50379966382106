import { Box, Switch, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Select, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input, FormControl, Textarea, useToast, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import LineChart from 'components/charts/LineChart';
import { dissolveKey, generateHexColorFromString, getToast, isOscillator, secondsToDayMonth } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";
import { useCoins } from "contexts/CoinsContext";
import Content from "views/admin/coinPage/components/Content";
import { useWatchlists } from "contexts/WatchlistsContext";
import { useUser } from "contexts/UserContext";

const drawerSizes = {
  sm: '325px',
  md: "425px",
  lg: "625px"
};

const AddToWatchlistDrawer = (props: { isOpen: boolean, close: () => void, token: BullFolio.CoinData.Data }) => {

  const { isOpen, close, token } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');

  const [note, setNote] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<string | undefined>(undefined);
  const [buyPrice, setBuyPrice] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [allWatchlists, setAllWatchlist] = useState<BullFolio.Watchlist[]>(null);
  const [watchlist, setWatchlist] = useState<BullFolio.Watchlist>(null);

  const { userData } = useUser();
  const { getAllWatchlists, addTokenToWatchlist } = useWatchlists();

  const toast = useToast();

	const handleAdd = async () => {
    if (!watchlist) {
      toast(getToast("warning", "Select watchlist", "Select your watchlist to add token"));
      return;
    }

    try {
      setIsLoading(true);
      toast(getToast("info", "Adding...", "Adding, please wait..."));
      let tokenIndex = -1;
      watchlist.tokens.forEach((t, index) => {
        if (t.id === token.id) tokenIndex = index;
      });
      if(tokenIndex >= 0) {
        const res = await addTokenToWatchlist(token.id, watchlist.id, note, Number(amount) || undefined, Number(buyPrice) || undefined, false, "buy", tokenIndex);
      }else{
        // token not yet in watchlist
        const res = await addTokenToWatchlist(token.id, watchlist.id, note, Number(amount) || undefined, Number(buyPrice) || undefined, false, "buy", tokenIndex);
      }
      setIsLoading(false);
      toast(getToast("success", "Added", "Token added to watchlist"));
    } catch(err) {
      setIsLoading(false);
      toast(getToast("error", "Error", "Something went wrong"));
    }
	}

  const handleSetWatchlist = (id: string) => {
    const w = allWatchlists.filter((x) => x.id == id)[0];
    setWatchlist(w);
  }

  useEffect(() => {
    if (!allWatchlists) {
      (async () => {
        setIsLoading(true);
        const res = await getAllWatchlists();
        setAllWatchlist(res);
        setIsLoading(false);
      })();
    }
  }, []);

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Add {token.name} to Watchlist</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Add {token.name} to your watchlist.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          {!isLoading ? (
            <Box>
              <FormLabel mb='0'>
                Select Watchlist
              </FormLabel>
              <Select onChange={(e) => handleSetWatchlist(e.target.value)} placeholder="Select watchlist">
                {allWatchlists?.map(w => {
                  return(
                    <option key={w.id} value={w.id}>{w.name}</option>
                  )
                })}
              </Select>
              <Text fontSize={"lg"} fontWeight="bold" mb="1" mt="4">Add transaction data (optional):</Text>
              <FormLabel mb="0">
                Amount  
              </FormLabel>
              <Input
                mb="3"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Buy amount (optional)"
              />
              <FormLabel mb="0">
                Buy Price  
              </FormLabel>
              <InputGroup mb="3">
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  value={buyPrice}
                  onChange={(e) => setBuyPrice(e.target.value)}
                  placeholder="Buy Price (optional)"
                />
              </InputGroup>
              <FormLabel mb="0">
                Note  
              </FormLabel>
              <Textarea
                mb="5"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Transaction note (optional)"
              />
              <Button w="100%" variant={"darkBrand"} onClick={() => handleAdd()}>
                Add {token.name} to {watchlist?.name || "Watchlist"}
              </Button>
            </Box>
          ):null}
          {isLoading ? (
            <Loading text="Please wait..." />
          ):null}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddToWatchlistDrawer;