import { Box, Button, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import Card from "components/card/Card";
import Loading from "components/Loading/Loading";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useAlerts } from "contexts/AlertsContext";
import { useUser } from "contexts/UserContext";
import { links } from "helpers/links";
import { useEffect, useState } from "react";
import AlertBox from "./components/AlertBox";

const columnsNum = {
  sm: 1,
  md: 1,
  lg: 3
};

const MyAlertsPage = () => {
  const textColor = useColorModeValue("navy.700", "white");

  const LIMIT = 35;

  const { getAllAlerts } = useAlerts();
  const { userData } = useUser();

  const [alerts, setAlerts] = useState<BullFolio.Alert[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if(userData) {
      (async () => {
        setIsLoading(true);
        const res = await getAllAlerts();
        console.log(res);
        setAlerts(res);
        setIsLoading(false);
      })();
    }
  }, [userData]);

  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `/`,
          name: "My Alerts"
        }]}
        additional={{ mb: "4" }}
      />
      <Card mb="6">
        <Text textColor={textColor} fontSize="2xl" fontWeight={"extrabold"}>
          My Alerts
        </Text>
        <Text textColor={textColor}>
          Your Alert Limit: {LIMIT}.
        </Text>
      </Card>
      {!isLoading ? (
        <>
          <Card mb="6">
            <SearchBar
              value={value}
              onChange={(e:any) => setValue(e.target.value)}  
              h='44px'
              w={{ lg: "390px" }}
              borderRadius='16px'
              setSearchTerm={() => {}}
            /> 
          </Card>

          {alerts?.length>0 ? (
            <SimpleGrid columns={columnsNum} gap="20px">
              {alerts.filter(obj => obj.name.toLowerCase().includes(value.toLowerCase())).map(alert => {
                return (
                  <Box key={alert.id}>
                    <AlertBox alert={alert} />
                  </Box>
                )
              })}
            </SimpleGrid>
          ):(
            <Text textAlign={"center"} mt="14">No Alerts Found.</Text>
          )}
          {alerts.length>=0 && alerts.length<LIMIT ? (
            <Link href={`/#${links.createAlert}`}>
              <Button variant={"darkBrand"} w="100%" mt="12">
                Create Alert
              </Button>
            </Link>
          ):(
            <Card mt="12">
              <Text textAlign={"center"}>Alerts Limit Reached.</Text>
            </Card>
          )}
        </>
      ):(
        <Loading text="Getting your alerts..."/>
      )}

      <Card mt="12">
        <Text fontWeight={"extrabold"} fontSize="xl">Receive alerts in Telegram</Text>
        <Text fontSize={"lg"}>Join Telegram and follow instructions there to receive all alerts directly in Telegram</Text>
        <Link isExternal={true} href="https://t.me/+guTMeHppFug1MzA0">
          <Button variant={"brand"} mt="3">
            Join Telegram
          </Button>
        </Link>
      </Card>
    </Box>
  );
};

export default MyAlertsPage;