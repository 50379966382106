import { Box, Switch, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Select, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input, FormControl, Textarea, useToast, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import LineChart from 'components/charts/LineChart';
import { dissolveKey, generateHexColorFromString, getToast, isOscillator, secondsToDayMonth } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";
import { useCoins } from "contexts/CoinsContext";
import Content from "views/admin/coinPage/components/Content";
import { useWatchlists } from "contexts/WatchlistsContext";
import { useUser } from "contexts/UserContext";

const drawerSizes = {
  sm: '325px',
  md: "425px",
  lg: "625px"
};

const AddNewTokenDrawer = (props: { add: (tokenId: string, note: string | undefined, amount: number | undefined, buyPrice: number | undefined, isForTx: boolean, txType: string | undefined) => Promise<void>, isForTx: boolean, watchlistId: string, isOpen: boolean, close: () => void }) => {

  const { add, isForTx, watchlistId, isOpen, close } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const [coin, setCoin] = useState<string>("");
  const [txType, setTxType] = useState<string>("buy");
  const [searchValue, setSearchValue] = useState<string>("");
  const [note, setNote] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<string | undefined>(undefined);
  const [buyPrice, setBuyPrice] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { userData } = useUser();

  const toast = useToast();

  const handleAdd = async () => {
    setIsLoading(true);
    if(coin.length<1) {
      toast(getToast("warning", "Select token!", "You must select token!"));  
    }else{
      if(isForTx) {
        if(Number(amount) && Number(buyPrice)) {
          await add(coin, note, Number(amount), Number(buyPrice), isForTx, txType);
          toast(getToast("success", "Added!", "Token added to watchlist!"));
        }else{
          toast(getToast("warning", "Must be number!", "Amount must be a number!"));  
        }
      }else{
        await add(coin, note, Number(amount) || undefined, Number(buyPrice) || undefined, isForTx, txType);
        toast(getToast("success", "Added!", "Token added to watchlist!"));
      }
    }
    setIsLoading(false);
  }

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">{isForTx ? "Add Transaction" : "Add New Coin"}</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">{isForTx ? "Add new transaction to a coin." : "Add new coin to your watchlist."}</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          {!isLoading ? (
            <Box>
              <FormLabel mb='0'>
                {isForTx ? "For coin" : "Select Coin"}
              </FormLabel>
              <Input
                placeholder="Search tokens by name..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                mb="1"
              />
              <Select onChange={(e) => setCoin(e.target.value)} placeholder="Select token">
                {userData?.allIds?.coins?.filter((x) => x.name.toLowerCase().includes(searchValue.toLowerCase())).map(coin => {
                  return(
                    <option key={coin.id} value={coin.id}>{coin.name} ({coin.symbol.toUpperCase()})</option>
                  )
                })}
              </Select>
              <Text fontSize={"lg"} fontWeight="bold" mb="1" mt="4">Add transaction data{isForTx ? "" : "(optional)"}:</Text>
              {isForTx ? (
                <>
                  <FormLabel mb="0">
                    Transaction Type
                  </FormLabel>
                  <Select mb="3" value={txType} onChange={(e) => setTxType(e.target.value)}>
                    <option value={"buy"}>Buy</option>
                    <option value={"sell"}>Sell</option>
                  </Select>
                </>
              ):null}
              <FormLabel mb="0">
                Amount  
              </FormLabel>
              <Input
                mb="3"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Buy amount (optional)"
              />
              <FormLabel mb="0">
                Buy Price  
              </FormLabel>
              <InputGroup mb="3">
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  value={buyPrice}
                  onChange={(e) => setBuyPrice(e.target.value)}
                  placeholder="Buy Price (optional)"
                />
              </InputGroup>
              <FormLabel mb="0">
                Note  
              </FormLabel>
              <Textarea
                mb="5"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Transaction note (optional)"
              />
              <Button w="100%" variant={"darkBrand"} onClick={() => handleAdd()}>
                Add
              </Button>
            </Box>
          ):null}
          {isLoading ? (
            <Loading text="Adding coin to watchlist..." />
          ):null}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddNewTokenDrawer;