import { Box } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import Loading from "components/Loading/Loading";
import { useCoins } from "contexts/CoinsContext";
import { useUser } from "contexts/UserContext";
import { useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Content from "./components/Content";


const CoinPage = () => {

  const { getCoinById } = useCoins();
  const { user, userData } = useUser();
  const query = useQuery();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [coin, setCoin] = useState<BullFolio.CoinData | null>(null);
  const [bitcoin, setBitcoin] = useState<BullFolio.CoinData | null>(null);

  useEffect(() => {
    const id = query.get("coinId");
    if(id) {
			setIsLoading(true);
      if(userData && user) {
        (async () => {
          const res = await getCoinById(id);
          const resBitcoin = await getCoinById("bitcoin");
          console.log(res);
          setCoin(res);
          setBitcoin(resBitcoin);
          setIsLoading(false);
          if(!res) {
            console.log("redirect");
          }
        })();
      }
    }else{
      history.push(`/#${links.home}`);
    }
  }, [query, user, userData]);

  return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `/#${links.home}`,
          name: "Top Coins"
        }, {
          href: `/`,
          name: coin?.data?.name || "Coin Overview"
        }]}
        additional={{ mb: "4" }}
      />
      {!isLoading && coin ? (
        <Content coin={coin} bitcoin={bitcoin} />
      ):(
        <Loading text="Loading coin data..." />
      )}
    </Box>
  )
};

export default CoinPage;