import { Badge, Box, Button, Flex, Select, Text, useToast } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import CoinDetailsDrawer from "components/drawers/CoinDetailsDrawer";
import Loading from "components/Loading/Loading";
import { HSeparator } from "components/separator/Separator";
import { useCoins } from "contexts/CoinsContext";
import { useStrategies } from "contexts/StrategiesContext";
import { useUser } from "contexts/UserContext";
import { getCurrencySymbol, getToast } from "helpers/formatters";
import numeral from "numeral";
import { useState } from "react";
import LogsTable, { RowObjCoinsLogs } from "../components/LogsTable";


const LogsPanel = (props: { strategy: BullFolio.Strategy }) => {

  const { strategy } = props;

  const { getStrategyLogs } = useStrategies();
  const { userData } = useUser();
  const toast = useToast();

  const [logs, setLogs] = useState<BullFolio.Log[] | null>(null);
  const [logDoc, setLogDoc] = useState<BullFolio.LogDoc | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterVal, setFilterVal] = useState<string>("buy sell");

  const handleGetData = async () => {
    setIsLoading(true);
    console.log("getting data")
    const data = await getStrategyLogs(strategy.id);
    if(data) {
      setLogDoc(data.logDoc);
      setLogs(data.logs);
      console.log("got data ", data);
    }else{
      // toast error
      toast(getToast("warning", "No Data Found!", "Please contact support.4"));
    }
    setIsLoading(false);
  }

  const generateTableData = (log: BullFolio.Log): RowObjCoinsLogs[] => {
    const res: RowObjCoinsLogs[] = [];

    log.token.forEach((token, i) => {
      if (filterVal.includes(log.type[i])) {
        res.push({
          rank: token.market_cap_rank.toString(),
          price: `${getCurrencySymbol(userData?.baseCurrency || "usd")}${numeral(log.price[i]).format(`0,0.00`)}`,
          name: [token.name, token.symbol, token.image, token.id],
          tradeType: log.type[i].toString(),
          id: token.id,
          date: `${padZero(new Date(log.date[i]).getDate())}. ${padZero(new Date(log.date[i]).getMonth() + 1)}. ${new Date(log.date[i]).getFullYear()}`,
        });
      }
    });

    return res;
  }

	const padZero = (num: number) => {
		return (num < 10 ? '0' : '') + num;
	};

  return(
    <>
      <Card mb="8">
        <Box px="1.5">
          <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Strategy Activity</Text>
          <Text>Analyze strategy activity, past trades and signals and overall strategy performance in realtime.</Text>
        </Box>
        {!logDoc || !logs ? (
          <Button
            mt="4"
            onClick={() => handleGetData()}
            variant={"darkBrand"}
          >
            Get Data
          </Button>
        ):null}
      </Card>

      {logDoc? (
        <Card mb="8">
          <Text fontSize={"2xl"} fontWeight={"bold"} mb="1">Overview</Text>
          <Box px="2">
            <Flex mb="1">
              <Text mr="4">Number of Tokens Tracked</Text>
              <Badge variant={"outline"} width="min-content" ml="2">{logDoc.tokensTracked.length}</Badge>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Total number of Buy Signals</Text>
              <Badge variant={"outline"} width="min-content" ml="2">{logDoc.totalLogs.buy}</Badge>
            </Flex>
            <Flex mb="1">
              <Text mr="4">Total number of Sell Signals</Text>
              <Badge variant={"outline"} width="min-content" ml="2">{logDoc.totalLogs.sell}</Badge>
            </Flex>
          </Box>
        </Card>
      ):null}

      {logs ? (
        <Flex justifyContent={"space-between"}>
          <Text px="3" fontSize={"2xl"} fontWeight={"bold"} mb="2">Past Signals</Text>
          <Select width={"fit-content"} onChange={(e) => setFilterVal(e.target.value)} value={filterVal} ml="2" minWidth={"20%"}>
            <option value={"buy sell"}>All</option>
            <option value={"buy"}>Buy</option>
            <option value={"sell"}>Sell</option>
          </Select>
        </Flex>
      ):null}
      {logs?.map((log, i) => {
        return (
          <Card key={i} mb="4">
            <Text fontWeight={"extrabold"} fontSize="xl" pb="2" px="1">
              {padZero(new Date(log.date[0]).getDate())}. {padZero(new Date(log.date[0]).getMonth() + 1)}. {new Date(log.date[0]).getFullYear()} at {padZero(new Date(log.date[0]).getHours())}:{padZero(new Date(log.date[0]).getMinutes())}
            </Text>
            <LogsTable
              tableData={generateTableData(log)}
            />
          </Card>
        )
      })}

      {isLoading ? (
        <Loading text="Loading logs..." />
      ):null}
    </>
  )
};

export default LogsPanel;